import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Rawmaterialinspection.css';
import Loading from '../Loading';
import { Table } from 'react-bootstrap';
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg';
import Header from '../Common/Header/Header';
import Footer from '../Common/Footer/Footer';
import axios from 'axios';
import Environment from '../../environment';

function Rawmaterialinspectionview() {
    const [loading, setLoading] = useState(false);
    const [inspectionRows, setInspectionRows] = useState([]);
    const [rowData, setRowData] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    useEffect(() => {
        setLoading(true)
        fetchData();
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, []);

    const fetchData = () => {
        axios.get(Environment.BaseAPIURL + `/api/User/GetRMInspectiondata?id=${id}`)
            .then(response => {
                const data = response?.data;
                setInspectionRows(data.Header[0]);
                setRowData(data.Body);
            })
            .catch(error => {
                console.error('Error fetching raw material data:', error);
            });
    };

    return (
        <>
            {
                loading ? <Loading /> :
                    <>
                        <Header />
                        <section className='InnerHeaderPageSection'>
                            <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <ul>
                                            <li> <Link to='/dashboard?moduleId=618'>Quality Module</Link></li>
                                            <b style={{ color: '#fff' }}>/ &nbsp;</b>
                                            <li> <Link to='/rawmaterialinwardlist?menuId=24'> Raw Material Inward </Link> <b style={{ color: '#fff' }}></b></li>
                                            <li><h1>/ &nbsp;Raw Material Inspection View</h1></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='RawmaterialPageSection RawmaterialInspectionPageSection'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <div className='PipeTallySheetDetails'>
                                            <form action="" className='row m-0'>
                                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                                    <h4>Raw Material Inspection <span>- View page</span></h4>
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <div className='form-group' style={{ marginBottom: '0' }}>
                                                        <label htmlFor='coatingTypes' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Type Of Coating </label>
                                                        <input value={inspectionRows?.Coattype} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor='clientName' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Client Name</label>
                                                        <input value={inspectionRows?.ClientName} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <div className='form-group'>
                                                        <label>Procedure / WI No.</label>
                                                        <input value={inspectionRows?.WorkInstr} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <div className='form-group' style={{ marginBottom: '10px' }}>
                                                        <label htmlFor="">Date</label>
                                                        <input value={new Date(inspectionRows?.Inspectiondate).toLocaleDateString('en-GB').replace(/\//g, "-")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <div id="custom-scroll" style={{ overflow: 'auto' }}>
                                                        <Table className='RawmaterialInspectionTable'>
                                                            <thead>
                                                                <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                                    <th style={{ minWidth: '70px' }}>Sr. No.</th>
                                                                    <th style={{ minWidth: '150px' }}>Receiving Date</th>
                                                                    <th style={{ minWidth: '260px' }}>Material Description</th>
                                                                    <th style={{ minWidth: '220px' }}>Manufacturer</th>
                                                                    <th style={{ minWidth: '170px' }}>Grade</th>
                                                                    <th style={{ minWidth: '160px' }}>Batch No.</th>
                                                                    <th style={{ minWidth: '145px' }}>Received Quantity</th>
                                                                    <th style={{ minWidth: '125px' }}>Unit</th>
                                                                    <th style={{ minWidth: '135px' }}>Lab Test Report No.</th>
                                                                    <th style={{ minWidth: '120px' }}>Inspection Status</th>
                                                                    <th style={{ minWidth: '260px' }}>Remarks</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rowData.map((row, index) => (
                                                                    <tr key={row.inspTestid}>
                                                                        <td>{index + 1}</td>
                                                                        <td><input value={new Date(row?.ReceivingDate).toLocaleDateString('en-GB').replace(/\//g, "-")} /></td>
                                                                        <td><input value={row?.Material} /></td>
                                                                        <td><input value={row?.Manufacturer} /></td>
                                                                        <td><input value={row?.Grade} /></td>
                                                                        <td><input value={row?.BatchNo} /></td>
                                                                        <td><input value={row?.Recvdqty} /></td>
                                                                        <td><input value={row?.Unit} /></td>
                                                                        <td><input value={row?.TestReportId} /></td>
                                                                        <td><input value={row?.Status} /></td>
                                                                        <td><input value={row?.Remark} /></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </>
            }
        </>
    )
}

export default Rawmaterialinspectionview;