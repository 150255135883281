import React, { useState, useEffect } from "react";
import './Rawmaterialinspection.css'
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg'
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Loading from '../Loading';
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import Environment from "../../environment";
import { useParams } from "react-router-dom";

function Rawmaterialinspectionview() {
    const { id } = useParams();
    const [headerData, setHeaderData] = useState({});
    const [rawMaterialData, setRawMaterialData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false)
        }, 2000);

        // axios.get(Environment.BaseAPIURL + "/api/User/GetInspectionReportHeader")
        axios.get(`https://65b8e041b71048505a89ab75.mockapi.io/api/User/rwilist/itembyid?FormatId=${id}`)
            .then(response => {
                setHeaderData(response.data);
            })
            .catch(error => {
                console.error('Error fetching header data:', error);
            });
        axios.get(Environment.BaseAPIURL + `/api/User/GetRawMaterialReport?clientId=${id}`)
            // axios.get(`https://65b8e041b71048505a89ab75.mockapi.io/api/User/rwilist/itembyid?srNoinspectionId=${id}`)
            .then(response => {
                setRawMaterialData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching raw material data:', error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Header />
                        <section className='InnerHeaderPageSection'>
                            <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <ul>
                                            <li><Link to='/dashboard'>Quality Module</Link></li>
                                            <li><h1>/ &nbsp; Raw Material Inspection </h1></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="RawMaterialReportSectionPage RMIViewSectionPage">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="RawMaterialReportTables">
                                            <form action="" className="row m-0">
                                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                                    <h4>Raw Material Inspection <span>- View page</span></h4>
                                                </div>

                                                {Object.entries(headerData)
                                                    .map(([label, value]) => (
                                                        <div className="col-md-4 col-sm-4 col-xs-12" key={label}>
                                                            <div className="form-group">
                                                                <label htmlFor="">{label}</label>
                                                                <input type="text" value={value} readOnly />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </form>

                                            <div className="row m-0">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <div id="custom-scroll" style={{ overflow: 'auto' }}>
                                                        <Table style={{ marginBottom: '0' }}>
                                                            <thead>
                                                                <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                                    <th style={{ minWidth: '80px' }}>Sr. No.</th>
                                                                    <th style={{ minWidth: '130px' }}>Receiving Date</th>
                                                                    <th style={{ minWidth: '200px' }}>Material Description</th>
                                                                    <th style={{ minWidth: '200px' }}>Manufacturer</th>
                                                                    <th style={{ minWidth: '130px' }}>Grade</th>
                                                                    <th style={{ minWidth: '130px' }}>Batch No.</th>
                                                                    <th style={{ minWidth: '130px' }}>Received Quantity</th>
                                                                    <th style={{ minWidth: '130px' }}>Lab Test Report No.</th>
                                                                    <th style={{ minWidth: '120px' }}>Inspection Status</th>
                                                                    <th style={{ minWidth: '80px' }}>Remark</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rawMaterialData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{new Date(item["ReceivingDate"]).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '/')}</td>
                                                                        <td>{item["MaterialDescription"]}</td>
                                                                        <td>{item["Manufacturer/Grade"]}</td>
                                                                        <td>{item["BatchNo"]}</td>
                                                                        <td>{item["ReceivedQuantity"]}</td>
                                                                        <td>{item["ReceivedQuantityUnit"]}</td>
                                                                        <td>{item["LabTestReportNo"]}</td>
                                                                        <td>{item["InspectionStatus"]}</td>
                                                                        <td>{item["Remarks"]}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>

                                                    {/* <table className="SignatureTable">
                                                        <thead>
                                                            <tr>
                                                                <th>INSPECTED BY</th>
                                                                <th>ACCCEPTED BY</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <th>QC ENGINEER</th>
                                                                <th>QC ENGINEER</th>
                                                            </tr>
                                                        </tbody>
                                                    </table> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </>
            }
        </>
    );
}

export default Rawmaterialinspectionview