import React, { useState, useEffect } from 'react';
import './Rawmateriallist.css';
import Header from '../Common/Header/Header';
import Footer from '../Common/Footer/Footer';
import Loading from '../Loading';
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg';
import { Link } from 'react-router-dom';
import Pagination from '../Common/Pagination/Pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Environment from "../../environment";

function Rawmaterialinwardlist() {
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;

    useEffect(() => {
        GetRawMaterialTestList();
    }, []);

    const filterData = () => {
        try {
            return data.filter(item => {
                const testDate = new Date(item.pm_insp_date);
                return (!fromDate || testDate >= fromDate) && (!toDate || testDate <= toDate);
            });
        } catch (ex) {
            console.log(ex);
            return [];
        }
    };

    const resetFilter = () => {
        setFromDate(null);
        setToDate(null);
    };

    const displayData = filterData().slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    const pageCount = Math.ceil(filterData().length / pageSize);
    const handlePageClick = data => {
        setCurrentPage(data.selected);
    };

    const GetRawMaterialTestList = async () => {
        try {
            const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetRMInspectionDataList`);
            if (Array.isArray(response.data)) {
                setData(response.data);
            } else {
                toast.error('Data received from the API is not in the expected format.');
            }
        } catch (error) {
            toast.error('Failed to load data: ' + error.message);
            console.error('Failed to load data', error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (data) => {
        const { tallySheetId, pipeId } = data;

        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const response = await axios.post(`${Environment.BaseAPIURL}/api/User/rawMaterialDelete`, {
                                tallySheetId: parseInt(tallySheetId),
                                pipeId: parseInt(pipeId),
                                pipeDeleteStatus: true
                            });
                            if (response.status === 200) {
                                setData(prevRows => prevRows.filter(row => row.tallySheetId !== tallySheetId));
                                toast.success('Data deleted successfully.');
                                console.log('Deleted:', data);
                            } else {
                                toast.error('Error deleting data: ' + response.statusText);
                                console.error('Error deleting data:', response.statusText);
                            }
                        } catch (error) {
                            toast.error('Error deleting data: ' + error.message);
                            console.error('Error deleting data:', error);
                        }
                    }
                },
                { label: 'No' }
            ]
        });
    };

    return (
        <>
            {loading ? <Loading /> :
                <>
                    <Header />
                    <section className='InnerHeaderPageSection'>
                        <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                    <ul>
                                        <li> <Link to='/dashboard?moduleId=618'>Quality Module</Link></li>
                                        <b style={{ color: '#fff' }}>/ &nbsp;</b>
                                        <li> <Link to='/inspectiontesting?menuId=24'> Raw Material Test & Inspection </Link> <b style={{ color: '#fff' }}></b></li>
                                        <li><h1>/ &nbsp; Raw Material Inward</h1></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='RawmateriallistPageSection'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                    <div className='RawmateriallistTables'>
                                        <h4>Raw Material Inward <span>- List page</span></h4>
                                        <div className='tableheaderflex'>
                                            <div className='tableheaderfilter'>
                                                <span><i className="fas fa-filter"></i> Filter Test Data</span>
                                                <label> From Date:
                                                    <DatePicker
                                                        maxDate={new Date()}
                                                        selected={fromDate}
                                                        onChange={date => setFromDate(date)}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="DD-MM-YYYY"
                                                    />
                                                </label>
                                                <label> To Date:
                                                    <DatePicker
                                                        maxDate={new Date()}
                                                        selected={toDate}
                                                        onChange={date => setToDate(date)}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="DD-MM-YYYY"
                                                    />
                                                </label>
                                                <i className="fa fa-refresh" onClick={resetFilter}></i>
                                            </div>
                                            <div className='tableheaderAddbutton'>
                                                <Link to='/rawmaterialinspection?menuId=24' style={{ float: 'right' }}>
                                                    <i className="fas fa-plus"></i> Add
                                                </Link>
                                            </div>
                                        </div>
                                        <ul className='Rawmaterialinspectionlistlegend'>
                                            <li><i className="fas fa-eye" style={{ color: '#4CAF50' }}></i>View</li>
                                        </ul>
                                        <div className='table-responsive' id='custom-scroll'>
                                            <table>
                                                <thead>
                                                    <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                        <th style={{ minWidth: '60px' }}>S No.</th>
                                                        <th style={{ minWidth: '130px' }}>Type Of Coating</th>
                                                        <th style={{ minWidth: '130px' }}>Client Name</th>
                                                        <th style={{ minWidth: '50px' }}>No. Of Entries</th>
                                                        <th style={{ minWidth: '80px' }}>Inspection Date</th>
                                                        <th style={{ minWidth: '60px' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayData.length === 0 ? (
                                                        <tr><td colSpan="6">No data found.</td></tr>
                                                    ) : (
                                                        displayData.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{currentPage * pageSize + index + 1}</td>
                                                                <td>{row.Coat_type}</td>
                                                                <td>{row.Client_name}</td>
                                                                <td>{row.Entity}</td>
                                                                <td>{new Date(row.pm_insp_date).toLocaleDateString('en-GB').replace(/\//g, "-")}</td>
                                                                <td>
                                                                    <Link to={`/inspection-test/menuId=24&id=${row.pm_inspection_id}`} target='_blank'><i className="fas fa-eye"></i></Link>
                                                                    {/* <Link to={`/rawmaterialinspection?processshitid=${row.pm_inspection_id}`} target='_blank'><i className="fas fa-edit"></i></Link>
                                                                    <Link onClick={() => handleDelete(row)} ><i className="fas fa-trash-alt" style={{ color: "red" }}></i></Link> */}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination pageCount={pageCount} onPageChange={handlePageClick} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            }
        </>
    );
}

export default Rawmaterialinwardlist;