import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Rawmaterialinspection.css';

import Header from '../Common/Header/Header'
import Footer from '../Common/Footer/Footer'
import Loading from '../Loading';
import Pagination from '../Common/Pagination/Pagination';

import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg';
import DatePicker from 'react-datepicker';

import axios from 'axios';
import Environment from "../../environment";

function Rawmaterialinspectionlist() {

    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, [])

    // -----------------------------------------------------------

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(Environment.BaseAPIURL + "/api/User/GetRawMaterialReport");
            // const response = await axios.get("https://65b8e041b71048505a89ab75.mockapi.io/api/User/rwilist/list");
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // -----------------------------------------------------------

    const [filterStatus, setFilterStatus] = useState(null);

    const filterData = () => {
        let filteredData = data.filter(item => {
            const reportDate = new Date(item.InspectionDate);
            const startDate = fromDate ? new Date(fromDate) : null;
            const endDate = toDate ? new Date(toDate) : null;

            return (
                (!startDate || reportDate >= startDate) &&
                (!endDate || reportDate <= endDate)
            );
        });

        if (filterStatus) {
            filteredData = filteredData.filter(item => item.InspectionStatus === filterStatus);
        }

        return filteredData;
    };

    const handleFilterStatus = (status) => {
        setFilterStatus(status === filterStatus ? null : status);
    };

    const filteredData = filterData();

    const setStartDate = date => {
        setFromDate(date);
    };

    const setEndDate = date => {
        setToDate(date);
    };

    const resetFilter = () => {
        setFromDate('');
        setToDate('');
        setFilterStatus('');
    };

    // -----------------------------------------------------------

    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;

    const pageCount = Math.ceil(filteredData.length / pageSize);

    const displayedData = filteredData.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize
    );

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    // -----------------------------------------------------------

    const getRowColorClass = (status) => {
        switch (status) {
            case 'P':
                return { backgroundColor: '#fff' };
            case 'A':
                return { backgroundColor: '#f0f2f5' };
            case 'R':
                return { backgroundColor: '#ffe9eb' };
            default:
                return {};
        }
    }

    // -----------------------------------------------------------

    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Header />
                        <section className='InnerHeaderPageSection'>
                            <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <ul>
                                            <li> <Link to='/dashboard'> Quality Module </Link></li>
                                            <b style={{ color: '#fff' }}>/ &nbsp;</b>
                                            <li> <Link to='/inspectiontesting'> Raw Material</Link> <b style={{ color: '#fff' }}></b></li>
                                            <li><h1> / &nbsp; Raw Material Inspection  </h1></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='RawmateriallistPageSection'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <div className='RawmateriallistTables'>
                                            <h4>Raw Material Inspection <span>- List page</span></h4>
                                            <div className='tableheaderflex'>
                                                <div className='tableheaderfilter'>
                                                    <span><i class="fas fa-filter"></i> Filter Test Data</span>
                                                    <label> From Date:
                                                        <DatePicker
                                                            maxDate={Date.now()}
                                                            selected={fromDate}
                                                            onChange={setStartDate}
                                                            dateFormat="dd/MMM/yyyy"
                                                            placeholderText="DD/MMM/YYYY"
                                                        />
                                                    </label>
                                                    <label> To Date:
                                                        <DatePicker
                                                            maxDate={Date.now()}
                                                            selected={toDate}
                                                            onChange={setEndDate}
                                                            dateFormat="dd/MMM/yyyy"
                                                            placeholderText="DD/MMM/YYYY"
                                                            minDate={fromDate}
                                                        />
                                                    </label>
                                                    <i class="fa fa-refresh" onClick={resetFilter}></i>
                                                </div>

                                                <div className='tableheaderAddbutton'>
                                                    <Link to='/rawmaterialinspectionQCofficerlist' target='_blank' className='TableQcLink'>Table for QC</Link>
                                                    <Link to='/rawmaterialinspection' target='_blank' > <i className='fas fa-plus'></i> Add </Link>
                                                </div>
                                            </div>

                                            <ul className='Rawmaterialinspectionlistlegend'>
                                                <li> <i class="fas fa-eye" style={{ color: '#34B233' }}></i> View </li>
                                                <li onClick={() => handleFilterStatus('P')} style={{ color: '#FFA100' }}> <i class="fas fa-hourglass-half" style={{ color: '#FFA100' }}></i> Pending </li>
                                                <li onClick={() => handleFilterStatus('A')} style={{ color: '#3D7EDB' }}> <i class="fas fa-check" style={{ color: '#3D7EDB' }}></i> QC Approved </li>
                                                <li onClick={() => handleFilterStatus('R')} style={{ color: '#ED2939' }}> <i class="fas fa-times" style={{ color: '#ED2939' }}></i> Returned </li>
                                                <li> <i class="fas fa-edit" style={{ color: '#7c7c00' }}></i> Edit </li>
                                            </ul>

                                            <div className='table-responsive' id='custom-scroll'>
                                                <table>
                                                    <thead>
                                                        <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                            <th style={{ maxWidth: '30px' }}>S No.</th>
                                                            <th style={{ minWidth: '100px' }}>Report Date</th>
                                                            <th style={{ minWidth: '130px' }}>Type of Coating</th>
                                                            <th style={{ width: '200px' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {displayedData.length === 0 ? (
                                                            <tr> <td colSpan="7">No data found for the selected date range. </td> </tr>
                                                        ) : (
                                                            displayedData.map((row, index) => (
                                                                <tr key={row.ClientID} style={getRowColorClass(row.InspectionStatus)}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{new Date(row.InspectionDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '/')}</td>
                                                                    <td>{row.Typeofcoating}</td>
                                                                    <td>
                                                                        <Link to={`/rawmaterialinspectionview/${row.ClientID}`} target='_blank'><i className="fas fa-eye"></i></Link>
                                                                        <span>
                                                                            {row.InspectionStatus === 'P' && <i className="fas fa-hourglass-half" style={{ color: '#FFA100' }}></i>}
                                                                            {row.InspectionStatus === 'A' && <i className="fas fa-check" style={{ color: '#3D7EDB' }}></i>}
                                                                            {row.InspectionStatus === 'R' && <i className="fas fa-times rjct" style={{ color: '#ED2939' }}></i>}
                                                                        </span>
                                                                        {row.InspectionStatus === 'R' && (
                                                                            <Link key={index} to={`/rawmaterialinspection/${row.ClientID}`} target='_blank'>
                                                                                <i className='fas fa-edit' style={{ marginLeft: '10px', color: '#7c7c00' }}></i>
                                                                            </Link>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>

                                                <Pagination pageCount={pageCount} onPageChange={handlePageClick} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </>
            }
        </>
    )
}

export default Rawmaterialinspectionlist;