import React, { useState, useEffect } from "react";
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg'
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import './Rawmaterialinspection.css';
import Loading from '../Loading';
import { Table } from "react-bootstrap";
import { format } from 'date-fns';
import { Link } from 'react-router-dom'
import QCofficerData from "../Raw-material-inspection/RawmaterialinspectionQCofficer.json";
import QCofficerReport from "../Raw-material-inspection/RawmaterialinspectionQCofficerReport.json";

function RawmaterialinspectionQCofficerapproved() {

    // Get the current date
    const currentDate = new Date();

    // Format the date in dd/mm/yyyy format
    const formattedDate = format(currentDate, 'dd/MM/yyyy');

    // -------------------------------------------------

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, [])

    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Header />
                        <section className='InnerHeaderPageSection'>
                            <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <ul>
                                            <li> <Link to='/dashboard'>Quality Module</Link></li>
                                            <li>
                                                <h1>/ &nbsp;
                                                    Raw Material Inspection (QC Officer) (Approve / Reject)
                                                </h1>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="RawMaterialReportSectionPage">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="RawMaterialReportTables">
                                            <form action="" className="row m-0">
                                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                                    <h4 className="QCApprovedBox">
                                                        <span className="TestReportTxt">
                                                            PIPE COATING DIVISION <br />
                                                            <b>RAW MATERIAL TEST REPORT</b>
                                                        </span>
                                                        <span className="FormatNo">FORMAT NO: TSL/COAT/QC/F-25 REV: 04 DATE: 13.11.2021</span>
                                                    </h4>
                                                </div>
                                                {Object.entries(QCofficerData)
                                                    .slice(0, 20)
                                                    .map(([label, value]) => (
                                                        <>
                                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                                <div className="form-group">
                                                                    <label for="">{label}</label>
                                                                    <input type="text" value={value} readonly />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}

                                                {/* <div className='col-md-2 col-sm-2 col-xs-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor="">Test Date</label>
                                                        <input className='formattedDateTestDate' type="text" value={formattedDate} />
                                                    </div>
                                                </div>

                                                <div className='col-md-2 col-sm-2 col-xs-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor="">Shift</label>
                                                        <input className='formattedDateTestDate' type="text" value="Day" />
                                                    </div>
                                                </div> */}
                                            </form>

                                            <div className="row m-0">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <div id="custom-scroll" className="custom-scroll" style={{ overflow: 'auto' }}>
                                                        <Table>
                                                            <thead>
                                                                <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                                    <th style={{ minWidth: '70px' }}>Sr. No.</th>
                                                                    <th style={{ minWidth: '140px' }}>Receiving Date</th>
                                                                    <th style={{ minWidth: '200px' }}>Material Description</th>
                                                                    <th style={{ minWidth: '140px' }}>Manufacturer</th>
                                                                    <th style={{ minWidth: '140px' }}>Grade</th>
                                                                    <th style={{ minWidth: '140px' }}>Batch No.</th>
                                                                    <th style={{ minWidth: '140px' }}>Received Quantity</th>
                                                                    <th style={{ minWidth: '140px' }}>Lab Test Report No.</th>
                                                                    <th style={{ minWidth: '140px' }}>Inspection Status</th>
                                                                    <th style={{ minWidth: '100px' }}>Remark</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {QCofficerReport.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item["Sr_No"]}</td>
                                                                        <td>{item["Receiving_Date"]}</td>
                                                                        <td>{item["Material_Description"]}</td>
                                                                        <td>{item["Manufacturer"]}</td>
                                                                        <td>{item["Grade"]}</td>
                                                                        <td>{item["Batch_No"]}</td>
                                                                        <td>{item["Received_Quantity"]}</td>
                                                                        <td>{item["Lab_Test_Report_No"]}</td>
                                                                        <td>{item["Inspection_Status"]}</td>
                                                                        <td>{item["Remark"]}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>

                                                    <div className="InspectedAcceptedTableFlex">
                                                        <div className="InspectedBox">
                                                            <h2>INSPECTED BY</h2>
                                                            <div className="INSPECTEDBYTdTable">
                                                                <label>Name</label>
                                                                <label>Designation, Department</label>
                                                                <label>Date</label>
                                                            </div>
                                                            <h6>QC ENGINEER</h6>
                                                        </div>
                                                    </div>

                                                    <div className="radio-buttons">
                                                        <div style={{ marginBottom: '20px' }}>
                                                            <label className="custom-radio">
                                                                <input type="radio" className="Approveinput" name="radio" />
                                                                <span className="radio-btn">
                                                                    <i className="fas fa-check"></i>Approve
                                                                </span>
                                                            </label>
                                                            <label className="custom-radio">
                                                                <input type="radio" className="Rejectinput" name="radio" />
                                                                <span className="radio-btn">
                                                                    <i className="fas fa-times"></i>Reject
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-4 col-xs-12">
                                                    <div className='form-group Remarksform-group'>
                                                        <label htmlFor="">Remarks <b>*</b></label>
                                                        <input type="text" />
                                                    </div>
                                                </div>

                                                <div className="col-md-8 col-sm-8 col-xs-12 text-end">
                                                    <Link to='/rawmaterialinspectionQCofficerlist' className="SubmitBtn">Submit</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </>
            }
        </>
    );
}

export default RawmaterialinspectionQCofficerapproved