import React, { useState, useEffect } from "react";
import Loading from '../Loading';
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import axios from 'axios';
import Environment from "../../environment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import secureLocalStorage from "react-secure-storage";

function ListBlastingline() {
  const [rowData, setRowData] = useState([]);
  const [permissions, setPermissions] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const menuId = queryParams.get('menuId');
  const userId = secureLocalStorage.getItem('userId');

  useEffect(() => {
    const Getproductiondata = async () => {
      try {
        const response = await axios.get(Environment.BaseAPIURL + `/api/User/GetProdBlastingdata`);
        const listresposnce = response.data;
        setRowData(listresposnce);

        fetchPermissions()
      } catch (error) {
        toast.error(error.message);
        console.error(error.message);
      }
    };

    Getproductiondata();
  }, []);


  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetPermissionDetailsByPageId`, {
        params: { UserId: userId, PageId: menuId }
      });
      setPermissions(response?.data[0]);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  }

  const getProcessUrl = (data, action) => {
    return `/viewblastingline?id=${12}&pm_Approve_level=${action}&menuId=${menuId}`;
  };

  const handleApproval = (data, action) => {
    navigate(getProcessUrl(data, action));
  };

  const actionCellRenderer = (params) => (
    <div className="action-icons">
      <i
        className="fas fa-eye"
        onClick={() => handleViewClick(params)}
        style={{ color: "#4CAF50", cursor: "pointer" }}
      ></i>
      {permissions?.firstLevelApprove === "1" && (
        <i className="fas fa-check" onClick={() => handleApproval(params, "first")} style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }}></i>
      )}
      {permissions?.secondLevelApprove === "1" && (
        <i className="fas fa-check-double" style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }} onClick={() => handleApproval(params, "second")}></i>
      )}
      {permissions?.editPerm === "1" && (
        <i className="fas fa-edit" style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }} onClick={() => navigate(`/addblastingline?id=${params.data.prodBlastingId}`)}></i>
      )}
    </div>
  );

  const handleViewClick = (params) => {
    console.log(params);
    navigate(`/viewblastingline?id=${params.data.prodBlastingId}`, '_blank');
  };

  const columnDefs = [
    {
      headerName: 'S No.',
      valueGetter: (params) => params.node.rowIndex + 1,
      width: 65,
      headerClass: 'custom-header'
    },
    { headerName: "Process Sheet No.", field: "procsheetno", width: 300, headerClass: 'custom-header' },
    { headerName: "Process Sheet ID", field: "procsheetId", width: 150, headerClass: 'custom-header' },
    { headerName: "Client Name", field: "clientName", width: 400, headerClass: 'custom-header' },
    {
      headerName: "Date",
      field: "importDate",
      width: 180,
      headerClass: 'custom-header align-center',
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          return date.toLocaleDateString('en-GB');
        }
        return '';
      }
    },
    {
      headerName: "Action",
      cellRenderer: actionCellRenderer,
      width: 160,
      headerClass: 'custom-header'
    },
  ];

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header />
          <section className="InnerHeaderPageSection">
            <div className="InnerHeaderPageBg" style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <ul>
                    <li><Link to="/productiondashboard">Production Module</Link></li>
                    <li><h1>/&nbsp; Blasting Line List </h1></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="ProcessSheetdataentrySectionPage">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="ProcessSheetlistTable">
                    <div className="tableheaderflex">
                      <div className="tableheaderfilter">
                        <span> <i className="fas fa-filter"></i> Filter Test Date </span>
                        <label> From Date:
                          <DatePicker
                            maxDate={new Date()}
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            dateFormat="dd/MMM/yyyy"
                            placeholderText="DD/MMM/YYYY"
                          />
                        </label>
                        <label> To Date:
                          <DatePicker
                            maxDate={new Date()}
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            dateFormat="dd/MMM/yyyy"
                            placeholderText="DD/MMM/YYYY"
                          />
                        </label>
                      </div>
                      <div className="tableheaderAddbutton">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}></div>
                        <Link style={{ float: 'right' }} to="/addblastingline" >
                          <i className="fas fa-plus"></i> Add
                        </Link>
                      </div>
                    </div>

                    <div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
                      <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        pagination={true}
                        paginationPageSize={50}
                        suppressDragLeaveHidesColumns="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
}

export default ListBlastingline;
