import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import Environment from "../../../../environment";
import Loading from '../../../Loading';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../../Allreports.css';
import HeaderDataSection from "../../Headerdata";
import ReportRemarks from '../../Report-remarks';
import InstrumentusedSection from '../../Instrument-used';
import Footerdata from '../../Footerdata';
import "../Bare-pipe-insp/BarePipe.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import html2pdf from 'html2pdf.js';
import secureLocalStorage from 'react-secure-storage';

const getInitials = (name) => {
    return name
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase();
};

const combineDataWithWitnesses = (data, witnessesByPipeCode) => {
    return data.map(pipe => {
        const pipeCode = pipe["Pipe No."].value;
        return {
            ...pipe,
            witnesses: witnessesByPipeCode[pipeCode] || []
        };
    });
};

function ChromateCoatInsp() {
    const { tstmaterialid } = useParams();
    const contentRef = useRef();
    const headerDetails = useRef([]);
    const [testDetails, setTestDetails] = useState([]);
    const [instrumentDetails, setInstrumentDetails] = useState([]);
    const [showWitness, setShowWitness] = useState(true)
    const [witnessData, setWitnessData] = useState([])
    const [headers, setHeaders] = useState([])
    const [signatureReport, setSignatureReport] = useState([])
    const [showRemarks, setShowRemarks] = useState([])
    const [reportTestDate, setReportTestDate] = useState()
    const [randomWitnesses, setRandomWitnesses] = useState([])
    const [key, setKey] = useState()
    const [value, setKeyValue] = useState()
    const [rawMaterial, setRawMaterial] = useState([]);
    const [cdLineDetail, setCdLineDetail] = useState([]);
    const [epoxyGuns, setEpoxyGuns] = useState([]);
    const location = useLocation();
    const pathSegments = location.pathname.split(/[\/&]/);
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const [witnessSelected, setWitnessSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [witnessValue, setWitnessValue] = useState('');
    const [isClicked, setIsClicked] = useState(false)
    const [regPerc, setRegPerc] = useState();
    const [randomWitnessList, setRandomWitnessList] = useState([]);
    // const companyId = 631
    const companyId = secureLocalStorage.getItem("emp_current_comp_id")
    const [checkedItems, setCheckedItems] = useState(
        testDetails.map(() => false) // Initialize all checkboxes as unchecked
    );

    const [minRow, setMinRow] = useState();
    const [maxRow, setMaxRow] = useState();

    let pm_project_id1 = null;
    let pm_processSheet_id1 = null;
    let pm_processtype_id1 = null;
    let pm_approved_by1 = null;
    let test_date1 = null;
    let pm_Approve_level1 = null;
    let menuId1 = null;
    for (let i = 0; i < pathSegments.length; i++) {
        if (pathSegments[i].startsWith('pm_project_id=')) {
            pm_project_id1 = pathSegments[i].substring('pm_project_id='.length);
        }
        if (pathSegments[i].startsWith('pm_processSheet_id=')) {
            pm_processSheet_id1 = pathSegments[i].substring('pm_processSheet_id='.length);
        }
        if (pathSegments[i].startsWith('pm_processtype_id=')) {
            pm_processtype_id1 = pathSegments[i].substring('pm_processtype_id='.length);
        }
        if (pathSegments[i].startsWith('pm_approved_by=')) {
            pm_approved_by1 = pathSegments[i].substring('pm_approved_by='.length);
        }
        if (pathSegments[i].startsWith('test_date=')) {
            test_date1 = pathSegments[i].substring('test_date='.length);
        }
        if (pathSegments[i].startsWith('pm_Approve_level=')) {
            pm_Approve_level1 = pathSegments[i].substring('pm_Approve_level='.length);
        }
        if (pathSegments[i].startsWith('menuId=')) {
            menuId1 = pathSegments[i].substring('menuId='.length);
        }
    }

    console.log(typeof pm_project_id1, typeof (parseInt(pm_project_id1)))
    const [id1, id2] = tstmaterialid.split('&');
    const [formData, setFormData] = useState({
        pm_comp_id: 1,
        pm_location_id: 1,
        pm_project_id: parseInt(pm_project_id1),
        pm_processSheet_id: parseInt(pm_processSheet_id1),
        pm_processtype_id: parseInt(pm_processtype_id1),
        pm_remarks: "",
        pm_approver_status: true,
        pm_approved_by: pm_approved_by1,
        pm_approved_on: test_date1,
        pm_Approve_level: pm_Approve_level1 == "first" ? 1 : pm_Approve_level1 == "second" ? 2 : 0,
        pm_approvedRoleId_by: '0',
        p_test_run_id: parseInt(id2)
    });


    async function callWitness() {
        const [id1, id2] = tstmaterialid.split('&');
        const response1 = await axios.post(`${Environment.BaseAPIURL}/api/User/GetEmployeeTypeWithName?p_procsheet_id=${pm_processSheet_id1}&p_test_run_id=${id2}&p_type_id=${pm_processtype_id1}`);
        setWitnessData(response1?.data)
        const hasRejectCountGreaterThanZero = response1?.data.some(item => item.RejectCount > 0);
        const allHaveZeroCounts = response1?.data.every(item => item.ApproveCount === 0 && item.RejectCount === 0);
        const pm_status_app_rej = response1?.data[0]?.pm_status_app_rej
        if (pm_status_app_rej == null || pm_status_app_rej == 0 || pm_status_app_rej == 2 || pm_Approve_level1 == 'second') {
            setShowRemarks(true)
        } else {
            setShowRemarks(false)
        }
        // if (response1?.data.length == 1) {
        setWitnessValue(response1?.data[0]?.roleId)
        setFormData({ ...formData, pm_approvedRoleId_by: witnessValue != '' ? witnessValue : pm_Approve_level1 == 'first' ? response1?.data[0]?.roleId.toString() : companyId.toString() })
        setWitnessSelected(true);
        // }
        const matchingData = response1?.data.find(item => item.roleId == companyId);
        const regPerc = matchingData ? matchingData.reg_perc : null;
        setRegPerc(regPerc)
    }

    const parseKeyValuePair = (str) => {
        // Split the string by ':-'
        const parts = str.split(':-');

        // Trim whitespace from both parts
        const key = parts[0].trim(); // Key before ':-'
        const value = parts[1]?.trim(); // Value after ':-', using optional chaining

        return { key, value };
    };
    const handleCheckboxChange = (index) => {
        setCheckedItems((prev) => {
            const newCheckedItems = [...prev];
            newCheckedItems[index] = !newCheckedItems[index];
            return newCheckedItems;
        });
    };
    const checkedCount = checkedItems.filter(Boolean).length;

    useEffect(() => {
        console.log(tstmaterialid)
        const fetchData = async () => {
            try {
                const [id1, id2] = tstmaterialid.split('&');
                const response = await axios.post(`${Environment.BaseAPIURL}/api/User/Get_ChromateCoatingReport?typeId=${pm_processtype_id1}&testId=${id2}`);
                const data = response.data[0];
                headerDetails.current = data._CdTesHeaderDetails[0]
                const date = data._CdTesHeaderDetails[0].reportTestDate || {}
                const [month, day, year] = date.split('/');
                const formattedDate = `${year}${day}${month}`;
                setReportTestDate(formattedDate);
                setRawMaterial(data._CdTestMat || []);
                setCdLineDetail(data._CdLineDetails[0] || []);
                console.log(data._CdLineDetails[0])
                setTestDetails(data._CdTesMiddleDetails || []);
                setEpoxyGuns(data._epoxygun || []);
                setHeaders(Object.keys(data._CdTesMiddleDetails[0]))
                const { key, value } = parseKeyValuePair(data._CdTesHeaderDetails[0].poNo);
                setKey(key);
                setKeyValue(value)

                const mergedData = data._CdTesMiddleDetails.map(item => {
                    const result = {};
                    Object.keys(item).forEach(key => {
                        const match = data._CdTestMaxMin.find(d => d.co_param_val_alias === key);
                        if (match) {
                            result[key] = {
                                value: item[key],
                                PM_Reqmnt_test_Max: match.PM_Reqmnt_test_Max,
                                PM_Reqmnt_test_min: match.PM_Reqmnt_test_min,
                                PM_Reqmnt_test_plus: match.pm_reqmnt_temp_plus,
                                PM_Reqmnt_test_minus: match.pm_reqmnt_temp_Minus,
                                pm_value_type: match.pm_value_type,
                                pm_test_value: match.pm_test_value
                            };
                        } else {
                            result[key] = { value: item[key] };
                        }
                    });
                    return result;
                });
                console.log(mergedData);
                const witnessesByPipeCode = groupWitnessesByPipeCode(data._RandomWitness);
                setRandomWitnesses(data._RandomWitness)
                console.log(mergedData[0])

                const dataWithWitnesses = combineDataWithWitnesses(mergedData, witnessesByPipeCode);
                console.log(dataWithWitnesses)
                setRandomWitnessList(witnessesByPipeCode)
                const headerss = Object.keys(dataWithWitnesses[0]).filter(key => key !== 'witnesses');
                // const headerss = Object.keys(mergedData[0]);

                // Extract rows
                const minValues = headerss.map(header => mergedData[0][header].pm_value_type == 'A' ? mergedData[0][header].pm_test_value : mergedData[0][header].PM_Reqmnt_test_min || "-");
                const maxValues = headerss.map(header => mergedData[0][header].PM_Reqmnt_test_Max || "-");
                const rows = mergedData.map(entry => {
                    return headerss.map(header => entry[header].value);
                });
                console.log(minValues)
                setMinRow(minValues)
                setMaxRow(maxValues)

                // setTestDetails(rows)

                const response1 = await axios.get(`${Environment.BaseAPIURL}/api/User/GETInstrumentDetailsByReportId?ReportId=${pm_processtype_id1}`);
                const data1 = response1.data[0]
                setInstrumentDetails(data1);

            } catch (error) {
                console.error('Error fetching report data:', error);
            }
            try {
                if (tstmaterialid) {
                    const [id1, id2] = tstmaterialid.split('&');
                    const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetInspectedByAcceptedByDetails?matid=${pm_processtype_id1}&testId=${id2}`);
                    const data = response.data
                    setSignatureReport(data)
                    callWitness()
                }
            } catch (error) {
                console.error('Error fetching report data:', error);
            }
        };
        fetchData();
    }, []);

    const groupWitnessesByPipeCode = (witnesses) => {
        return witnesses.reduce((acc, witness) => {
            if (!acc[witness.pm_pipe_code]) {
                acc[witness.pm_pipe_code] = [];
            }
            acc[witness.pm_pipe_code].push({
                name: witness.co_param_val_name,
                initials: getInitials(witness.co_param_val_name),
                role: witness.RoleName
            });
            return acc;
        }, {});
    };

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0];
        setFormData(prevData => ({ ...prevData, pm_approved_on: currentDate }));
    }, []);

    const handlePrint = () => {
        window.print();
    };

    function handleSelect(e) {
        setWitnessValue(e.target.value)
        setFormData({ ...formData, pm_approvedRoleId_by: e.target.value })
        setWitnessSelected(true);
        if (!showRemarks) {
            handleStatusChange("A")
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleStatusChange = (value) => {
        // setFormData({ ...formData, pm_approver_status: status });
        setIsClicked(true)
        if (value === "A") {
            setFormData({ ...formData, pm_approver_status: true, pm_approvedRoleId_by: witnessValue != '' ? witnessValue : pm_Approve_level1 == 'first' ? witnessValue.toString() : companyId.toString() });
            setWitnessSelected(true);
            setShowWitness(true);
        }
        if (value === "R") {
            setFormData({ ...formData, pm_approver_status: false, pm_approvedRoleId_by: "0" });
            setShowWitness(false);
            setWitnessSelected(false)
        }
    };

    const renderApprovalSection = () => {
        return (
            showRemarks ? <div className='RemarksFlexBox'>
                <label htmlFor="">Remarks</label>
                <input name="pm_remarks" className="form-control" value={formData.pm_remarks} onChange={handleChange} type="text" placeholder="Enter Approval/Rejection Remarks...." autoComplete="off" />
                <div className='ApproveRejectUIFlex'>
                    <label className="custom-radio">
                        <input type="radio" className="Approveinput" name="pm_approver_status" id="btnaprv" onChange={() => handleStatusChange("A")} />
                        <span className="radio-btn"><i className="fas fa-check"></i>Approve</span>
                    </label>
                    <label className="custom-radio">
                        <input type="radio" className="Rejectinput" name="pm_approver_status" id="btnreject" onChange={() => handleStatusChange("R")} />
                        <span className="radio-btn"><i className="fas fa-times"></i>Reject</span>
                    </label>
                </div>
            </div> : ''
        );
    };

    const renderFirstApprovalStatus = () => {
        if (pm_Approve_level1 == "first") {
            return (
                <div className="bare-pipe-inspection">
                    {renderApprovalSection()}
                    {showWitness && (<div className="SelectWitnessFlexBox">
                        <label htmlFor="">
                            Select Witness <b>*</b>
                        </label>
                        <select name="" value={witnessValue} onChange={handleSelect}>
                            <option disabled selected>Select Witness</option>
                            {witnessData && witnessData?.map((data) => {
                                return (
                                    <option value={data?.roleId}>{data?.Name}</option>
                                )
                            })}
                        </select>
                    </div>)}
                    <div className='SubmitBtnFlexBox'>
                        {<button type="button" className="SubmitBtn" onClick={handleSubmit}>Submit</button>}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderSecondApprovalStatus = () => {
        if (pm_Approve_level1 == "second") {
            return (
                <div className='BarePipeInspForm row m-0'>
                    {/* <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className='form-group'>
                            <label htmlFor="">Date of First Approval</label>
                                                                    <span>: &nbsp;</span>
                            <h4>{headerDetails.first_approval_date || "05/30/2024"}</h4>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className='form-group'>
                            <label htmlFor="">Remarks</label>
                                                                    <span>: &nbsp;</span>
                            <h4>{headerDetails.first_approval_remarks || "Remarks"}</h4>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className='form-group'>
                            <label htmlFor="">Approval Status</label>
                                                                    <span>: &nbsp;</span>
                            <h4>{headerDetails.first_approval_status || "Approval Status"}</h4>
                        </div>
                    </div> */}
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className='renderApprovalFlexBox'>
                            {renderApprovalSection()}
                            {<button type="button" onClick={handleSubmit}>Submit</button>}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData, formData.pm_approver_status, 264)
        if (showRemarks) {
            if (formData?.pm_remarks == '' || isClicked == false) {
                toast.error("Please enter remarks and status")
                return
            }
        }
        const checkCount = parseInt(checkedCount)
        const testDetail = parseInt(testDetails.length)
        console.log(testDetail)
        const regPercs = parseInt(regPerc)

        if (showWitness && !witnessSelected && pm_Approve_level1 != "second") {
            toast.error('Please select a witness before submitting the form.');
            return;
        }
        if (pm_Approve_level1 == "second" && regPerc != 100) {
            if (checkCount / testDetail * 100 < regPercs) {
                toast.error(`Please check atleast ${Math.ceil((regPercs / 100) * testDetail)} data`)
                return
            }
        }

        function getPipeNosByIds(checkedItems, testDetails) {
            return checkedItems
                .map((id, index) => id ? testDetails[index]["Pipe No."] : null)
                .filter(pipeNo => pipeNo !== null);
        }

        // Example usage
        const pipeNos = getPipeNosByIds(checkedItems, testDetails);
        console.log(pipeNos)
        // setFormData((...prevData) => [{ prevData, checkedPipes: pipeNos }])
        console.log(formData)

        try {
            const response = await fetch(Environment.BaseAPIURL + "/api/User/InspectionSheetApproval", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ formData, 'checkedPipes': pipeNos ? pipeNos : '' }),
            });

            const responseBody = await response.text();

            if (responseBody === '100' || responseBody === '200') {
                toast.success('Status Updated Successfully!');
                navigate(`/blastingsheetlist?menuId=${menuId1}`)
                console.log("Form data sent successfully!");
            } else {
                console.error(
                    "Failed to send form data to the server. Status code:",
                    response.status
                );
                console.error("Server response:", responseBody);
            }
        } catch (error) {
            console.error("An error occurred while sending form data:", error);
        }
    };

    function condenseData(input) {
        // Split the input string into an array
        let dataArray = input?.split(',');

        // Extract the common prefix
        let commonPrefix = dataArray[0]?.slice(0, -2);

        // Extract the unique numbers
        let uniqueNumbers = dataArray?.map(item => item.split('-').pop());

        // Join the unique numbers into a single string
        let result = commonPrefix + '' + uniqueNumbers.join(', ');

        return result;
    }

    useEffect(() => {
        // fetchData();
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    }, []);

    // ----------------------------------------------------

    const processedMaterials = rawMaterial.map(item => ({
        materialName: item.materialName === 'Chromate' ? 'Chromate' : 'Chromate',
        grade: item.materialName === 'Chromate' ? item.grade : '-',
        manufacturerName: item.materialName === 'Chromate' ? item.manufacturerName : '-',
        batch: item.materialName === 'Chromate' ? item.batch : '-',
    }));

    const chunkAndPadArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            while (chunk.length < chunkSize) {
                chunk.push({ someData: "-" });
            }
            chunks.push(chunk);
        }
        return chunks;
    };

    const chunkedData = chunkAndPadArray(testDetails, 10);

    const handleDownloadPDF = () => {
        const element = contentRef.current;
        const opt = {
            margin: [10, 10, 10, 10],
            filename: `Coating-application-report-${headerDetails.current?.procSheetNo}-${new Date().toLocaleDateString('en-GB').replace(/\//g, "-")}.pdf`,
            image: { type: 'jpeg', quality: 1.0 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save();
    };

    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <div>
                            <div style={{ textAlign: 'right', paddingRight: '14px', paddingTop: '10px' }}>
                                <div className="DownloadPrintFlexSection">
                                    <h4 onClick={handleDownloadPDF}>
                                        <i className="fas fa-download"> </i> Download PDF
                                    </h4>
                                    <h4 onClick={handlePrint}>
                                        <i className="fas fa-print"></i> Print
                                    </h4>
                                </div>
                            </div>
                            <div ref={contentRef}>
                                {chunkedData.map((chunk, chunkIndex) => (
                                    <div key={chunkIndex} className='InspReportSection' ref={contentRef} style={{ pageBreakAfter: 'always' }}>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                                    <div className='InspReportBox'>

                                                        <HeaderDataSection reportData={headerDetails.current} />

                                                        <section className='Reportmasterdatasection'>
                                                            <div className='container-fluid'>
                                                                <form className='row'>
                                                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Client</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4 style={{ textTransform: 'uppercase' }}>{headerDetails.current.clientName}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Report No.</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4>{headerDetails.current?.reportAlias}/{reportTestDate} - {String(chunkIndex + 1).padStart(2, '0')} {headerDetails?.current.reportPqt == '' ? '' : (
                                                                                <> ({headerDetails.current.reportPqt})</>
                                                                            )} </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">{key ? key : ''}.</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4>{value ? value : ''}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Date & Shift</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4 style={{ textTransform: 'uppercase' }}>{headerDetails.current?.dateShift} Shift</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Specification</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4>{headerDetails.current?.specification} REV. 03 DATE: 19.09.2017</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Pipe Size</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4>{headerDetails.current?.pipeSize}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Acceptance Criteria</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4>{headerDetails.current?.acceptanceCriteria}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Type Of Coating</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4>{headerDetails.current?.typeofCoating}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Process Sheet</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4>{headerDetails.current?.procSheetNo} REV. 00 DATE : 08.8.2024</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor="">Procedure / WI No.</label>
                                                                            <span>: &nbsp;</span>
                                                                            <h4>{headerDetails.current?.wino && condenseData(headerDetails.current.wino) || "-"}</h4>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </section>

                                                        {Array.isArray(testDetails) && testDetails.length > 0 && (
                                                            <section className='ReporttableSection'>
                                                                <div className='container-fluid'>
                                                                    <div className='row'>
                                                                        <div className='col-md-12 col-sm-12 col-xs-12'>
                                                                            <div id='custom-scroll'>
                                                                                <table className='ChromateReportCustomTable'>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th colSpan={3} style={{ width: '170px' }}>PIPE DATA</th>
                                                                                            <th colSpan={3} style={{ width: '308px' }}>CHROMATE APPLICATION</th>
                                                                                            <th colSpan={7} style={{ width: '718px' }}>COATING APPLICATION</th>
                                                                                            {pm_Approve_level1 == "second" && regPerc != 100 ? <th>-</th> : ''}
                                                                                            <th rowSpan={2}></th>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>S. NO.</th>
                                                                                            <th>PIPE NO.</th>
                                                                                            <th>ASL NO.</th>
                                                                                            <th>PIPE TEMP. BEFORE CHROMATE(°C)</th>
                                                                                            <th>VISUAL OF CHROMATE APPLICATION</th>
                                                                                            <th>CHROMATE SOLUTION TEMP.(°C)</th>
                                                                                            <th>PIPE TEMP AFTER CHROMATE APPLICATION(°C)</th>
                                                                                            <th>PIPE TEMP BEFORE FBE APPLICATION(°C)</th>
                                                                                            <th>ADHESIVE FILM TEMP.(°C)</th>
                                                                                            <th>PE/PP FILM TEMP.(°C)</th>
                                                                                            <th>WATER TEMP. BEFORE QUENCHING</th>
                                                                                            <th>WATER TEMP. AFTER QUENCHING</th>
                                                                                            <th>REMARKS</th>
                                                                                            {pm_Approve_level1 == "second" && regPerc != 100 ? <th>{checkedCount}/{testDetails.length} Data Verified (Select atleast : {Math.ceil((regPerc / 100) * testDetails.length)})</th> : ""}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th colSpan="2" rowSpan={2} style={{ textTransform: 'uppercase' }}>Specified Requirement</th>
                                                                                            <th>MIN</th>
                                                                                            {minRow?.slice(2)?.map((item, rowIndex) => (
                                                                                                <td key={rowIndex}>{item}</td>
                                                                                            ))}
                                                                                            {pm_Approve_level1 == "second" && regPerc != 100 ? <td>-</td> : ''}
                                                                                            <td>-</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>MAX</th>
                                                                                            {maxRow?.slice(2)?.map((item, rowIndex) => (
                                                                                                <td key={rowIndex}>{item}</td>
                                                                                            ))}
                                                                                            {pm_Approve_level1 == "second" && regPerc != 100 ? <td>-</td> : ''}
                                                                                            <td>-</td>
                                                                                        </tr>
                                                                                        {/* {testDetails?.map((item, rowIndex) => (
                                                                                    <tr key={rowIndex}>
                                                                                        <td key={rowIndex}>{rowIndex + 1}</td>
                                                                                        {headers.map((header, colIndex) => (
                                                                                            <td key={colIndex}>{header}</td>
                                                                                        ))}
                                                                                    </tr>
                                                                                ))} */}

                                                                                        {chunk?.map((row, rowIndex) => {
                                                                                            const hasData = Object.values(row).some(value => value !== undefined && value !== null && value !== '-');

                                                                                            return (
                                                                                                <tr key={rowIndex}>
                                                                                                    {hasData ? <td key="srNo">{rowIndex + 1}</td> : <td key="srNo">{"-"}</td>}
                                                                                                    {headers?.map((cell, cellIndex) => (
                                                                                                        <>
                                                                                                            <td key={cellIndex}>{row[cell]}{row.someData}</td>
                                                                                                        </>
                                                                                                    ))}
                                                                                                    {pm_Approve_level1 == "second" && regPerc != 100 ?
                                                                                                        <>
                                                                                                            <td>{!row.someData ?
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    checked={checkedItems[rowIndex]}
                                                                                                                    onChange={() => handleCheckboxChange(rowIndex)}
                                                                                                                /> : '-'
                                                                                                            }
                                                                                                            </td></> : ''}
                                                                                                    <td>
                                                                                                        {randomWitnessList[row["Pipe No."]] ?
                                                                                                            <div style={{ display: 'flex' }}>
                                                                                                                {/* , borderTop: '1px solid #ddd' */}
                                                                                                                {randomWitnessList[row["Pipe No."]].map((witness, index) => (
                                                                                                                    <div key={index} style={{ marginBottom: '5px' }}>
                                                                                                                        <span style={{ fontSize: '10px', fontWeight: '500' }}>&nbsp;&nbsp;{witness.role.split("_")[1]}-{witness.initials}</span>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </div> : '-'
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                            );
                                                                                        })}
                                                                                        {/* {chunk?.map((row, rowIndex) => (
                                                                                            <tr key={rowIndex}>
                                                                                                <td key={rowIndex}>{rowIndex + 1}</td>
                                                                                                {headers?.map((cell, cellIndex) => (
                                                                                                    <td key={cellIndex}>{row[cell]}{row.someData}</td>
                                                                                                ))}
                                                                                            </tr>
                                                                                        ))} */}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        )}

                                                        {Array.isArray(epoxyGuns) && epoxyGuns.length > 0 && (
                                                            <section className='ReporttableSection'>
                                                                <div className='container-fluid'>
                                                                    <div className='row'>
                                                                        <div className='col-md-12 col-sm-12 col-xs-12'>
                                                                            <div id='custom-scroll'>
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: '190px', borderTop: 'none' }}>Number of Epoxy Guns</th>
                                                                                            {epoxyGuns.map((data, index) => (
                                                                                                <th style={{ borderTop: 'none' }} key={index}>{data.epoxy_gun}</th>
                                                                                            ))}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th>AIR PRESSURE Kg/cm²</th>
                                                                                            {epoxyGuns?.map((item, rowIndex) => (
                                                                                                <>
                                                                                                    <td key={rowIndex}>{item.airpressure == '0' ? '-' : item.airpressure}</td>
                                                                                                </>
                                                                                            ))}
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>FLOW RATE %</th>
                                                                                            {epoxyGuns?.map((item, rowIndex) => (
                                                                                                <>
                                                                                                    <td key={rowIndex}>{item.flowrate == '0' ? '-' : item.flowrate}</td>
                                                                                                </>
                                                                                            ))}
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        )}

                                                        <ReportRemarks reportData={headerDetails.current.testRemarks} />

                                                        <section className='ReporttableSection'>
                                                            <div className='container-fluid'>
                                                                <div className='row'>
                                                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                                                        <div id='custom-scroll'>
                                                                            <table>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th colSpan={11} style={{ textAlign: 'left', borderBottom: "none", padding: '2px 12px' }}>ABOVE RESULTS ARE CONFORMING TO SPECIFICATION :-
                                                                                            <span style={{ fontFamily: 'Myriad Pro Light' }}> {headerDetails.current?.specification} REV. 03 DATED: 19.09.2017 & QAP NO.- {headerDetails.current?.acceptanceCriteria} AND FOUND SATISFACTORY    </span>
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>

                                                        <section className='ReporttableSection'>
                                                            <div className='container-fluid'>
                                                                <div className='row'>
                                                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                                                        <div id='custom-scroll'>
                                                                            <table>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th colSpan={11} style={{ fontSize: '10px', textAlign: 'left', paddingLeft: '10px' }}>Raw Material Used:</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th style={{ minWidth: '70px' }}>Sr. No.</th>
                                                                                        <th style={{ minWidth: '150px' }}>Raw Material</th>
                                                                                        <th style={{ minWidth: '150px' }}>Manufacturer</th>
                                                                                        <th style={{ minWidth: '200px' }}>Grade</th>
                                                                                        <th style={{ minWidth: '40px' }}>Batch No.</th>
                                                                                        <th style={{ minWidth: '40px' }}>Line Speed Mtr. / Min.</th>
                                                                                        <th style={{ minWidth: '40px' }}>Dew Point of Air for Coating Application (°C)</th>
                                                                                        <th style={{ minWidth: '40px' }}>No of Epoxy Gun</th>
                                                                                        <th colSpan={2} style={{ minWidth: '40px' }}>HDPE Screw RPM</th>
                                                                                        <th style={{ minWidth: '40px' }}>Adhesive Screw RPM</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {[
                                                                                        { material: 'Chromate' },
                                                                                        { material: 'FBE Powder' },
                                                                                        { material: 'Adhesive' },
                                                                                        { material: 'Polyethylene (PE)' },
                                                                                    ].map((material, rowIndex) => {
                                                                                        const matchedMaterial = rawMaterial.find(
                                                                                            item => item.materialName === material.material
                                                                                        );

                                                                                        return (
                                                                                            <tr key={rowIndex}>
                                                                                                <td>{rowIndex + 1}</td>
                                                                                                <td>{material.material}</td>
                                                                                                <td>{matchedMaterial ? matchedMaterial.manufacturerName : '-'}</td>
                                                                                                <td>{matchedMaterial ? matchedMaterial.grade : '-'}</td>
                                                                                                <td>{matchedMaterial ? matchedMaterial.batch : '-'}</td>
                                                                                                {rowIndex === 0 && (
                                                                                                    <>
                                                                                                        <td rowSpan={4}>{cdLineDetail?.lineSpeed ? cdLineDetail?.lineSpeed : '-'}</td>
                                                                                                        <td rowSpan={4}>{cdLineDetail?.airCoating ? cdLineDetail?.airCoating : '-'}</td>
                                                                                                        <td rowSpan={4}>{cdLineDetail?.epoxyGun ? cdLineDetail?.epoxyGun : '-'}</td>
                                                                                                    </>
                                                                                                )}
                                                                                                {rowIndex === 0 && (
                                                                                                    <>
                                                                                                        <td>1</td>
                                                                                                        <td>2</td>
                                                                                                    </>
                                                                                                )}
                                                                                                {rowIndex === 1 && (
                                                                                                    <>
                                                                                                        <td rowSpan={4}>{cdLineDetail?.hdpeScrew ? cdLineDetail?.hdpeScrew.split(', ')[0] : '-'}</td>
                                                                                                        <td rowSpan={4}>{cdLineDetail?.hdpeScrew ? cdLineDetail?.hdpeScrew.split(', ')[1] : '-'}</td>
                                                                                                    </>
                                                                                                )}
                                                                                                {rowIndex === 0 && (
                                                                                                    <td rowSpan={4}>{cdLineDetail?.adhesiveScrew ? cdLineDetail?.adhesiveScrew : '-'}</td>
                                                                                                )}
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>

                                                        <InstrumentusedSection reportType={"blasting"} reportData={instrumentDetails} />

                                                        <Footerdata data={signatureReport} witness={randomWitnesses} />

                                                    </div>
                                                    <div className="row text-center">
                                                        <div className='col-md-12 col-sm-12 col-xs-12'>
                                                            {renderFirstApprovalStatus()}
                                                        </div>
                                                        <div className='col-md-12 col-sm-12 col-xs-12'>
                                                            {renderSecondApprovalStatus()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
            }
        </>
    );
}

export default ChromateCoatInsp;