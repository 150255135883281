import React, { useState, useEffect } from "react";
import './Coatingapplicationline.css';
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import axios from 'axios';
import Environment from "../../environment";
import { toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import Loading from "../Loading";
import 'react-toastify/dist/ReactToastify.css';

function Addcoatingapplicationline() {
  const navigate = useNavigate();
  const [year, setYear] = useState('');
  const [ddlYear, setddlYear] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [type, setType] = useState('');
  const userId = secureLocalStorage.getItem("userId");
  const [visible, setVisible] = useState(false);
  const [workview, setWorkview] = useState([]);
  const [formDataHdpe, setFormDataHdpe] = useState({
    hpdescrew1: '',
    hpdescrew2: '',
  })

  const [formData, setFormData] = useState({
    coCompId: 1,
    coLocationId: 1,
    procsheetId: 0,
    epoxyBooth: "1",
    adhesiveDie: "1",
    peDie: "1",
    waterQuenching: "1",
    linePipe: "1",
    trgtPipeTemp: "",
    appWindow: "",
    lineSpeed: "",
    distBwEpoxyToAdhesive: "",
    distBwEpoxyToWaterquenching: "",
    dewPtTemp: "",
    noOfEpoxyGunsOperate: "",
    minAirpressOfEpoxySpray: "",
    reqCoatingThicknessEpoxypowder: "",
    reqCoatingThicknessAdhesive: "",
    totalCoatingThickness: "",
    adhesiveFilmTemp: "",
    peFilmTemperature: "",
    hpdescrew: '',
    adhesivescrew: '',
    flowrate: '',
    airpressure: '',
    epoxygun: '',
  });

  const queryParams = new URLSearchParams(window.location.search);

  // Get the value of 'menuId'
  const id = queryParams.get('id');

  const editApi = () => {
    axios.get(Environment.BaseAPIURL + `/api/User/GetProdApplicationdatabyid?id=${id}`)
      .then((response) => {
        console.log(response.data[0]._lsthdr[0].targetPipeTemp);
        setHeaderData(response.data[0]._lsthdr[0])
        setFormData(prev => [{
          ...prev, epoxyBooth: response.data[0]._lsthdr[0].epoxyBooth,
          adhesiveDie: response.data[0]._lsthdr[0].adhesiveDie,
          peDie: response.data[0]._lsthdr[0].peDie,
          waterQuenching: response.data[0]._lsthdr[0].waterQuenching,
          linePipe: response.data[0]._lsthdr[0].linePipe,
          trgtPipeTemp: response.data[0]._lsthdr[0].targetPipeTemp,
          appWindow: response.data[0]._lsthdr[0].appWindow,
          lineSpeed: response.data[0]._lsthdr[0].lineSpeed,
          distBwEpoxyToAdhesive: response.data[0]._lsthdr[0].epoxyToAdhesive,
          distBwEpoxyToWaterquenching: response.data[0]._lsthdr[0].epoxyToWaterQuench,
          dewPtTemp: response.data[0]._lsthdr[0].dewPoint,
          noOfEpoxyGunsOperate: response.data[0]._lsthdr[0].noOfEpoxyGunOperate,
          minAirpressOfEpoxySpray: response.data[0]._lsthdr[0].minAirPressOfEpoxy,
          reqCoatingThicknessEpoxypowder: response.data[0]._lsthdr[0].reqCoatingEpoxyPwdr,
          reqCoatingThicknessAdhesive: response.data[0]._lsthdr[0].reqCoatingAdhesive,
          totalCoatingThickness: response.data[0]._lsthdr[0].totalCoatingThiknes,
          adhesiveFilmTemp: response.data[0]._lsthdr[0].adhesiveFilmTemp,
          peFilmTemperature: response.data[0]._lsthdr[0].peFilmTemp,
          hpdescrew: response.data[0]._lsthdr[0].peDie,
          adhesivescrew: response.data[0]._lsthdr[0].adhesiveDie,
          flowrate: response.data[0]._lsthdr[0],
          airpressure: response.data[0]._lsthdr[0],
          epoxygun: response.data[0]._lsthdr[0],
        }])
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      setVisible(true)
      editApi()
    }
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  useEffect(() => {
    if (!id) {
      const getYear = () => {
        axios.get(Environment.BaseAPIURL + "/api/User/getprocsheetyear")
          .then((response) => {
            const sortedYears = response.data.sort((a, b) => b.year - a.year);
            setddlYear(sortedYears);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      };
      getYear();
    }
  }, []);

  const handleTypeBlur = () => {
    callApi();
  }

  const callApi = async () => {
    try {
      if (year && type) {
        const response = await axios.post(`${Environment.BaseAPIURL}/api/User/getEPOXYProcessSheetDetails?processsheetno=${type}&year=${year}`);
        const firstDataItem = response.data.Table[0];
        if (response.data.Table.length < 1) {
          toast.error("Process sheet does not exist")
          setVisible(false)
        }
        setFormData((prevState) => ({
          ...prevState,
          procsheetId: response.data.Table[0]?.processsheetid,
          clientname: response.data.Table[0]?.clientname,
          PONo: response.data.Table[0]?.PONo,
          pipesize: response.data.Table[0]?.pipesize,
          projectname: response.data.Table[0]?.projectname,
          typeofcoating: response.data.Table[0]?.typeofcoating,
          userId: userId
        }));
        setHeaderData(firstDataItem || []);
        setFormData((data) => ({ ...data, shiftId: firstDataItem?.pm_shiftvalue }))
        if (firstDataItem) {
          GetEpoxyGunData();
          setVisible(true)
        }
      } else {
        console.error('Invalid year or type:', year, type);
      }
    } catch (error) {
      console.error('Error fetching process sheet details:', error);
    }
  };

  const GetEpoxyGunData = async () => {
    try {
      const Epoxygunresponse = await axios.get(Environment.BaseAPIURL + "/api/User/GetEpoxyGun");
      const data = Epoxygunresponse.data;
      setWorkview(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    if (name === "year") {
      setYear(value);
    } else {
      setType(value);
    }
  };

  const handleHdpeChange = ({ target }) => {
    const { name, value } = target;
    setFormDataHdpe((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;

    if (name == "distBwEpoxyToAdhesive" || name == "distBwEpoxyToWaterquenching") {
      if (/^\d{0,60}$/.test(value)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
    else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const airPressureValues = airpressure.filter(val => val).join(',');
    const flowRateValues = flowrate.filter(val => val).join(',');
    const epoxygun = airpressure.map((ap, index) => {
      if (ap && flowrate[index]) {
        return workview[index]?.epoxygunid
      }
      return null;
    }).filter(data => data).join(',');

    const formatted = {
      ...formData,
      hpdescrew: `${formDataHdpe.hpdescrew1}, ${formDataHdpe.hpdescrew2}`,
      noOfEpoxyGunsOperate: (formData.noOfEpoxyGunsOperate).toString(),
      airpressure: airPressureValues,
      flowrate: flowRateValues,
      epoxygun
    }

    try {
      const response = await axios.post(Environment.BaseAPIURL + "/api/User/InsertprodApplicationdata", formatted);
      if (response.data.responseMessage == "Already Inserted") {
        toast.error("This Process Sheet Already Inserted!");
        return;
      }
      toast.success("Data submitted successfully!");
      navigate('/listcoatingapplicationline');
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    }
  };

  const handleInputChangeNew = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [airpressure, setAirPressure] = useState(Array(24).fill(''));
  const [flowrate, setFlowRate] = useState(Array(24).fill(''));
  const [errors, setErrors] = useState(Array(24).fill({ airpressure: false, flowrate: false }));

  const handleColumnChange = (index, type, value) => {
    const formattedValue = Array.isArray(value) ? value.join(',') : value;

    if (type === 'airpressure') {
      const newAirPressure = [...airpressure];
      newAirPressure[index] = value;
      setAirPressure(newAirPressure);
      updateNoOfEpoxyGunsOperate(newAirPressure);

      if (formattedValue) {
        const newErrors = [...errors];
        newErrors[index] = { ...newErrors[index], airpressure: false };
        setErrors(newErrors);
      }

    } else if (type === 'flowrate') {
      const newFlowRate = [...flowrate];
      newFlowRate[index] = formattedValue;
      setFlowRate(newFlowRate);

      if (formattedValue) {
        const newErrors = [...errors];
        newErrors[index] = { ...newErrors[index], flowrate: false };
        setErrors(newErrors);
      }
    }
  };

  const updateNoOfEpoxyGunsOperate = (newAirPressure) => {
    const filledColumnsCount = newAirPressure.filter(val => val).length;
    setFormData({ ...formData, noOfEpoxyGunsOperate: filledColumnsCount });
  };

  const handleBlur = (index, type) => {
    if (!airpressure[index] && !flowrate[index]) {
      return;
    }
    if (type === 'airpressure') {
      if (!airpressure[index]) {
        toast.error(`Air Pressure (Kg/Cm2) of ${workview[index].epoxygun} cannot be empty`);
      }
    } else if (type === 'flowrate') {
      if (!flowrate[index] && airpressure[index]) {
        toast.error(`Flow Rate (%) of ${workview[index].epoxygun} cannot be empty`);
      } else {
        return;
      }
    }
  };

  return (
    <>
      {loading ? (<Loading />) : (
        <>
          <Header />
          <section className="InnerHeaderPageSection">
            <div className="InnerHeaderPageBg" style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <ul>
                    <li><Link to="/productiondashboard?moduleId=619">Production Module</Link></li>
                    <li><b style={{ color: '#fff' }}>/&nbsp;</b> <Link to="/listcoatingapplicationline"> Coating Application Line List</Link></li>
                    <li><h1>/&nbsp; Coating Application Line </h1></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="RawmaterialPageSection AddproductionparameterchartSection">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="PipeTallySheetDetails">
                    <form className="form row m-0" onSubmit={handleSubmit}>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <h4>Coating Application Line <span>- Add page</span></h4>
                      </div>
                      <div className='col-md-4 col-sm-4 col-xs-12'>
                        <div className='form-group'>
                          <label htmlFor="processSheet">Process Sheet</label>
                          <div className='ProcessSheetFlexBox'>
                            <input id="processSheet" style={{ width: '66%', cursor: 'not-allowed' }} value={headerData.processsheetcode} placeholder='Process sheet no.' readOnly />
                            <select name="year" value={year} onChange={handleTypeChange} >
                              <option value=""> Year </option>
                              {ddlYear.map((coatingTypeOption, i) => (
                                <option key={i} value={coatingTypeOption.year}> {coatingTypeOption.year} </option>
                              ))}
                            </select>
                            <b>-</b>
                            <input id="type" type="number" placeholder='No.' value={type} onChange={handleTypeChange} onBlur={handleTypeBlur} />
                          </div>
                        </div>
                      </div>

                      {[
                        { id: 'clientName', label: 'Client Name', value: headerData?.clientname },
                        { id: 'projectName', label: 'Project Name', value: headerData?.projectname },
                        { id: 'pipeSize', label: 'Pipe Size', value: headerData?.pipesize },
                        { id: 'poNo', label: 'LOI No./PO No./FOA No./LOA No.', value: headerData?.PONo },
                        { id: 'dated', label: 'Dated', value: new Date(headerData?.testdate).toLocaleDateString('en-GB') },
                        { id: 'shift', label: 'Shift', value: headerData?.pm_shiftvalue },
                      ].map(field => (
                        <div key={field.id} className='col-md-4 col-sm-4 col-xs-12'>
                          <div className='form-group'>
                            <label htmlFor={field.id}>{field.label}</label>
                            <input
                              id={field.id}
                              type='text'
                              value={field.value}
                              placeholder={field.label}
                              style={{ cursor: 'not-allowed' }}
                              readOnly
                            />
                          </div>
                        </div>
                      ))}
                      <div className="col-md-12 col-sm-12 col-xs-12"><hr /></div>
                      {visible && (
                        <>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Targeted Pipe Temperature</label>
                              <input
                                type="text"
                                placeholder="Enter targeted pipe temperature"
                                name="trgtPipeTemp"
                                value={formData.trgtPipeTemp}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Application Window</label>
                              <input
                                type="text"
                                placeholder="Enter application window"
                                name="appWindow"
                                value={formData.appWindow}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Line Speed</label>
                              <input
                                type="text"
                                placeholder="Enter line speed"
                                name="lineSpeed"
                                value={formData.lineSpeed}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Distance Between Epoxy Last Spray To Adhesive</label>
                              <input
                                type="number"
                                placeholder="Enter distance between epoxy last spray to adhesive"
                                name="distBwEpoxyToAdhesive"
                                value={formData.distBwEpoxyToAdhesive}
                                onChange={handleInputChange}
                                required
                                pattern="\d{1,60}"
                                minLength={1}
                                maxLength="60"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Distance Between Epoxy Last Spray To Water Quenching</label>
                              <input
                                type="number"
                                placeholder="Enter distance between epoxy last spray to water quenching"
                                name="distBwEpoxyToWaterquenching"
                                value={formData.distBwEpoxyToWaterquenching}
                                onChange={handleInputChange}
                                required
                                pattern="\d{1,60}"
                                minLength={1}
                                maxLength={60}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Dew Point Temp</label>
                              <input
                                type="text"
                                placeholder="Enter dew point temp"
                                name="dewPtTemp"
                                value={formData.dewPtTemp}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">No. Of Epoxy Guns Operate</label>
                              <input
                                style={{ background: 'whitesmoke' }}
                                type="text"
                                placeholder=""
                                name="noOfEpoxyGunsOperate"
                                value={formData.noOfEpoxyGunsOperate}
                                onChange={handleInputChangeNew}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor=""> Coating Thickeness Epoxy Spray</label>
                              <input
                                type="text"
                                placeholder="Enter  coating thickeness epoxy spray"
                                name="reqCoatingThicknessEpoxypowder"
                                value={formData.reqCoatingThicknessEpoxypowder}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor=""> Coating Thickeness Adhesive</label>
                              <input
                                type="text"
                                placeholder="Enter  coating thickeness adhesive"
                                name="reqCoatingThicknessAdhesive"
                                value={formData.reqCoatingThicknessAdhesive}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Total Coating Thickness</label>
                              <input
                                type="text"
                                placeholder="Enter total coating thickness"
                                name="totalCoatingThickness"
                                value={formData.totalCoatingThickness}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Adhesive Film Temperature</label>
                              <input
                                type="text"
                                placeholder="Enter adhesive film temperature"
                                name="adhesiveFilmTemp"
                                value={formData.adhesiveFilmTemp}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">PE Film Temperature</label>
                              <input
                                type="text"
                                placeholder="Enter PE film temperature"
                                name="peFilmTemperature"
                                value={formData.peFilmTemperature}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">HDPE Screw RPM</label>
                              <div style={{ display: 'flex' }}>
                                <input
                                  type="number"
                                  placeholder="Enter HDPE Screw 1"
                                  name="hpdescrew1"
                                  value={formDataHdpe.hpdescrew1}
                                  onChange={handleHdpeChange}
                                  required
                                />
                                <input
                                  style={{ marginLeft: '10px' }}
                                  type="number"
                                  placeholder="Enter HDPE Screw 2"
                                  name="hpdescrew2"
                                  value={formDataHdpe.hpdescrew2}
                                  onChange={handleHdpeChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="">Adhesive Screw RPM</label>
                              <input
                                type="number"
                                placeholder="Enter Adhesive Screw RPM"
                                name="adhesivescrew"
                                value={formData.adhesivescrew}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="form-group">
                              <div className="table-responsive AddCoatingApplicationTable" id="custom-scroll">
                                <table>
                                  <thead>
                                    <tr style={{ background: 'whitesmoke' }}>
                                      <th style={{ minWidth: '140px', textAlign: 'left' }}>No. of Epoxy Guns</th>
                                      {workview.map((item, index) => (
                                        <th>{item.epoxygun}</th>
                                      )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td style={{ textAlign: 'left' }}>Air Pressure (Kg/Cm2)</td>
                                      {airpressure.map((value, index) => (
                                        <td key={index}>
                                          <input
                                            name="airpressure"
                                            type="number"
                                            className={errors[index].airpressure ? 'input-error' : ''}
                                            value={value}
                                            onChange={(e) => handleColumnChange(index, 'airpressure', e.target.value)}
                                            onBlur={() => handleBlur(index, 'airpressure')}
                                            required={!!flowrate[index]}
                                          />
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <td style={{ textAlign: 'left' }}>Flow Rate (%)</td>
                                      {flowrate.map((value, index) => (
                                        <td key={index}>
                                          <input
                                            name="flowrate"
                                            type="number"
                                            className={errors[index].flowrate ? 'input-error' : ''}
                                            value={value}
                                            onChange={(e) => handleColumnChange(index, 'flowrate', e.target.value)}
                                            onBlur={() => handleBlur(index, 'flowrate')}
                                            disabled={!airpressure[index] && value === ''}
                                            required={!!airpressure[index]}
                                          />
                                        </td>
                                      ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="RegisterEmployeeFooter">
                              <span></span>
                              <button type="submit" className="SubmitNextbtn">  Submit</button>
                            </div>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div >
          </section >
          <Footer />
        </>
      )}
    </>
  );
}

export default Addcoatingapplicationline;