import React, { useState, useEffect } from "react";
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg'
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import './Rawmaterialinspection.css';
import Loading from '../Loading';
import Pagination from '../Common/Pagination/Pagination';
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import axios from 'axios';
// import Environment from "../../environment";

function RawmaterialinspectionQAofficerlist() {

    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://65b8e041b71048505a89ab75.mockapi.io/api/User/rwilist/list");
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // -----------------------------------------------------------
    const [filterStatus, setFilterStatus] = useState(null);

    const filterData = () => {
        let filteredData = data.filter(item => {
            const reportDate = new Date(item.InspectionDate);
            const startDate = fromDate ? new Date(fromDate) : null;
            const endDate = toDate ? new Date(toDate) : null;

            return (
                (!startDate || reportDate >= startDate) &&
                (!endDate || reportDate <= endDate)
            );
        });

        if (filterStatus) {
            filteredData = filteredData.filter(item => item.InspectionStatus === filterStatus);
        }

        return filteredData;
    };

    const handleFilterStatus = (status) => {
        setFilterStatus(status === filterStatus ? null : status);
    };

    const filteredData = filterData();

    const setStartDate = date => {
        setFromDate(date);
    };

    const setEndDate = date => {
        setToDate(date);
    };

    const resetFilter = () => {
        setFromDate('');
        setToDate('');
        setFilterStatus('');
    };

    // -----------------------------------------------------------

    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;

    const pageCount = Math.ceil(filteredData.length / pageSize);

    const displayedData = filteredData.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize
    );

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    // -----------------------------------------------------------

    const getRowColorClass = (status) => {
        switch (status) {
            case 'P':
                return { backgroundColor: '#fff' };
            case 'A':
                return { backgroundColor: '#f0f2f5' };
            case 'R':
                return { backgroundColor: '#ffe9eb' };
            default:
                return {};
        }
    }

    // -----------------------------------------------------------

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, [])

    // -----------------------------------------------------------

    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Header />
                        <section className='InnerHeaderPageSection'>
                            <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <ul>
                                            <li> <Link to='/dashboard'>Quality Module</Link></li>
                                            <li><h1> / &nbsp; Raw Material Inspection </h1></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="RawMaterialReportSectionPage RawMaterialInspectionQASectionPage">
                            <div className="container">
                                <div className="row">
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <div className='RawmateriallistTables'>
                                            <h4>Raw Material Inspection <span>- QC Officer List page</span></h4>
                                            <div className='tableheaderflex'>
                                                <div className='tableheaderfilter'>
                                                    <span><i class="fas fa-filter"></i> Filter Test Data</span>
                                                    <label> From Date:
                                                        <DatePicker
                                                            maxDate={Date.now()}
                                                            selected={fromDate}
                                                            onChange={setStartDate}
                                                            dateFormat="dd/MMM/yyyy"
                                                            placeholderText="DD/MMM/YYYY"
                                                        />
                                                    </label>
                                                    <label> To Date:
                                                        <DatePicker
                                                            maxDate={Date.now()}
                                                            selected={toDate}
                                                            onChange={setEndDate}
                                                            dateFormat="dd/MMM/yyyy"
                                                            placeholderText="DD/MMM/YYYY"
                                                            minDate={fromDate}
                                                        />
                                                    </label>
                                                    <i class="fa fa-refresh" onClick={resetFilter}></i>
                                                </div>
                                            </div>

                                            <div className="ColorPatternFlex">
                                                <span><i className="fas fa-eye"></i> View</span>
                                                <span className="Default" onClick={() => handleFilterStatus('P')} style={{ color: '#FFA100' }}><b></b> Pending</span>
                                                <span className="Approve" onClick={() => handleFilterStatus('A')} style={{ color: '#3D7EDB' }}><b></b> Approved</span>
                                                <span className="Reject" onClick={() => handleFilterStatus('R')} style={{ color: '#ED2939' }}><b></b> Returned</span>
                                            </div>

                                            <div className='table-responsive' id='custom-scroll'>
                                                <table className="QCofficerTable">
                                                    <thead>
                                                        <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                            <th style={{ minWidth: '60px' }}>S No.</th>
                                                            <th style={{ minWidth: '100px' }}>Report Date</th>
                                                            <th style={{ minWidth: '120px' }}>Type of Coating</th>
                                                            <th style={{ width: '170px' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {displayedData.length === 0 ? (
                                                            <tr> <td colSpan="7">No data found for the selected date range.</td> </tr>
                                                        ) : (
                                                            displayedData.map((row, index) => (
                                                                <tr key={row.srNoinspectionId} style={getRowColorClass(row.InspectionStatus)}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{new Date(row.InspectionDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '/')}</td>
                                                                    <td>{row.Typeofcoating}</td>
                                                                    <td>
                                                                        <Link to='/rawmaterialinspectionview' target='_blank'><i class="fas fa-eye"></i></Link>
                                                                        <span>
                                                                            {row.InspectionStatus === 'P' ? (
                                                                                <Link to='/rawmaterialinspectionQCofficerapproved' target='_blank' style={{ color: '#FFA100', cursor: 'default', textDecoration: 'underline' }}>Pending</Link>
                                                                            ) : (row.InspectionStatus === 'A' ? (
                                                                                <span style={{ color: '#3D7EDB' }}>Approved</span>
                                                                            ) : (
                                                                                <span style={{ color: '#ED2939' }}>Returned</span>
                                                                            ))}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>

                                                <Pagination pageCount={pageCount} onPageChange={handlePageClick} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </>
            }
        </>
    );
}

export default RawmaterialinspectionQAofficerlist