import React, { useState, useEffect, useRef } from "react";
import Loading from "../Loading";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import Select, { components } from "react-select";
import { toast } from 'react-toastify';
import axios from "axios";
import Environment from "../../environment";
import "./assignlabfield.css";
import secureLocalStorage from "react-secure-storage";

const AssignLabFieldTest = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const LabField = searchParams.get("test");
  const empId = secureLocalStorage.getItem("empId");
  const { Option } = components;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ddlYear, setddlYear] = useState([]);
  const [ddlprocedure, setProcedure] = useState([]);
  const [formData, setFormData] = useState({
    psYear: '',
    psSeqNo: '',
  });
  const [pipeCoatData, setPipeCoatData] = useState([]);
  const [selectedTests, setSelectedTests] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredFieldData, setFilteredData] = useState([]);
  const filteredLabData = useRef([]);
  const [processSheetNo, setProcessSheetNo] = useState();
  const [fieldType, setFieldType] = useState();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    fetchData();
    setFieldType(LabField == "Field" ? "F" : "L")
  }, []);


  const CustomOption = (props) => {
    return (
      <Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            style={{ width: '20px', marginRight: '8px' }}
          />{' '}
          <label>{props.label}</label>
        </div>
      </Option>
    );
  };

  const fetchData = () => {
    axios.get(Environment.BaseAPIURL + "/api/User/GetProcessSheetDetailsList")
      .then((response) => {
        setProcedure(response?.data[3]);
        setddlYear(response?.data[4]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const resetFormData = () => {
    setFormData((prev) => ({
      psSeqNo: prev.psSeqNo,
      psYear: prev.psYear,
      clientname: '',
      project_name: '',
      pipesize: '',
      coating_type: '',
      procedure: ''
    }));
  };

  const handlePsSeqNoBlur = () => {
    if (formData.psSeqNo) {
      getHeaderData();
      resetFormData();
    }
  };

  const getHeaderData = () => {
    setProcessSheetNo(formData.psSeqNo)
    axios.get(Environment.BaseAPIURL + `/api/User/GetTestBlastingdataEntry?processsheetno=${formData?.psSeqNo}&processtype=0`)
      .then((response) => {
        setFormData(response?.data[3][0]);
        if (response.data[3][0]) {
          labPipeCoatData(formData?.psSeqNo);
        }
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
      });
  };

  const labPipeCoatData = (id) => {
    console.log(id)
    axios.get(Environment.BaseAPIURL + `/api/User/Assignfieldlabtestonpipe?processsheet_id=${formData?.psSeqNo}&compid=1&locationid=1`)
      .then((response) => {
        const data = response?.data;
        const filteredData = data.filter(item => {
          if (LabField === "Field") {
            return item.pm_isfield === 1;
          } else if (LabField === "Lab") {
            return item.pm_islab === 1;
          }
          return false;
        });
        labFieldTestData(id, filteredData)
        setPipeCoatData(filteredData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const labFieldTestData = (id, data) => {
    console.log(id)
    axios.get(Environment.BaseAPIURL + `/api/User/Getlabfielddata?procsheet_id=${id}`)
      .then((response) => {
        const mappedData = data.map(item1 => {
          const matchedTests = response?.data.filter(item2 => item2.CoattypeID === item1.coatid && item2.type === (LabField == "Lab" ? "L" : "F"));
          return {
            ...item1,
            tests: matchedTests.map(test => ({
              value: test.id,
              label: test.testname
            }))
          };
        });
        // const selectOptions = mappedData.map(item => ({
        //   // label: item.pipename,
        //   options: item.tests
        // }));

        setFilteredData(mappedData);
        const initialSelectedTests = {};
        mappedData.forEach(pipe => {
          initialSelectedTests[pipe.pipeid] = pipe.tests;
        });
        setSelectedTests(initialSelectedTests);
        // const data_new = mappedData.map((data) => data.tests)
        // console.log(data_new)
        // setFilteredData(data_new)
        // console.log(mappedData, pipeCoatData, response.data)
        // if (LabField == "Lab") {
        //   filteredLabData.current = data.filter(test => test.type == "L" && test.CoattypeID === coatid)
        // }
        // else {
        //   filteredFieldData.current = data.filter(test => test.type == "F" && test.CoattypeID === coatid)
        // }
        // console.log(filteredFieldData, filteredLabData, data.filter(test => test.type == "F" && test.CoattypeID === coatid), data.filter(test => test.type == "L" && test.CoattypeID === coatid))
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const checkLabField = (id) => {
    console.log(LabField)
    console.log(filteredFieldData.filter(test => test.type == "F" && test.CoattypeID === id))
  }

  const handleTestChange = (selectedOptions, pipeid) => {
    setSelectedTests(prevState => ({
      ...prevState,
      [pipeid]: selectedOptions
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedTests)
    console.log(pipeCoatData)
    const testType = LabField === "Lab" ? "L" : (LabField === "Field" ? "F" : "");
    const dataToSend = {
      co_comp_id: 1,
      co_location_id: 1,
      procsheet_id: formData?.procsheet_id,
      projectid: formData?.project_id,
      testtype: testType,
      userid: empId,
      testsData: pipeCoatData.map((pipe, index) => (
        {
          pipe_id: (pipe.pipeid).toString(),
          fieldtest: (selectedTests[pipe.pipeid]?.map(test => test.value).join('@#@')) || '' //.toString()
        }))
    };

    axios.post(Environment.BaseAPIURL + "/api/User/UpdateAssignfieldlabtestonpipeEntry", dataToSend)
      .then(response => {
        console.log('Data submitted successfully', response);
        if (response.status === 200) {
          toast.success("Data submitted successfully")
          navigate('/inspectiontesting?menuId=24');
        }
      })
      .catch(error => {
        console.error("Error submitting data:", error);
        toast.error("Failed to submit")
      });
  };

  return (
    <>
      {loading ? (<Loading />
      ) : (
        <>
          <Header />
          <section className="InnerHeaderPageSection">
            <div className="InnerHeaderPageBg" style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <ul>
                    <li><Link to="/dashboard?moduleId=618">Quality Module</Link></li>
                    <b style={{ color: '#fff' }}>/ &nbsp;</b>
                    <li> <Link to='/inspectiontesting?menuId=24'> Raw Material Test & Inspection </Link> <b style={{ color: '#fff' }}></b></li>
                    <li><h1>/&nbsp; Assign {LabField} Test On Pipe(s) </h1></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="RawmaterialPageSection BlastingDataEntrySectionPage AssignLabFieldTest">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="PipeTallySheetDetails">
                    <form className="form row m-0" onSubmit={handleSubmit}>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <h4>Assign {LabField} Test On Pipe(s)</h4>
                      </div>
                      <div className='col-md-4 col-sm-4 col-xs-12'>
                        <div className='form-group'>
                          <label htmlFor="">Process Sheet</label>
                          <div className='ProcessSheetFlexBox'>
                            <input
                              name="processSheet"
                              placeholder='Process sheet'
                              value={formData?.["pm_procsheet_code"] || ''}
                              onChange={handleInputChange}
                              style={{ width: '66%', cursor: 'not-allowed' }}
                            />
                            <select name="psYear" value={formData?.psYear} onChange={handleInputChange} >
                              <option value=""> Year </option>
                              {ddlYear.map((yearOption, i) => (
                                <option key={i} value={yearOption.year_value}> {yearOption.year_value} </option>
                              ))}
                            </select>
                            <b>-</b>
                            <input
                              type="number"
                              name="psSeqNo"
                              value={formData?.psSeqNo}
                              onChange={handleInputChange}
                              placeholder='No.'
                              onBlur={handlePsSeqNoBlur}
                            />
                          </div>
                        </div>
                      </div>

                      {[
                        { label: "Client Name", value: formData?.clientname },
                        { label: "Project Name", value: formData?.["project_name"] },
                        { label: "Pipe Size", value: formData?.pipesize },
                        { label: "Type Of Coating", value: formData?.["coating_type"] },
                        // { label: "Procedure/ WI No.", value: formData?.procedure },
                      ].map((field, idx) => (
                        <div key={idx} className="form-group col-md-4 col-sm-4 col-xs-12">
                          <label>{field.label}</label>
                          <input type="text" value={field.value} placeholder={field.label} readOnly />
                        </div>
                      ))}

                      {formData?.["pm_procsheet_code"] && (
                        <div className="firsttable" id="custom-scroll">
                          <table>
                            <thead>
                              <tr style={{ background: "rgb(90, 36, 90)", top: "0px", }}>
                                <th style={{ minWidth: "60px" }}>Sr No.</th>
                                <th style={{ minWidth: "120px" }}>Pipe no.</th>
                                <th style={{ minWidth: "160px" }}>Coat Status</th>
                                <th style={{ minWidth: "200px" }}>{LabField} Test</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredFieldData?.map((data, index) => (
                                <tr key={data.pipeid}>
                                  <td>{index + 1}</td>
                                  <td><input type="text" value={data?.pipename} readOnly /></td>
                                  <td><input type="text" value={data?.coatname} readOnly /></td>
                                  <td>
                                    {console.log(filteredFieldData)}
                                    <Select
                                      components={{ Option: CustomOption }}
                                      isMulti
                                      name={`test_${index}`}
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      allowSelectAll={true}
                                      options={data.tests}
                                      classNamePrefix="select"
                                      value={selectedTests[data.pipeid]}
                                      onChange={(selectedOptions) => handleTestChange(selectedOptions, data.pipeid)}
                                    />
                                  </td>
                                </tr>
                              ))}
                              {console.log(fieldType)}
                            </tbody>
                          </table>
                          <div className="col-md-12 col-sm-12 col-xs-12 mt-4">
                            <button type="submit" className="SubmitBtn" style={{ float: "right" }}>Submit</button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default AssignLabFieldTest;