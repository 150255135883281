import React, { useState, useEffect } from "react";
import './Coatingapplicationline.css';
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import axios from 'axios';
import Environment from "../../environment";
import Loading from "../Loading";
import Processsheetfooter from '../Common/Process-sheet-footer/Processsheetfooter';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import secureLocalStorage from "react-secure-storage";
import tatasteellogo from "../../assets/images/tsl-blue-logo.png";
import tatalogo from "../../assets/images/tata-blue-logo.png";
import tatastamp from '../Reports/Stamps.png';


function Viewcoatingapplicationline() {


  const searchParams = new URLSearchParams(window.location.search);
  const Id = searchParams.get("id");
  const empId = secureLocalStorage.getItem("empId")
  const today = new Date();
  const formattedDate = today.getFullYear() + '-' +
    String(today.getMonth() + 1).padStart(2, '0') + '-' +
    String(today.getDate()).padStart(2, '0');
  const [workview, setWorkview] = useState([]);
  const [formData, setFormData] = useState({
    pm_comp_id: "1",
    pm_location_id: "1",
    pm_project_id: "",
    pm_processsheet_id: "",
    pm_approver_type: "",
    pm_remarks: "",
    pm_testdate: formattedDate,
    pm_approver_status: "",
    pm_approved_by: empId.toString(),
  });
  const [ShowTestDate, setShowTestDate] = useState("");
  const [ShowApprovalSection, setShowApprovalSection] = useState("");
  const [approvalData, setApprovalData] = useState([])
  const [showWitness, setShowWitness] = useState(false)
  const navigate = useNavigate()

  const params = new URLSearchParams(window.location.search);
  // Get the value of the 'pm_Approve_level' parameter
  const level = params.get('pm_Approve_level');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Environment.BaseAPIURL + `/api/User/GetProdApplicationdatabyid?id=${Id}`);
        const data = response.data;
        setWorkview(data[0]);
        if (level == 'first') {
          setShowApprovalSection(true)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  const handleChange = (event, index) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(Environment.BaseAPIURL + "/api/User/ProcessSheetApproval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
      );

      const responseBody = await response.text();

      if (responseBody === "100" || responseBody === "200") {
        toast.success("Form data sent successfully!");
        navigate("/processsheetlist?menuId=7")

      } else {
        console.error("Failed to send form data to the server. Status code:", response.status);
        console.error("Server response:", responseBody);
      }
    } catch (error) {
      console.error("An error occurred while sending form data:", error);
    }
  };

  const handleStatusChange = (value) => {
    if (value === "A") {
      setFormData({ ...formData, pm_approver_status: "A" });
      setShowWitness(true)
    }
    if (value === "R") {
      setFormData({ ...formData, pm_approver_status: "R" });
      setShowWitness(false)
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // getYear();
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  const epoxyToAdhesive = workview?.epoxyToAdhesive || 0;
  const epoxyToWaterQuench = workview?.epoxyToWaterQuench || 0;
  console.log(Number(workview?.epoxyToWaterQuench - workview?.epoxyToAdhesive), workview?.epoxyToWaterQuench, workview?.epoxyToAdhesive)
  const totalDifference = Number(epoxyToWaterQuench - epoxyToAdhesive);


  return (
    <>
      {loading ? (<Loading />) : (
        <>
          {/* <Header /> */}
          {/* <section className="InnerHeaderPageSection">
            <div className="InnerHeaderPageBg" style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <ul>
                    <li><Link to="/productiondashboard?moduleId=619">Producergregtion Module</Link></li>
                    <li><b style={{ color: '#fff' }}>/&nbsp;</b> <Link to="/listcoatingapplicationline"> Coating Application Line List</Link></li>
                    <li><h1>/&nbsp; Coating Application Line </h1></li>
                  </ul>
                </div>
              </div>
            </div>
          </section> */}
          <div>
            <div class="DownloadPrintFlexSection">
              <h4><i class="fas fa-download"> </i> Download PDF</h4>
              <h4><i class="fas fa-print"></i> Print</h4>
            </div>
            <section className="AddproductionparameterchartSection">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="PipeTallySheetDetails">
                      <form className="form row m-0">
                        <section className="HeaderDataSection" style={{ borderBottom: 'none', padding: '10px 0' }}>
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="HeaderDataFlexdisplay">
                                  <img className="tatasteellogoimg" src={tatasteellogo} alt="" />
                                  <img className="tatalogoimg" src={tatalogo} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <table className="AddproductionparameterchartTable">
                          <thead>
                            <tr>
                              <th colSpan={2}>PIPE COATING DIVISION - KHOPOLI</th>
                            </tr>
                            <tr>
                              <th colSpan={2}>COATING APPLICATION LINE : 3LPE COATING WINDOW</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td style={{ textTransform: 'uppercase', borderBottom: 'none', borderRight: 'none' }}>CLIENT : {workview?.clientName || 'BHARAT PETROLEUM CORPOATION LIMITED'}</td>
                              <td style={{ borderBottom: 'none', borderLeft: 'none' }}>DATE: {formatDate(workview?.importdate || '12.10.2023')}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} style={{ borderTop: 'none' }}>PROJECT : {workview?.projectName || 'PROCUREMENT OF API 5L PSL2 3LPE COATED LINE PIPE BPCL CGD PROJECTS'} </td>
                            </tr>
                            <tr>
                              <td style={{ textTransform: 'uppercase', borderBottom: 'none', borderRight: 'none' }}>PIPE SIZE : {workview?.pipeSize || '219.1 MM OD X 7.1 MM WT'}</td>
                              <td style={{ borderBottom: 'none', borderLeft: 'none' }}>COATING TYPE : {workview?.coatType || '3LPE'} </td>
                            </tr>
                            <tr>
                              <td colSpan={2} style={{ borderTop: 'none' }}>SPEC NO. : {workview?.specification || 'SPECIFICATION FOR 3-LAYER POLYETHYLENE COATING OF LINEPIPES, Specification No. BPCL/S/05/21/014'} </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>PO.NO. : {workview?.loiNo || '4600960937 Dated.17.08.2023'} </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="cpldiagramBox">
                          <div style={{ display: "flex" }}>
                            <div className="topleftBox">
                              Epoxy Booth
                              <span className="topleftSpan1"></span>
                              <div style={{ textAlign: "center", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                                <span className="topleftSpan2"></span>
                                <span className="topleftSpan2"></span>
                                <span className="topleftSpan2"></span>
                                <span className="topleftSpan2"></span>
                              </div>
                            </div>

                            <div className="topRightBox">
                              <span className="topRightSpan1">
                                <span className="topRightSpan2">
                                  <span className="topRightSpan3">
                                    <b>Adhesive Die</b>
                                  </span>
                                  <span className="topRightSpan3">
                                    <b style={{ textAlign: "right", paddingRight: "10px" }}>PE Die</b>
                                  </span>
                                </span>

                                <div className="topRightSpan4">
                                  <b>Water Quenching</b>
                                  <span className="topRightSpan5"></span>
                                  <span className="topRightSpan5"></span>
                                  <span className="topRightSpan5"></span>
                                  <span className="topRightSpan5"></span>
                                  <span className="topRightSpan5"></span>
                                  <span className="topRightSpan5"></span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="bottomBox">
                            <b className="LinePipeTxt">
                              <hr />
                              Line Pipe
                            </b>
                            <span className="Span1"></span>
                            <span className="Span2"></span>
                            <span className="Span3">
                              <hr />
                              <b>{workview?.epoxyToAdhesive} mm <span>{totalDifference} mm</span></b>

                              <hr style={{ bottom: '-70px' }} />
                              <b style={{ bottom: '-90px', justifyContent: "center" }}>{workview?.epoxyToWaterQuench} mm</b>
                            </span>
                          </div>
                          <div className="ppcUlList" id="custom-scroll">
                            <table className="ppcUlListtable">
                              <thead>
                                <tr>
                                  <th style={{ width: '100px' }}>S No.</th>
                                  <th style={{ width: '500px' }}>Name</th>
                                  <th style={{ width: '500px' }}>Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>Targeted Pipe Temperature</td>
                                  <td>{workview?.targetPipeTemp || 'Minimum 190 C or as per manufacturer'}</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>Application Window</td>
                                  <td>{workview?.appWindow || '11 to 14 sec'}</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>Line Speed</td>
                                  <td>{workview?.lineSpeed || '5.5 (+-0.1) Mtr./Min'}</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>Distance between Epoxy last Spray to adhesive</td>
                                  <td>{workview?.epoxyToAdhesive || '1150 mm'}</td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>Distance between Epoxy last Spray to Water Quenching</td>
                                  <td>{workview?.epoxyToWaterQuench || '3900 mm'}</td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>Dew point temp</td>
                                  <td>{workview?.dewPoint || '-'}</td>
                                </tr>
                                <tr>
                                  <td>7</td>
                                  <td>No. Of Epoxy Guns operate</td>
                                  <td>{workview?.noOfEpoxyGunOperate || '-'}</td>
                                </tr>
                                {/* <tr>
                                  <td>8</td>
                                  <td>Minimum Air Pressure of Epoxy Spray</td>
                                  <td>{workview?.minAirPressOfEpoxy}</td>
                                </tr> */}
                                <tr>
                                  <td>8</td>
                                  <td>Required Coating Thickness Epoxy Powder</td>
                                  <td>{workview?.reqCoatingEpoxyPwdr || '-'}</td>
                                </tr>
                                <tr>
                                  <td>9</td>
                                  <td>Required Coating Thickness Adhesive</td>
                                  <td>{workview?.reqCoatingAdhesive || '-'}</td>
                                </tr>
                                <tr>
                                  <td>10</td>
                                  <td>Total Coating Thickness</td>
                                  <td>{workview?.totalCoatingThiknes || '-'}</td>
                                </tr>
                                <tr>
                                  <td>11</td>
                                  <td>Adhesive Film Temperature</td>
                                  <td>{workview?.adhesiveFilmTemp || '-'}</td>
                                </tr>
                                <tr>
                                  <td>12</td>
                                  <td>PE Film Temperature</td>
                                  <td>{workview?.peFilmTemp || '-'}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                      <Processsheetfooter data={approvalData} />
                      {ShowApprovalSection && (
                        <>
                          <div class="row mt-4">
                            <div className="col-md-4 col-sm-4 col-xs-12">
                              <div className="form-group Remarksform-group">
                                <label htmlFor="">Remarks <b>*</b></label>
                                <input
                                  name="pm_remarks"
                                  class="form-control"
                                  value={formData.pm_remarks}
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Enter Approval/Rejection Remarks...."
                                  autoComplete="off"
                                />
                              </div>
                              <label className="custom-radio">
                                <input
                                  type="radio"
                                  className="Approveinput"
                                  name="pm_approver_status"
                                  id="btnaprv"
                                  onChange={() => handleStatusChange("A")}
                                />
                                <span className="radio-btn"><i class="fas fa-check"></i>Approve</span>
                              </label>
                              <label className="custom-radio">
                                <input
                                  type="radio"
                                  className="Rejectinput"
                                  name="pm_approver_status"
                                  id="btnreject"
                                  onChange={() => handleStatusChange("R")}
                                />
                                <span className="radio-btn"><i class="fas fa-times"></i>Reject</span>
                              </label>
                            </div>
                            <div className="col-md-5 col-sm-5 col-xs-12">
                              <div style={{ display: 'flex' }}>
                                {ShowTestDate && (
                                  <div className="form-group Remarksform-group EffectiveDateDiv">
                                    <label htmlFor="">Effective Date <b>*</b></label>
                                    <input
                                      type="date"
                                      class="form-control"
                                      autoComplete="off"
                                      name="pm_testdate"
                                      max={new Date().toISOString().split("T")[0]}
                                      value={formData.pm_testdate}
                                      onChange={handleChange}
                                      placeholder="Enter Effective Date...."
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <button type="button" className="SubmitBtn" onClick={handleSubmit}>Submit</button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <Footer /> */}
        </>
      )}
    </>
  );
}

export default Viewcoatingapplicationline