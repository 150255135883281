import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import axios from 'axios';
import Environment from "../../environment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Loading from '../Loading';
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import secureLocalStorage from "react-secure-storage";

function Listcoatingapplicationline() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const menuId = queryParams.get('menuId');
  const userId = secureLocalStorage.getItem('userId');

  const getProcessUrl = (data, action) => {
    return `/viewcoatingapplicationline?id=${data.data.prodAppId}&pm_Approve_level=${action}&menuId=${menuId}`;
  };

  const handleApproval = (data, action) => {
    navigate(getProcessUrl(data, action));
  };

  useEffect(() => {
    const fetchCoatingAppData = async () => {
      try {
        const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetProdApplicationdata`);
        setRowData(response.data);
        fetchPermissions()
      } catch (error) {
        toast.error("Failed to fetch data.");
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCoatingAppData();
  }, []);

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetPermissionDetailsByPageId`, {
        params: { UserId: userId, PageId: menuId }
      });
      setPermissions(response?.data[0]);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  }


  const actionCellRenderer = (params) => (
    <div className="action-icons">
      <i
        className="fas fa-eye"
        onClick={() => handleViewClick(params)}
        style={{ color: "#4CAF50", cursor: "pointer" }}
      ></i>
      {permissions?.editPerm === "1" && (
        <i className="fas fa-edit" style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }} onClick={() => navigate(`/addcoatingapplicationline?id=${params.data.prodAppId}`)}></i>
      )}
      {permissions?.firstLevelApprove === "1" && (
        <i className="fas fa-check" onClick={() => handleApproval(params, "first")} style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }}></i>
      )}
      {permissions?.secondLevelApprove === "1" && (
        <i className="fas fa-check-double" style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }} onClick={() => handleApproval(params, "second")}></i>
      )}
    </div>
  );

  const columnDefs = [
    { headerName: 'S No.', valueGetter: (params) => params.node.rowIndex + 1, width: 67, headerClass: 'custom-header' },
    { headerName: "Process Sheet No.", field: "procsheetno", width: 205, headerClass: 'custom-header' },
    { headerName: "Project Name", field: "projectname", width: 280, headerClass: 'custom-header' },
    { headerName: "Client Name", field: "clientName", width: 320, headerClass: 'custom-header' },
    {
      headerName: "Date",
      field: "importDate",
      width: 110,
      headerClass: 'custom-header',
      valueFormatter: (params) => {
        if (params.value) {
          return new Date(params.value).toLocaleDateString('en-GB').replace(/\//g, "-");
        }
        return '';
      }
    },
    { headerName: "Action", cellRenderer: actionCellRenderer, width: 160, headerClass: 'custom-header' }
  ];

  const handleViewClick = (params) => {
    navigate(`/viewcoatingapplicationline?id=${params.data.prodAppId}`, '_blank');
  };

  const filterData = () => {
    return rowData.filter(item => {
      const testDate = new Date(item.importDate);

      const startOfFromDate = fromDate ? new Date(fromDate.setHours(0, 0, 0, 0)) : null;
      const startOfToDate = toDate ? new Date(toDate.setHours(0, 0, 0, 0)) : null;
      const startOfTestDate = new Date(testDate.setHours(0, 0, 0, 0));

      return (
        (!startOfFromDate || startOfTestDate >= startOfFromDate) &&
        (!startOfToDate || startOfTestDate <= startOfToDate)
      );
    });
  };


  const resetFilter = () => {
    setFromDate(null);
    setToDate(null);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      <section className="InnerHeaderPageSection">
        <div className="InnerHeaderPageBg" style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <ul>
                <li><Link to="/productiondashboard?moduleId=619">Production Module</Link></li>
                <li><h1>/ Coating Application Line List</h1></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="ProcessSheetdataentrySectionPage">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="ProcessSheetlistTable">
                <div className="tableheaderflex">
                  <div className="tableheaderfilter">
                    <span> <i className="fas fa-filter"></i>Filter Test Date</span>
                    <label>From Date:
                      <DatePicker
                        maxDate={new Date()}
                        selected={fromDate}
                        onChange={setFromDate}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="DD-MM-YYYY"
                      />
                    </label>
                    <label>To Date:
                      <DatePicker
                        maxDate={new Date()}
                        selected={toDate}
                        onChange={setToDate}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="DD-MM-YYYY"
                      />
                    </label>
                    <i className="fa fa-refresh" onClick={resetFilter}></i>
                  </div>
                  <div className="tableheaderAddbutton">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', }} > </div>
                    <Link style={{ float: 'right' }} to="/addcoatingapplicationline">
                      <i className="fas fa-plus"></i> Add
                    </Link>
                  </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "540px", width: "100%" }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterData()}
                    pagination={true}
                    paginationPageSize={10}
                    suppressDragLeaveHidesColumns="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Listcoatingapplicationline;