// src/config/apiConfig.js

// const API_URL = "http://192.168.2.8:8891/api/User/";
// export default API_URL;

export default class Environment {
    // static BaseAPIURL = "http://192.168.2.8:8192";
    static BaseAPIURL = "https://tsl-api.maxworth.in";
    // static BaseAPIURL = "http://localhost:20609";
    // static ImageURL = " http://192.168.2.8:8192";
    static ImageURL = "https://tsl-ui.maxworth.in/sign_images";


    // // static BaseAPIURL = "http://192.168.2.8:8192";
    // // static BaseAPIURL = "https://tsl-api.maxworth.in";
    // static BaseAPIURL = "http://192.168.2.111:8192";


    // // static BaseAPIURL = "http://localhost:20609";
    // // static ImageURL = "http://192.168.2.8:8195";
    // // static ImageURL = "https://tsl-ui.maxworth.in/sign_images";
    // static ImageURL = "http://192.168.2.111:8195";
}