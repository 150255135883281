import './ApplicationTable.css'
//import JsonAppData from './datas.json'
import Header from '../Common/Header/Header'
import Footer from '../Common/Footer/Footer'
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg'
import { Link } from 'react-router-dom'
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


function JsonAppDataDisplay() {


  // ---------------------------------------------

  const [year, setYear] = useState('');
  const [inputValue, setInputValue] = useState('');


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const isInputFilled = inputValue.trim() !== '';

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };


  const validatePipeTempColor = (value) => {
    const intValue = Number(value);
    if (intValue == 0) {
      return 'white-cell';
    } else if (intValue >= 40 && intValue <= 80) {
      return 'green-cell';
    }
    else {
      return 'red-cell';
    }
  };

  // const handleTypeChange = (event) => {
  //     setType(event.target.value);
  // };

  const shouldDisableFields = year && inputValue;
  const ProcessSheetFields = year && inputValue;

  // ---------------------------------------------

  // State to track whether the button is clicked
  const [isClicked, setIsClicked] = useState(false);

  // Function to handle the click event
  const handleClick = () => {
    setIsClicked(true);
  };

  const [inputValues, setInputValues] = useState({
    No: '',
    // Add more input fields as needed
  });

  const allFieldsFilled = Object.values(inputValues).every(value => value !== '');

  // ----------------------------------------------------

  // Get the current date
  const currentDate = new Date();

  // Format the date in dd/mm/yyyy format
  const formattedDate = format(currentDate, 'dd/MM/yyyy');


  const [rowData, setRowData] = useState('');

  useEffect(() => {
    // Fetch data from the JSON file
    fetch('/datas.json')
      .then(response => response.json())
      .then(data => setRowData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const onCellValueChanged = (params) => {
    // Handle cell value changes if needed
    console.log('Cell value changed:', params);
  };

  const getEditableTextBox = (params) => {
    return {
      template: '<input type="text" class="ag-cell-edit-input" style="width: 100%" />',
      init: function (params) {
        params.eInput.value = params.value;
        params.eInput.addEventListener('blur', function () {
          params.stopEditing();
        });
      },
      getGui: function () {
        return params.eInput;
      },
      afterGuiAttached: function () {
        params.eInput.focus();
        params.eInput.select();
      },
      getValue: function () {
        return params.eInput.value;
      },
      destroy: function () { },
    };
  };


  //-----------------------Drop down list 

  const [dropdownValues, setDropdownValues] = useState('');
  // const [rowData, setRowData] = useState([]);
  const YourGridComponent = () => {


    useEffect(() => {
      // Fetch your data from the local JSON file or any other source
      const fetchData = async () => {
        // Replace this with your actual data fetching logic
        const response = await fetch('/datas.json');
        const data = await response.json();

        // Extract unique values for the dropdown
        const uniqueValues = Array.from(
          new Set(data.map((item) => item.visualOfChromateApplication))
        );

        setDropdownValues(uniqueValues);
        setRowData(data);
      };

      fetchData();
    }, []);

  }
  //-----------------------Drop Down list
  // const gridOptions = {
  //   // other grid options...
  //   singleClickEdit: true,

  // }


  function checkData() {
    alert('Please check entered the data !');
  }

  // const handleSubmit = () => {
  //   // Add logic to handle the submission of the form data
  //   console.log('Form data submitted:', rowData);}


  const columnDefs = [
    { headerName: 'Actual Sr. No', field: 'actualSrNo' },
    { headerName: 'Sr.No.', field: 'srNo' },
    { headerName: 'Pipe No', field: 'pipeNo' },
    { headerName: 'ASL NO', field: 'aslNo', },
    {
      headerName: 'Pipe temp. before Chromate(°C)',
      field: 'pipeTempBeforeChromate',
      editable: (params) => {
        // Disable editing when actualSrNo is 0 or empty
        return params.data.actualSrNo !== 0 && params.data.actualSrNo !== '';
      },
      cellEditorFramework: getEditableTextBox,
      cellClassRules: {
        'cell-green': (params) => {
          const minValue = 40;
          const maxValue = 80;
          const value = parseFloat(params.value);
          return value >= minValue && value <= maxValue && params.data.actualSrNo !== '';
        },
        'cell-red': (params) => {
          const minValue = 40;
          const maxValue = 80;
          const value = parseFloat(params.value);
          return (value < minValue || value > maxValue) && params.data.actualSrNo !== '';
        },
        'cell-disabled': (params) => params.data.actualSrNo === 0 || params.data.actualSrNo === '',
      }
    },
    {
      headerName: 'Visual of Chromate Application', field: 'visualOfChromateApplication', editable: (params) => {
        // Disable editing when actualSrNo is 0 or empty
        return params.data.actualSrNo !== 0 && params.data.actualSrNo !== '';
      }, cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Ok', 'Not Ok'], dropdownValues, }
    },
    {
      headerName: 'Chromate Solution Temperature(°C)',
      field: 'chromateSolutionTemp', editable: (params) => {
        // Disable editing when actualSrNo is 0 or empty
        return params.data.actualSrNo !== 0 && params.data.actualSrNo !== '';
      },
      cellEditorFramework: getEditableTextBox,
      cellClassRules: {
        'cell-green': (params) => {
          const minValue = 0;
          const maxValue = 60;
          const value = parseFloat(params.value);
          return value >= minValue && value <= maxValue && params.data.actualSrNo !== '';
        },
        'cell-red': (params) => {
          const minValue = 0;
          const maxValue = 60;
          const value = parseFloat(params.value);
          return (value < minValue || value > maxValue) && params.data.actualSrNo !== '';
        },
        'cell-disabled': (params) => params.data.actualSrNo === 0 || params.data.actualSrNo === '',
      }
    },

    { headerName: 'Pipe Temp. After chromate Application(°C)', field: 'pipeTempAfterChromate' },
    {
      headerName: 'Pipe Temp. before FBE Application(°C)',
      field: 'pipeTempBeforeFBE',
      editable: (params) => {
        // Disable editing when actualSrNo is 0 or empty
        return params.data.actualSrNo !== 0 && params.data.actualSrNo !== '';
      },
      cellEditorFramework: getEditableTextBox,
      cellClassRules: {
        'cell-green': (params) => {
          const minValue = 190;
          const maxValue = 999;
          const value = parseFloat(params.value);
          return value >= minValue && value <= maxValue && params.data.actualSrNo !== '';
        },
        'cell-red': (params) => {
          const minValue = 190;
          const maxValue = 999;
          const value = parseFloat(params.value);
          return (value < minValue || value > maxValue) && params.data.actualSrNo !== '';
        },
        'cell-disabled': (params) => params.data.actualSrNo === 0 || params.data.actualSrNo === '',
      }
    },
    {
      headerName: 'Adhesive Film Temp.(°C)',
      field: 'adhesiveFilmTemp',
      editable: (params) => {
        // Disable editing when actualSrNo is 0 or empty
        return params.data.actualSrNo !== 0 && params.data.actualSrNo !== '';
      },
      cellEditorFramework: getEditableTextBox,
      cellClassRules: {
        'cell-green': (params) => {
          const minValue = 200;
          const maxValue = 230;
          const value = parseFloat(params.value);
          return value >= minValue && value <= maxValue && params.data.actualSrNo !== '';
        },
        'cell-red': (params) => {
          const minValue = 200;
          const maxValue = 230;
          const value = parseFloat(params.value);
          return (value < minValue || value > maxValue) && params.data.actualSrNo !== '';
        },
        'cell-disabled': (params) => params.data.actualSrNo === 0 || params.data.actualSrNo === '',
      }
    },

    {
      headerName: 'PE/PP Film Temp.(°C)',
      field: 'peppFilmTemp',
      editable: (params) => {
        // Disable editing when actualSrNo is 0 or empty
        return params.data.actualSrNo !== 0 && params.data.actualSrNo !== '';
      },
      cellEditorFramework: getEditableTextBox,
      cellClassRules: {
        'cell-green': (params) => {
          const minValue = 220;
          const maxValue = 260;
          const value = parseFloat(params.value);
          return value >= minValue && value <= maxValue && params.data.actualSrNo !== '';
        },
        'cell-red': (params) => {
          const minValue = 220;
          const maxValue = 260;
          const value = parseFloat(params.value);
          return (value < minValue || value > maxValue) && params.data.actualSrNo !== '';
        },
        'cell-disabled': (params) => params.data.actualSrNo === 0 || params.data.actualSrNo === '',
      }
    },

    { headerName: 'Water Temperature Of quenching Before quenching', field: 'waterTempBeforeQuenching' },
    { headerName: 'Water Temperature Of Quenching After Quenching', field: 'waterTempAfterQuenching' },
    { headerName: 'Remark', field: 'remark' },
  ];



  return (
    <div>
      <Header />
      <section className='InnerHeaderPageSection'>
        <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <ul>
                <li> <Link to='/dashboard'>Quality Module</Link></li>
                <li><h1>/&nbsp; Application Table </h1></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className='ApplicationTableSectionPage'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className='ApplicationTableDetails'>
                <form action="" className='row m-0'>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor="">Process Sheet</label>
                      <div className='ProcessSheetFlexBox'>
                        <input style={{ width: '66%', cursor: 'not-allowed' }} value={ProcessSheetFields ? 'TSL/PSC/EXT/3LPE/GAIL/MNJPL' : ''} disabled={ProcessSheetFields} placeholder='Process sheet no.' readOnly />
                        <select value={year} onChange={handleYearChange} >
                          <option value=""> Year </option>
                          <option value="2022"> 2022 </option>
                          <option value="2023"> 2023 </option>
                        </select>
                        <b>-</b>
                        <input type="number" placeholder='No.' value={inputValue} onChange={handleInputChange} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor="">Client Name</label>
                      <input type="text" value={shouldDisableFields ? 'TRIPURA NATURAL GAS COMPANY LIMITED' : ''} title={shouldDisableFields ? 'TRIPURA NATURAL GAS COMPANY LIMITED' : ''} disabled={shouldDisableFields} placeholder='Client name' style={{ cursor: 'not-allowed' }} readOnly />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor="">Project Name</label>
                      <input type="text" value={shouldDisableFields ? 'Nagpur - Chindwara - Jabalpur Pipeline (NCJPL) Part C Of MNJPL Project' : ''} title={shouldDisableFields ? 'Nagpur - Chindwara - Jabalpur Pipeline (NCJPL) Part C Of MNJPL Project' : ''} disabled={shouldDisableFields} placeholder='Project name' style={{ cursor: 'not-allowed' }} readOnly />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor="">Pipe Size</label>
                      <input type="text" value={shouldDisableFields ? '457 mm OD X 8.2 mm WT' : ''} title={shouldDisableFields ? '457 mm OD X 8.2 mm WT' : ''} disabled={shouldDisableFields} placeholder='Pipe size' style={{ cursor: 'not-allowed' }} readOnly />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor="">Specification</label>
                      <input type="text" value={shouldDisableFields ? 'Client spec - EIL STANDARD SPECIFICATION No. 6-71-0041' : ''} title={shouldDisableFields ? 'Client spec - EIL STANDARD SPECIFICATION No. 6-71-0041' : ''} disabled={shouldDisableFields} placeholder='Spec. & grade' style={{ cursor: 'not-allowed' }} readOnly />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor="">S.O/P.O NO.</label>
                      <input type="text" value={shouldDisableFields ? 'GAIL/NOIDA/C&P/PROJ/MNJPL-PARTB/PIPE/21-45/13' : ''} title={shouldDisableFields ? 'GAIL/NOIDA/C&P/PROJ/MNJPL-PARTB/PIPE/21-45/13' : ''} disabled={shouldDisableFields} placeholder='PO.NO/LOA.NO' style={{ cursor: 'not-allowed' }} readOnly />
                    </div>
                  </div>

                  {shouldDisableFields && (
                    <>
                      <div className='col-md-4 col-sm-4 col-xs-12'>
                        <div className='form-group'>
                          <label htmlFor="">Test Date</label>
                          <input className='formattedDateTestDate' type="text" value={formattedDate} style={{ cursor: 'not-allowed', backgroundColor: 'whitesmoke' }} readOnly />
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-4 col-xs-12'>
                        <div className='form-group'>
                          <label htmlFor="">Shift</label>
                          <input type="text" value={shouldDisableFields ? 'Day' : ''} disabled={shouldDisableFields} placeholder='DD/MMM/YYYY' style={{ cursor: 'not-allowed' }} readOnly />
                        </div>
                      </div>
                    </>
                  )}

                  <div className='col-md-12 col-sm-12 col-xs-12'>
                    <div className='ShowtableBox'>
                      {/* <button type='button' onClick={handleClick} disabled={!shouldDisableFields}>Show table</button> */}
                      {/* {allFieldsFilled && (<button onClick={(handleClick)}>Show table</button>)} */}
                      {isInputFilled && <button type='button' onClick={handleClick}>Enter Data</button>}
                    </div>
                  </div>
                </form>
                {isClicked &&
                  <div className='row m-0'>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <div className="ag-theme-alpine" id='custom-scroll' style={{ marginTop: '10px', height: '600px', width: '100%' }}>
                        <AgGridReact
                          columnDefs={columnDefs}
                          rowData={rowData}
                          onCellValueChanged={onCellValueChanged}
                          suppressDragLeaveHidesColumns="true"
                        //  gridOptions={gridOptions}
                        // pagination={true}
                        // paginationPageSize={100}
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default JsonAppDataDisplay;
