import React, { useRef, useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Environment from "../../../environment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../Allreports.css';
import HeaderDataSection from "../Headerdata";
import ReportRemarks from '../Report-remarks';
import InstrumentusedSection from '../Instrument-used';
import Footerdata from '../Footerdata';
import Loading from "../../Loading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function formatDate(dateString) {
  return dateString ? new Date(dateString).toLocaleDateString('en-GB') : "-";
}

function formatTime(timeString) {
  if (!timeString) return "-";
  const time = new Date(timeString);
  return time.toLocaleTimeString('en-GB', { hour12: true });
}

function InHouseReport() {
  const { tstmaterialid } = useParams();
  const contentRef = useRef();
  const [headerDetails, setHeaderDetails] = useState({});
  const [testDetails, setTestDetails] = useState([]);
  const [instrumentDetails, setInstrumentDetails] = useState([]);
  const [reportTestDate, setReportTestDate] = useState()
  const [newDate, setNewDate] = useState()
  const [loading, setLoading] = useState(false);
  const [rawMaterialDetails, setRawMaterialDetails] = useState([]);
  const [signatureReport, setSignatureReport] = useState([])
  const [showRemarks, setShowRemarks] = useState([])
  const [witnessValue, setWitnessValue] = useState('');
  const [witnessSelected, setWitnessSelected] = useState(false);
  const [showWitness, setShowWitness] = useState(true)
  const [witnessData, setWitnessData] = useState([])
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false)
  const navigate = useNavigate()
  const pathSegments = location.pathname.split(/[\/&]/);

  let pm_project_id1 = null;
  let pm_processSheet_id1 = null;
  let pm_processtype_id1 = null;
  let pm_approved_by1 = null;
  let test_date1 = null;
  let pm_Approve_level1 = null;
  let menuId1 = null;
  for (let i = 0; i < pathSegments.length; i++) {
    if (pathSegments[i].startsWith('pm_project_id=')) {
      pm_project_id1 = pathSegments[i].substring('pm_project_id='.length);
    }
    if (pathSegments[i].startsWith('pm_processSheet_id=')) {
      pm_processSheet_id1 = pathSegments[i].substring('pm_processSheet_id='.length);
    }
    if (pathSegments[i].startsWith('pm_processtype_id=')) {
      pm_processtype_id1 = pathSegments[i].substring('pm_processtype_id='.length);
    }
    if (pathSegments[i].startsWith('pm_approved_by=')) {
      pm_approved_by1 = pathSegments[i].substring('pm_approved_by='.length);
    }
    if (pathSegments[i].startsWith('test_date=')) {
      test_date1 = pathSegments[i].substring('test_date='.length);
    }
    if (pathSegments[i].startsWith('pm_Approve_level=')) {
      pm_Approve_level1 = pathSegments[i].substring('pm_Approve_level='.length);
    }
    if (pathSegments[i].startsWith('menuId=')) {
      menuId1 = pathSegments[i].substring('menuId='.length);
    }
  }

  const [id1, id2] = tstmaterialid.split('&');
  const [formData, setFormData] = useState({
    pm_comp_id: 1,
    pm_location_id: 1,
    pm_project_id: parseInt(pm_project_id1),
    pm_processSheet_id: parseInt(pm_processSheet_id1),
    pm_processtype_id: parseInt(pm_processtype_id1),
    pm_remarks: "",
    pm_approver_status: true,
    pm_approved_by: pm_approved_by1,
    pm_approved_on: test_date1,
    pm_Approve_level: pm_Approve_level1 == "first" ? 1 : pm_Approve_level1 == "second" ? 2 : 0,
    pm_approvedRoleId_by: '0',
    p_test_run_id: parseInt(id2)
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        if (tstmaterialid) {
          console.log(164, '--------')
          const [id1, id2, id3, id4] = tstmaterialid.split('&');
          const response = await axios.get(`${Environment.BaseAPIURL}/api/User/RMTestingReport?procsheet_id=${id1}&TestRunId=${id2}&typeid=${id3}&test_id=${id4}`);
          const data = response.data[0];
          setHeaderDetails(data[0] || {});
          const date = data[0]?.ReportTestDate || {}
          // const [month, day, year] = date.split('T');
          const formattedDate = date?.split('T')[0]
          const newDateStr = formattedDate.replace(/-/g, '');
          setReportTestDate(newDateStr);
          const date1 = new Date(data[0]?.ReportTestDate);

          const day = String(date1.getDate()).padStart(2, '0');
          const month = String(date1.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
          const year = date1.getFullYear();

          const formattedDate1 = `${day}.${month}.${year}`;
          console.log(formattedDate1)
          setNewDate(formattedDate1)

          setTestDetails(response.data[1] || []);
          setInstrumentDetails(response.data[2] || []);
          // setRawMaterialDetails(data._CdTestRawMaterial || []);

          try {
            if (tstmaterialid) {
              const [id1, id2, id3, id4] = tstmaterialid.split('&');
              const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetInspectedByAcceptedByDetails?matid=${id3}&testId=${id2}`);
              const data = response.data
              console.log(data)
              setSignatureReport(data)
              callWitness()
            }
            setLoading(false)
          } catch (error) {
            console.error('Error fetching report data:', error);
            setLoading(false)
          }
        }
      } catch (error) {
        setLoading(false)
        console.error('Error fetching report data:', error);
      }
    };
    fetchData();
  }, [tstmaterialid]);

  async function callWitness() {
    const [id1, id2] = tstmaterialid.split('&');
    const response1 = await axios.post(`${Environment.BaseAPIURL}/api/User/GetEmployeeTypeWithName?p_procsheet_id=${id1}&p_test_run_id=${id2}`);
    setWitnessData(response1?.data)
    const pm_status_app_rej = response1?.data[0]?.pm_status_app_rej
    const hasRejectCountGreaterThanZero = response1?.data.some(item => item.RejectCount > 0);
    const allHaveZeroCounts = response1?.data.every(item => item.ApproveCount === 0 && item.RejectCount === 0);
    if (pm_status_app_rej == null || pm_status_app_rej == 0 || pm_status_app_rej == 2 || pm_Approve_level1 == 'second') {
      setShowRemarks(true)
    } else {
      setShowRemarks(false)
    }
    // if (response1?.data.length == 1) {
    setWitnessValue(response1?.data[0]?.roleId)
    setFormData({ ...formData, pm_approvedRoleId_by: witnessValue != '' ? witnessValue : response1?.data[0]?.roleId.toString() })
    setWitnessSelected(true);
    // }
  }

  function handleSelect(e) {
    setWitnessValue(e.target.value)
    setFormData({ ...formData, pm_approvedRoleId_by: e.target.value })
    setWitnessSelected(true);
    if (!showRemarks) {
      handleStatusChange("A")
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStatusChange = (value) => {
    setIsClicked(true)
    if (value === "A") {
      setFormData({ ...formData, pm_approver_status: true, pm_approvedRoleId_by: witnessValue.toString() });
      setWitnessSelected(true);
      setShowWitness(true);
    }
    if (value === "R") {
      setFormData({ ...formData, pm_approver_status: false, pm_approvedRoleId_by: "0" });
      setShowWitness(false);
      setWitnessSelected(false)
    }
  };

  const renderApprovalSection = () => {
    return (
      showRemarks ? <div className='RemarksFlexBox' style={{ marginTop: '10px' }}>
        <label htmlFor="">Remarks</label>
        <input name="pm_remarks" value={formData.pm_remarks} onChange={handleChange} type="text" placeholder="Enter Approval/Rejection Remarks...." autoComplete="off" />
        <div className='ApproveRejectUIFlex'>
          <label className="custom-radio">
            <input type="radio" className="Approveinput" name="pm_approver_status" id="btnaprv" onChange={() => handleStatusChange("A")} />
            <span className="radio-btn"><i className="fas fa-check"></i>Approve</span>
          </label>
          <label className="custom-radio">
            <input type="radio" className="Rejectinput" name="pm_approver_status" id="btnreject" onChange={() => handleStatusChange("R")} />
            <span className="radio-btn"><i className="fas fa-times"></i>Reject</span>
          </label>
        </div>
      </div> : ''
    );
  };

  const renderFirstApprovalStatus = () => {
    if (pm_Approve_level1 == "first") {
      return (
        <div className="bare-pipe-inspection">
          {renderApprovalSection()}
          {showWitness && (<div className="SelectWitnessFlexBox">
            <label htmlFor="" >Select Witness <b>*</b></label>
            <select name="" value={witnessValue} onChange={handleSelect}>
              <option disabled selected>Select Witness</option>
              {witnessData && witnessData?.map((data) => {
                return (
                  <option value={data?.roleId}>{data?.Name}</option>
                )
              })}
            </select>
          </div>)}
          <div className='SubmitBtnFlexBox'>
            {<button type="button" className="SubmitBtn" onClick={handleSubmit}>Submit</button>}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSecondApprovalStatus = () => {
    if (pm_Approve_level1 == "second") {
      return (
        <div className='BarePipeInspForm row m-0'>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className='renderApprovalFlexBox'>
              {renderApprovalSection()}
              {<button type="button" onClick={handleSubmit}>Submit</button>}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, formData.pm_approver_status, 264)
    if (showRemarks) {
      if (formData?.pm_remarks == '' || isClicked == false) {
        toast.error("Please enter remarks and status")
        return
      }
    }

    if (showWitness && !witnessSelected && pm_Approve_level1 != "second") {
      toast.error('Please select a witness before submitting the form.');
      return;
    }
    try {
      console.log(formData.pm_approver_status)
      const response = await fetch(Environment.BaseAPIURL + "/api/User/InspectionSheetApproval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const responseBody = await response.text();

      if (responseBody === '100' || responseBody === '200') {
        toast.success('Status Updated Successfully!');
        navigate(`/rawmateriallist?menuId=${menuId1}&testingtype=${pm_processtype_id1}`)
        console.log("Form data sent successfully!");
      } else {
        console.error(
          "Failed to send form data to the server. Status code:",
          response.status
        );
        console.error("Server response:", responseBody);
      }
    } catch (error) {
      console.error("An error occurred while sending form data:", error);
    }
  };

  const handleDownload = () => {
    const content = contentRef.current;
    const options = {
      scale: 2,
      useCORS: true,
      scrollX: 0,
      scrollY: 0,
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: document.documentElement.scrollHeight,
    };

    // table borders for PDF generation
    const tableElements = content.querySelectorAll('table');
    tableElements.forEach(table => {
      table.style.border = '1px solid #999999';

      // borders from table cells
      const cells = table.querySelectorAll('td, th');
      cells.forEach(cell => {
        cell.style.border = '1px solid #999999';
      });
    });

    html2canvas(content, options)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('landscape', 'mm', 'a4');
        pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
        pdf.save('insAdhesive_report.pdf');
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
        alert('An error occurred while generating the PDF. Please try again later.');
      });
  };

  const handlePrint = () => {
    window.print();
  };


  function condenseData(input) {
    // Split the input string into an array
    let dataArray = input?.split(',');

    // Extract the common prefix
    let commonPrefix = dataArray[0]?.slice(0, -2);

    // Extract the unique numbers
    let uniqueNumbers = dataArray?.map(item => item.split('-').pop());

    // Join the unique numbers into a single string
    let result = commonPrefix + '-' + uniqueNumbers.join(', ');

    return result;
  }

  return (
    <>
      {
        loading ?
          <Loading />
          :
          <div>
            <div style={{ textAlign: 'right', paddingRight: '14px', paddingTop: '10px' }}>
              <h4 className='DownloadPDFBtn' onClick={handleDownload}>
                <i className="fas fa-download"> </i> Download PDF
              </h4>
              <h4 className='PrintBtn' onClick={handlePrint}>
                <i className="fas fa-print"></i> Print
              </h4>
            </div>
            <div className='InspReportSection' ref={contentRef}>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12 col-sm-12 col-xs-12'>
                    <div className='InspReportBox'>

                      <HeaderDataSection reportData={headerDetails} />

                      <section className='Reportmasterdatasection'>
                        <div className='container-fluid'>
                          <form className='row'>
                            <div className='col-md-7 col-sm-6 col-xs-12'>
                              <div className='form-group'>
                                <label htmlFor="">Material Description</label>
                                <h4>: &nbsp;&nbsp; {headerDetails.materialName || "-"}</h4>
                              </div>
                            </div>
                            <div className='col-md-5 col-sm-6 col-xs-12'>
                              <div className='form-group'>
                                <label htmlFor="">Report No.</label>
                                <h4>:  &nbsp;&nbsp;{headerDetails?.ReportAlias}/{reportTestDate}
                                  {headerDetails?.ReportPqt == '' ? '' : (
                                    <> ({headerDetails.ReportPqt})</>
                                  )}</h4>
                              </div>
                            </div>
                            <div className='col-md-7 col-sm-6 col-xs-12'>
                              <div className='form-group'>
                                <label htmlFor="">Supplier</label>
                                <h4>: &nbsp;&nbsp;{headerDetails.manufacturerName || "-"}</h4>
                              </div>
                            </div>
                            <div className='col-md-5 col-sm-6 col-xs-12'>
                              <div className='form-group'>
                                <label htmlFor="">Date</label>
                                <h4>: &nbsp;&nbsp;{newDate || "-"}</h4>
                              </div>
                            </div>
                            <div className='col-md-7 col-sm-6 col-xs-12'>
                              <div className='form-group'>
                                <label htmlFor="">Coating Type</label>
                                <h4>: &nbsp;&nbsp;{headerDetails.Coattype || "-"}</h4>
                              </div>
                            </div>
                            <div className='col-md-5 col-sm-6 col-xs-12'>
                              <div className='form-group'>
                                <label htmlFor="">Client</label>
                                <h4>: &nbsp;&nbsp;{headerDetails.Clientname || "-"}</h4>
                              </div>
                            </div>
                            <div className='col-md-7 col-sm-6 col-xs-12'>
                              <div className='form-group'>
                                <label htmlFor="">Procedure / WI No.</label>
                                <h4>: &nbsp;&nbsp;{headerDetails?.WINO && condenseData(headerDetails?.WINO) || "-"}</h4>
                              </div>
                            </div>

                            <div className='col-md-5 col-sm-6 col-xs-12'>
                              <div className='form-group'>
                                <label htmlFor="">EPMC</label>
                                <h4>: &nbsp;&nbsp;{headerDetails.EPMC || "-"}</h4>
                              </div>
                            </div>
                          </form>
                        </div>
                      </section>

                      {Array.isArray(testDetails) && testDetails.length > 0 && (
                        <section className='ReporttableSection'>
                          <div className='container-fluid'>
                            <div className='row'>
                              <div className='col-md-12 col-sm-12 col-xs-12'>
                                <div id='custom-scroll'>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Sr. No.</th>
                                        <th>Batch No.</th>
                                        <th>Test</th>
                                        <th>Test Method</th>
                                        <th>Requirement</th>
                                        <th>Test Result</th>
                                        <th>Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {testDetails.map((item, index) => (
                                        <tr key={index + 1}>
                                          <td>{index + 1}</td>
                                          <td>{headerDetails.batch || "-"}<br />{headerDetails.grade || "-"}</td>
                                          <td>{item.Test_Description || "-"}</td>
                                          <td>{item.Test_Method || "-"}</td>
                                          <td>{item.pm_value_type == "A" ? item.pm_test_value : (item.PM_Reqmnt_test_min && item.PM_Reqmnt_test_Max) ?
                                            `${item.PM_Reqmnt_test_min} - ${item.PM_Reqmnt_test_Max}` :
                                            (item.PM_Reqmnt_test_min ? `Min - ${item.PM_Reqmnt_test_min}` :
                                              (item.PM_Reqmnt_test_Max ? `Max - ${item.PM_Reqmnt_test_Max}` : ''))} {item.PM_Reqmnt_test_min && item.PM_Reqmnt_test_Max ? " " + item.Unit : ''}</td>
                                          <td>{item.pm_test_result_remarks || "-"}{item.pm_test_result_remarks ? " " + item.Unit : ''}</td>
                                          <td>{item.pm_test_result_accepted == 1 ? "Satisfactory " + item.pm_test_result_suffix : "Not Satisfactory " + item.pm_test_result_suffix}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}
                      <section>
                        <table style={{ borderLeft: '1px solid #999999', borderRight: '1px solid #999999', width: '100%' }}>
                          <tbody>
                            <tr>
                              <td style={{ fontWeight: '400' }}>ABOVE RESULTS ARE CONFORMING TO SPECIFICATION: Client spec: TRACTEBEL PTS Doc. No. P.019141 G 110077 005, Reply to Bidder's Prebid Queries - Document No. P.019141-G11031-001 Dated. 12.07.2022 QCT No.P.019141 G 11013 006 Dated.08.06.2021 & QAP NO:- {headerDetails?.pm_Procedure_type_id} REV.02 DATE: 03.02.2023</td>
                            </tr>
                          </tbody>
                        </table>
                      </section>

                      {/* <ReportRemarks reportData={testDetails} /> */}

                      <InstrumentusedSection reportData={instrumentDetails} />

                      {/* <section className="Reportrawmaterialsection">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div>
                              <table>
                                <thead>
                                  <tr>
                                    <th colSpan={4} > RAW MATERIAL USED </th>
                                  </tr>
                                  <tr>
                                    <th style={{ paddingLeft: "10px", fontFamily: "Myriad Pro Bold", }} > MATERIAL </th>
                                    <th style={{ paddingLeft: "10px", fontFamily: "Myriad Pro Bold", }} > MANUFACTURER </th>
                                    <th style={{ paddingLeft: "10px", fontFamily: "Myriad Pro Bold", }} > GRADE </th>
                                    <th style={{ paddingLeft: "10px", fontFamily: "Myriad Pro Bold", }} > BATCH NO. </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rawMaterialDetails.map((rawMaterial, index) => (
                                    <tr key={index}>
                                      <td>{rawMaterial.materialName || "-"}</td>
                                      <td>{rawMaterial.manufacturerName || "-"}</td>
                                      <td>{rawMaterial.grade || "-"}</td>
                                      <td>{rawMaterial.batch || "-"}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      </section> */}
                      {signatureReport && signatureReport.length > 0 ? <Footerdata data={signatureReport} /> : ''}
                      <div className="row text-center">
                        <div className='col-md-12 col-sm-12 col-xs-12'>
                          {renderFirstApprovalStatus()}
                        </div>
                        <div className='col-md-12 col-sm-12 col-xs-12'>
                          {renderSecondApprovalStatus()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
}

export default InHouseReport;