import React, {
  useState,
  useEffect,
  setApiResponse,
  useSearchParams,
  useRef,
} from "react";
import Loading from "../Loading";
import "./BlastingDataEntry.css";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import Environment from "../../environment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import queryString from "query-string";
import { de, tr } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import "ag-grid-autocomplete-editor/dist/main.css";
import Modal from 'react-modal';
import secureLocalStorage from "react-secure-storage";
import { isThisHour } from "date-fns";


var excludedPipes = []

const BlastingDataEntry = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const year_query = searchParams.get("year");
  const sequence_no = searchParams.get("sequence_no");
  const process_type = searchParams.get("process_type");
  const viewType = searchParams.get("ViewType");
  const value_type = searchParams.get("id");
  const pm_test_run_id = searchParams.get("pm_test_run_id");
  const procsheetId = searchParams.get("processsheetId");
  const action = searchParams.get("action");
  const menuId = searchParams.get("menuId");
  const [ShowTestDate, setShowTestDate] = useState("");
  const [batches, setBatches] = useState([]);
  const [ShowApprovalSection, setShowApprovalSection] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const empId = secureLocalStorage.getItem('empId');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wiProcedure, setWiProcedure] = useState([]);
  const [wiSelectedProcedure, setWiSelectedProcedure] = useState("");
  const [indexPipe, setIndexPipe] = useState(false);
  const [testRunId, setTestRunId] = useState();
  const userRole = secureLocalStorage.getItem('userRole');
  const roleId = secureLocalStorage.getItem('roleId');
  const showPopUp = useRef(false)
  const navigate = useNavigate()

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '40%'
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  Modal.setAppElement('#root');
  let subtitle;

  useEffect(() => {
    if (viewType === "onhandleApproveClick") {
      setShowTestDate("");
      setShowApprovalSection("Yes");
      setApprovalFormData({ ...approvalFormData, approvertype: "qc" });
    } else if (viewType === "onhandleSecondApproveClick") {
      setShowTestDate("Yes");
      setShowApprovalSection("Yes");
      setApprovalFormData({ ...approvalFormData, approvertype: "tpi" });
    } else if (viewType === "View") {
      setShowTestDate("");
      setShowApprovalSection("");
    }
  }, [viewType]);


  // Function to remove duplicates based on a combination of properties
  function removeDuplicates(arr) {
    // Track seen combinations of properties
    let seen = {};
    // Filtered array to store unique objects
    let filteredArray = [];

    arr.forEach(obj => {
      // Create a key using the properties that define uniqueness
      let key = obj.co_param_val_name + '|' + obj.PIPNO + '|' + obj.pm_test_id;

      // Check if this key has been seen before
      if (!seen[key]) {
        seen[key] = true;
        filteredArray.push(obj);
      }
    });

    return filteredArray;
  }

  const procedureChange = (selectedOption) => {

    const labels = selectedOption.map(option => option.label).join(', ');


    setWiSelectedProcedure(labels)
    // You can send the payload to an API or perform other actions here
  };

  function callBlastingApi(date, value_type) {
    setTypeOfProcess(value_type)
    axios.get(Environment.BaseAPIURL + `/api/User/GetTestBlastingdataEntryNewtmp?processsheetno=${procsheetId}&processtype=${value_type}&TestDate=${date}&shiftid=${formData.shift}`)
      .then((response) => {
        setMatMafGrade(response?.data[11])
      })
  }

  async function editData(data_procedure1) {
    try {
      setTypeOfProcess(value_type)

      const response = await axios.post(Environment.BaseAPIURL + `/api/User/GetBlastingDetailsByIdNew?ProcessSheetID=${procsheetId}&ProcessSheetTypeID=${value_type}&testid=${pm_test_run_id}`)

      setFormData((prevData) => ({
        ...prevData,
        year: response.data[0][0].pm_procsheet_year,
        type: response.data[0][0].pm_procsheet_seq,
        processsheet: response.data[0][0].pm_procsheet_code,
        clientname: response.data[0][0].clientname,
        projectname: response.data[0][0].project_name,
        project_id: response.data[0][0].project_id,
        procsheet_id: response.data[0][0].procsheet_id,
        pipesize: response.data[0][0].pipesize,
        typeofcoating: response.data[0][0].coating_type,
        procedure: wiSelectedProcedure || "",
        testdate: (new Date(response.data[0][0].test_date).toLocaleDateString("en-CA")),
        shift: response.data[0][0].pm_shift_id,
        coatingType: response.data[0][0].co_param_val_id,
        ispqt: response.data[0][0].pm_ispqt_id == 1 ? true : false,
      }))

      const procedureTypeIds = response.data[0][0].pm_Procedure_type_id.split(",");
      const validProcedureTypeIds = procedureTypeIds.map(id => parseInt(id, 10)).filter(id => !isNaN(id));

      const filteredData = data_procedure1.filter(item => validProcedureTypeIds.includes(item.value));
      console.log(filteredData, data_procedure1, procedureTypeIds)
      const data_procedure = filteredData.map(id => ({
        value: id.value,
        label: id.label
      }));
      excludedPipes = []
      setWiSelectedProcedure(data_procedure)
      if (response.data[2].length > 0) {
        // const newData = removeDuplicates(response.data[2]);

        const processedData = {};

        response.data[2].forEach((item) => {
          const { pm_asl_number, PIPNO, pipe_id, seqno, Coatstatus, co_param_val_name, pm_test_value1, pm_temperature1, pm_project_id, pm_procsheet_id, pm_test_run_id, pm_test_categ_id, pm_test_type_id, pm_test_id, pm_proc_template_id, pm_proc_test_id, pm_shift_id, pm_value_type, pm_test_value2, pm_procsheet_seq, pm_process_type_id, pm_test_result_remarks, pm_ntc_id, pm_isfield, pm_islab } = item;

          // Initialize if PIPNO is not already in processedData
          if (!processedData[PIPNO]) {
            processedData[PIPNO] = { PIPNO };
          }
          processedData[PIPNO].seqno = seqno;
          processedData[PIPNO].pipe_id = pipe_id;
          processedData[PIPNO].pm_asl_number = pm_asl_number;
          processedData[PIPNO].pm_test_result_remarks = pm_test_result_remarks;
          processedData[PIPNO].coat_status = Coatstatus;
          processedData[PIPNO].isfield = pm_isfield;
          processedData[PIPNO].islab = pm_islab;
          // Add current item's data under the co_param_val_name for this PIPNO
          processedData[PIPNO][co_param_val_name.replace(/[^\w\s]/g, '')] = {
            pm_test_value1,
            pm_temperature1,
            pm_project_id,
            pm_procsheet_id,
            pm_test_run_id,
            pm_test_categ_id,
            pm_test_type_id,
            pm_test_id,
            pm_proc_template_id,
            pm_proc_test_id,
            pm_shift_id,
            pm_value_type,
            pm_test_value2,
            pm_procsheet_seq,
            pm_process_type_id,
            pm_ntc_id
          };
        });

        // Convert processedData to desired output format
        const result = Object.values(processedData);

        setExistingDataForGrid(result);
        // setDataForGrid(result);
      }
      setTypeGrids(true);
      setNtcReasons(response?.data[6])
      setApiResponse(response.data[1]);
      setYear(response.data[0][0].pm_procsheet_year)
      setRemarksList(response.data[5])
      setMatMafGrade(response?.data[4])

      const response1 = await axios.get(`${Environment.BaseAPIURL}/api/User/GETInstrumentDetailsByReportId?ReportId=${value_type}`);
      const data = response1.data[0]
      setusedInstrument(data);
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  }

  // useEffect(() => {
  //   axios.get(Environment.BaseAPIURL + `/api/User/GetWiTestList?sub_test_id=0&test_id=${value_type}`)
  //     .then((response) => {
  //       console.log(response, "WI NO")
  //     })
  // }, [value_type])

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaulttestdate = date.toLocaleDateString("en-CA");
  const [year, setYear] = useState(year_query ? year_query : "");
  const [type, setType] = useState(sequence_no ? sequence_no : "");
  const [processType, setProcessType] = useState(
    process_type ? process_type : ""
  );
  const [formData, setFormData] = useState({
    processsheet: "",
    year: year,
    type: type,
    clientname: "",
    projectname: "",
    project_id: "",
    procsheet_id: "",
    pipesize: "",
    typeofcoating: "",
    procedure: "",
    testdate: defaulttestdate,
    shift: "",
    coatingType: processType,
    fieldtesting: "",
    labtesting: "",
    ispqt: false,
    rm_batch: "",
    material_id: "",
    manufacturer_id: "",
    grade_id: "",
    created_by: empId.toString(),
    isSubmit: "",
    isThickness: "",
  });

  const [approvalFormData, setApprovalFormData] = useState({
    approvertype: "",
    QCApproverejectflag: "",
    QCApproverejectremark: "",
    pmtestrunid: "123",
  });

  let [APIresponse, setApiResponse] = useState([]);
  let [DataforGrid, setDataForGrid] = useState([]);
  let [ExistingDataforGrid, setExistingDataForGrid] = useState([]);
  let [materialUsed, setMaterialUsed] = useState([]);
  let [count, setCount] = useState(0);

  const [TypeGrids, setTypeGrids] = useState("");
  const [TestDDL, setTestDDL] = useState("");

  const shouldDisableFields = year && type;
  const ProcessSheetFields = year && type;
  let ShowProcessTypeGrids = TypeGrids;
  const [ddlYear, setddlYear] = useState([]);
  const [coatingType, setCoatingType] = useState([]);
  const [ddlShift, setShift] = useState([]);
  const [ddlprocedure, setProcedure] = useState([]);
  const [ddlfieldTesting, setddlfieldTesting] = useState([]);
  const [ddlLabtesting, setddlLabtesting] = useState([]);
  const [Remarks, setRemarks] = useState([]);
  const ddlRemarks = [];
  const [usedInstrument, setusedInstrument] = useState([]);
  const [isDetailsListFetched, setIsDetailsListFetched] = useState(false);
  const [coatStatusOptions, setCoatStatusOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [frequencyDetails, setFrequencyDetails] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [remarksList, setRemarksList] = useState([])
  const [ntcReasons, setNtcReasons] = useState([])
  const [materials, setMaterials] = useState([])
  const [ntcValue, setNtcValue] = useState()
  const [submit, setSubmit] = useState()
  const [typeOfProcess, setTypeOfProcess] = useState()
  const [previousStationPipes, setPreviousStationPipes] = useState([])
  const [shiftId, setShiftId] = useState()
  // const [thicknessData, setThicknessData] = useState({
  //   coatstatus: '',
  //   islab: '',
  //   isfield: ''
  // })

  useEffect(() => {
    fetchData();
    const fetchSuggestions = async () => {
      const suggestionList = [
        { ClientID: 'Batch1', ClientName: 'Batch 1' },
        { ClientID: 'Batch2', ClientName: 'Batch 2' },
        { ClientID: 'Batch3', ClientName: 'Batch 3' },
        { ClientID: 'Batch4', ClientName: 'Batch 4' },
      ];
      setSuggestions(suggestionList);
    };

    fetchSuggestions();
  }, []);


  const handleClientChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };


  useEffect(() => {
    if (process_type && sequence_no && isDetailsListFetched) {
      getTestBlastingDataEntries(process_type);
    }
  }, [process_type, sequence_no, isDetailsListFetched]);

  const handleprocedureChange = (selectedOption) => {
    handleTypeChange({ target: { name: "procedure", value: selectedOption } });
  };

  const handlefieldtestingChange = (selectedOption) => {
    handleTypeChange({
      target: { name: "fieldtesting", value: selectedOption },
    });
  };
  const handlelabtestingChange = (selectedOption) => {
    handleTypeChange({ target: { name: "labtesting", value: selectedOption } });
  };

  const [typeblasting, settypeblasting] = useState([
    {
      "Raw Material Name": "Adhesive",
      "MFR": "Manufacture",
      "Grade": "Grade"
    }
  ]);

  const onDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this row?')) {
      const newRowData = rowData.filter(row => row.id !== id);
      rowData = newRowData;
    }
  };

  const [matManGrade, setMatMafGrade] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState({});

  const getTestBlastingEntry = () => {
    axios
      // .get(
      //   Environment.BaseAPIURL +
      //   `/api/User/GetTestBlastingdataEntry?processsheetno=${formData.type}&processtype=0`
      // )
      .get(
        Environment.BaseAPIURL +
        `/api/User/GetTestBlastingdataEntryNewtmp?processsheetno=${formData.type}&processtype=0&TestDate=${formData.testdate}`
      )
      .then((response) => {
        setNtcReasons(response?.data[9])
        if (response.data[3].length < 1) {
          setFormData({
            processsheet: "",
            year: year,
            type: type,
            clientname: "",
            projectname: "",
            project_id: "",
            procsheet_id: "",
            pipesize: "",
            typeofcoating: "",
            procedure: "",
            testdate: defaulttestdate,
            shift: "",
            coatingType: processType,
            fieldtesting: "",
            labtesting: "",
            ispqt: false,
            rm_batch: "",
            material: "",
            manufacturer: "",
            grade: "",
            created_by: empId.toString(),
            isSubmit: "",
            isThickness: "",
          });
        }
        if (response.data[7].length > 0) {
          setShiftId(response.data[7][0].pm_shift_id);
        }
        if (response.data[3].length > 0) {
          const sprocedure = response.data[3][0]["sprocedure"];
          let selectedProcedures = [];
          if (sprocedure) {
            const selectedProcedureIds = sprocedure.split(",").filter(Boolean);
            selectedProcedures = selectedProcedureIds
              .map((id) => {
                const procedure = ddlprocedure.find(
                  (proc) => proc.pm_workinst_detail_id == id
                );
                return procedure
                  ? {
                    value: procedure.pm_workinst_detail_id,
                    label: procedure.pm_workinst_doc_id,
                  }
                  : null;
              })
              .filter(Boolean);
          }

          setFormData({
            ...formData,
            processsheet: response.data[3][0]["pm_procsheet_code"],
            type: formData.type + "",
            clientname: response.data[3][0]["clientname"],
            projectname: response.data[3][0]["project_name"],
            project_id: response.data[3][0]["project_id"] + "",
            procsheet_id: response.data[3][0]["procsheet_id"] + "",
            pipesize: response.data[3][0]["pipesize"],
            typeofcoating: response.data[3][0]["coating_type"],
            procedure: selectedProcedures,
            shift: response.data[3][0]["pm_shift_id"],
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
      });
  };

  const findUniqueEquipments = (equipments) => {
    const seen = new Set();
    return equipments.filter(
      (equipment) =>
        !seen.has(equipment.equip_id) && seen.add(equipment.equip_id)
    );
  };

  const handleTypeChange = (event, index) => {
    const { name, value } = event.target;
    switch (name) {
      case "year":
        setYear(event.target.value);
        updateyear(event.target.value);
        break;
      case "type":
        setType(event.target.value);
        updateProcessType(event.target.value);
        break;
      case "procedure":
        setFormData({ ...formData, procedure: value });
        break;
      case "testdate":
        setFormData({ ...formData, testdate: value });
        break;
      case "shift":
        setFormData({ ...formData, shift: value });
        break;
      case "coatingType":
        setProcessType(value);
        setFormData({ ...formData, coatingType: value });
        if (value == 526) {
          // return getThicknessInstruments();
        }
        getTestBlastingDataEntries(value);
        break;
      case "ispqt":
        if (formData.ispqt === false) {
          setFormData({ ...formData, ispqt: true });
        } else {
          setFormData({ ...formData, ispqt: false });
        }
        break;
      default:
        setFormData({ ...formData, [name]: value });
    }
  };

  const updateyear = (value) => {
    setFormData({ ...formData, year: value });
  };

  const updateProcessType = (value) => {
    setFormData({ ...formData, type: value });
  };

  const fetchData = () => {
    axios
      .get(Environment.BaseAPIURL + "/api/User/GetProcessSheetDetailsList")
      .then((response) => {
        setIsDetailsListFetched(true);
        const filteredOptions = response.data[1].filter(option => option.co_param_val_id == value_type);
        setCoatingType(filteredOptions);
        setShift(response.data[2]);
        setProcedure(response.data[3]);
        setddlYear(response.data[4]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        getCoatStatusOptions();
      });
  };


  const fetchFrequency = async (value, data1) => {
    try {
      const response = await axios.post(Environment.BaseAPIURL + `/api/User/GetFrequencyCriteria?processsheetno=${formData.type}&processtype=${value}&isPqt=${formData.ispqt ? 0 : 1}`)

      setFrequency(response.data[0].frequency);
      setFrequencyDetails(response.data[0]._FrequencyCriteriaDetails);

      const response1 = await axios.get(`${Environment.BaseAPIURL}/api/User/GETInstrumentDetailsByReportId?ReportId=${value_type}`);
      const data = response1.data[0]
      setusedInstrument(data);

      const response2 = await axios
        .get(
          Environment.BaseAPIURL +
          `/api/User/GetPendingPipedetails?ProcessType=${value}&procsheetid=${data1}`
        )
      setPreviousStationPipes(response2?.data[0])
    }
    catch (error) {
      console.error("Error fetching data:", error);
    };
  };

  const editBlastingDataEntry = (value, value1, value2) => {

    // axios
    //   // .get(
    //   //   Environment.BaseAPIURL +
    //   //   `/api/User/GetTestBlastingdataEntry?processsheetno=${formData.type}&processtype=${value}`
    //   // )
    //   .get(
    //     Environment.BaseAPIURL +
    //     `/api/User/GetTestBlastingdataEntryNewtmp?processsheetno=${value}&processtype=${value1}&TestDate=${value2}`
    //   )
    //   .then((response) => {
    //     setCount(count => count + 1)
    //     setMaterials(response?.data[10])
    //     if (response.data[8].length > 0) {
    //       setRemarksList(response.data[8])
    //     }
    //     if (response.data[7].length > 0) {
    //       setShift(response.data[7]);
    //     }
    //     if (response.data.length > 0) {
    //       fetchFrequency();
    //     }
    //     const uniqueEquipments = findUniqueEquipments(response.data[6]);
    //     setusedInstrument(uniqueEquipments);
    //     if (formData.coatingType === "526") {
    //       setddlfieldTesting(response.data[4]);
    //       setddlLabtesting(response.data[5]);
    //       setTestDDL(true);
    //     } else {
    //       setTestDDL(false);
    //     }
    //     if (response.data[0].length) {
    //       setTypeGrids(true);
    //       setApiResponse(response.data[0]);
    //     } else {
    //       setTypeGrids(false);
    //     }
    //     if (response.data[1].length > 0) {
    //       setDataForGrid(response.data[1]);
    //     }
    //     if (response.data[2].length > 0) {
    //       setExistingDataForGrid(response.data[2]);
    //     }

    //     if (response.data[8].length > 0) {
    //       setRemarks(response.data[8]);
    //       response.data[8].forEach((item) => {
    //         ddlRemarks.push(item.cbrm_remarks_val);
    //       });
    //     }

    //     if (response.data[7].length > 0) {
    //       // setShift(response.data[7]);
    //     }

    //     if (response.data[3].length > 0) {
    //       const sprocedure = response.data[3][0]["sprocedure"];
    //       let selectedProcedures = [];
    //       if (sprocedure) {
    //         const selectedProcedureIds = sprocedure.split(",").filter(Boolean);
    //         selectedProcedures = selectedProcedureIds
    //           .map((id) => {
    //             const procedure = ddlprocedure.find(
    //               (proc) => proc.pm_workinst_detail_id == id
    //             );
    //             return procedure
    //               ? {
    //                 value: procedure.pm_workinst_detail_id,
    //                 label: procedure.pm_workinst_doc_id,
    //               }
    //               : null;
    //           })
    //           .filter(Boolean);
    //       }

    //       setFormData({
    //         ...formData,
    //         processsheet: response.data[3][0]["pm_procsheet_code"],
    //         type: formData.type + "",
    //         clientname: response.data[3][0]["clientname"],
    //         projectname: response.data[3][0]["project_name"],
    //         project_id: response.data[3][0]["project_id"] + "",
    //         procsheet_id: response.data[3][0]["procsheet_id"] + "",
    //         pipesize: response.data[3][0]["pipesize"],
    //         typeofcoating: response.data[3][0]["coating_type"],
    //         procedure: selectedProcedures,
    //         shift: response.data[3][0]["pm_shift_id"],
    //         coatingType: value,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching API data:", error);
    //   });
  };

  const getTestBlastingDataEntries = async (value) => {
    try {
      setTypeOfProcess(value)
      const response = await axios
        .get(
          Environment.BaseAPIURL +
          `/api/User/GetTestBlastingdataEntryNewtmp?processsheetno=${formData.type}&processtype=${value}&TestDate=${formData.testdate}&shiftid=${formData.shift}`
        )
      if (response.data[1].length > 0) {
        // const newData = removeDuplicates(response.data[2]);

        const processedData = {};

        response.data[1].forEach((item, index) => {
          if (index == 0) {
            setTestRunId(item.pm_test_run_id)
          }
          console.log(item.pm_test_run_id)
          if (item.issaved == 1) {
            const { pm_asl_number, PIPNO, pipe_id, seqno, Coatstatus, co_param_val_name, pm_test_value1, pm_temperature1, pm_project_id, pm_procsheet_id, pm_test_run_id, pm_test_categ_id, pm_test_type_id, pm_test_id, pm_proc_template_id, pm_proc_test_id, pm_shift_id, pm_value_type, pm_test_value2, pm_procsheet_seq, pm_process_type_id, pm_test_result_remarks, pm_ntc_id, pm_isfield, pm_islab } = item;

            // Initialize if PIPNO is not already in processedData

            if (!processedData[PIPNO]) {
              processedData[PIPNO] = { PIPNO };
            }
            processedData[PIPNO].seqno = seqno;
            processedData[PIPNO].pipe_id = pipe_id;
            processedData[PIPNO].pm_asl_number = pm_asl_number;
            processedData[PIPNO].pm_test_result_remarks = pm_test_result_remarks;
            processedData[PIPNO].coat_status = Coatstatus;
            processedData[PIPNO].isfield = pm_isfield;
            processedData[PIPNO].islab = pm_islab;
            console.log(processedData)
            // Add current item's data under the co_param_val_name for this PIPNO
            processedData[PIPNO][co_param_val_name.replace(/[^\w\s]/g, '')] = {
              pm_test_value1,
              pm_temperature1,
              pm_project_id,
              pm_procsheet_id,
              pm_test_run_id,
              pm_test_categ_id,
              pm_test_type_id,
              pm_test_id,
              pm_proc_template_id,
              pm_proc_test_id,
              pm_shift_id,
              pm_value_type,
              pm_test_value2,
              pm_procsheet_seq,
              pm_process_type_id,
              pm_ntc_id
            };
          }
        });

        // Convert processedData to desired output format
        const result = Object.values(processedData);

        console.log(result, processedData)
        setExistingDataForGrid(result);
        // setDataForGrid(result);
      }
      setCount(count => count + 1)
      setNtcReasons(response?.data[9])
      setMatMafGrade(response?.data[11])

      // setMaterials(mergedData)
      if (response.data[8].length > 0) {
        setRemarksList(response.data[8])
      }
      if (response.data[7].length > 0) {
        // setShift(response.data[7]);
      }
      if (response.data.length > 0) {
        fetchFrequency(value, response.data[3][0]["procsheet_id"]);
      }
      if (formData.coatingType === "526") {
        setddlfieldTesting(response.data[4]);
        setddlLabtesting(response.data[5]);
        setTestDDL(true);
      } else {
        setTestDDL(false);
      }
      if (action != 'edit') {
        if (response.data[0].length) {
          setTypeGrids(true);
          console.log(response.data[0])
          setApiResponse(response.data[0]);
        } else {
          setTypeGrids(false);
        }
        let matchingData = [];
        if (response.data[1].length > 0) {
          for (var i = 0; i < response.data[1].length; i++) {
            if (response.data[1][i].issaved == 0) {
              matchingData.push(response.data[1][i]);
            }
          }
          setDataForGrid(matchingData);
        }
        if (response.data[0].length < 1) {
          if (value != '') {
            toast.error("There are no tests added for this process sheet")
          }
        }
        if (response.data[1].length < 1) {
          if (value != '') {
            toast.error("There are no pipes added for this process sheet")
          }
        }
      }
      // if (response.data[2].length > 0) {
      //   setExistingDataForGrid(response.data[2]);
      // }

      if (response.data[8].length > 0) {
        setRemarks(response.data[8]);
        response.data[8].forEach((item) => {
          ddlRemarks.push(item.cbrm_remarks_val);
        });
      }

      if (response.data[7].length > 0) {
        // setShift(response.data[7]);
      }

      if (response.data[3].length > 0) {
        const sprocedure = response.data[3][0]["sprocedure"];
        let selectedProcedures = [];
        if (sprocedure) {
          const selectedProcedureIds = sprocedure.split(",").filter(Boolean);
          selectedProcedures = selectedProcedureIds
            .map((id) => {
              const procedure = ddlprocedure.find(
                (proc) => proc.pm_workinst_detail_id == id
              );
              return procedure
                ? {
                  value: procedure.pm_workinst_detail_id,
                  label: procedure.pm_workinst_doc_id,
                }
                : null;
            })
            .filter(Boolean);
        }

        setFormData({
          ...formData,
          processsheet: response.data[3][0]["pm_procsheet_code"],
          type: formData.type + "",
          clientname: response.data[3][0]["clientname"],
          projectname: response.data[3][0]["project_name"],
          project_id: response.data[3][0]["project_id"] + "",
          procsheet_id: response.data[3][0]["procsheet_id"] + "",
          pipesize: response.data[3][0]["pipesize"],
          typeofcoating: response.data[3][0]["coating_type"],
          procedure: selectedProcedures,
          shift: response.data[3][0]["pm_shift_id"],
          coatingType: value,
        });
      }

    } catch (error) {
      console.error('Error fetching report data:', error);
    }

  };
  const getThicknessInstruments = () => {
    axios
      .post(Environment.BaseAPIURL + `/api/User/getinstrumentlist`, {
        processsheetno: formData.type || "",
      })
      .then((response) => {
        if (response?.data) {
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleChange = (selectedOption) => {
    if (userRole === "Admin") {
      setWiSelectedProcedure(selectedOption);
    }
  };

  const groupedData = matManGrade.reduce((acc, item) => {
    const key = `${item.Material_Id}_${item.Manfacturer_Id}_${item.Grade_Id}`;
    if (!acc[key]) {
      acc[key] = { ...item, batches: [] };
    }
    acc[key].batches.push(item.pm_batch);
    return acc;
  }, {});

  const groupedDataArray = Object.values(groupedData);
  console.log(groupedDataArray)

  const handleBatchChange = (key, selectedOption) => {
    setSelectedBatches(prevState => ({
      ...prevState,
      [key]: selectedOption,
    }));
    console.log(selectedBatches)
  };

  const getCoatStatusOptions = async () => {
    axios
      .get(Environment.BaseAPIURL + `/api/User/GetCoatStatustypeData`)
      .then((response) => {
        if (response?.data) {
          setCoatStatusOptions(response.data);
          axios.get(Environment.BaseAPIURL + `/api/User/GetWiTestList?sub_test_id=0&test_id=${value_type}`)
            .then((response) => {
              const procedures = response?.data.map(item => ({ value: item.work_instr_id, label: item.workinst_doc_id }));
              setWiProcedure(procedures)
              setWiSelectedProcedure(procedures);
              if (action == 'edit') {
                editData(procedures)
              }
            })
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  let columnDefs = [
    {
      headerName: "S No.",
      field: "Id",
      width: 70,
      headerClass: "custom-header",
    },
    {
      headerName: "Pipe No.",
      width: 130,
      field: "pipeno",
      headerClass: "custom-header",
    },
    {
      headerName: "ASL No.",
      field: "aslno",
      width: 100,
      headerClass: "custom-header",
    },
    // {
    //   headerName: "Actions",
    //   field: "actions",
    //   cellRenderer: "removeButtonRenderer",
    //   width: 100,
    //   headerClass: "custom-header",
    // },
  ];
  //End TestGrid


  function removeButtonRenderer(params) {
    const button = document.createElement('button');
    button.innerText = 'Remove';
    button.addEventListener('click', () => {
      // Call the function to remove the row
      params.api.applyTransaction({ remove: [params.data] });
    });
    return button;
  }

  let finlrowdata = "";
  let rowData = [];
  let fieldsNamesarr = [];
  let columnsArr = []
  let valueids = [];
  let dynamicRowDataForZerothPos = "";
  let dynamicRowDataForFirstPos = "";
  function removeSpecialCharacters(str) {
    // Replace any character that is not alphanumeric or underscore with an empty string
    return str?.replace(/[^\w\s]/gi, '');
  }
  // debugger;
  console.log(APIresponse)
  APIresponse.forEach(function (element, index) {
    // debugger;
    const new_param_value = removeSpecialCharacters(element.co_param_val_name)
    element.co_param_val_name = new_param_value
    let fieldsNames;
    if (
      element.PM_Reqmnt_test_min !== null &&
      element.PM_Reqmnt_test_Max !== null
    ) {
      let dynamicField;
      dynamicField = element.co_param_val_name?.split(" ").join("");
      columnsArr.push(dynamicField)
      fieldsNames = dynamicField;
      const selectValues = element?.pm_test_value?.split(/,\s*/)
      const values = element.pm_value_type == "A" ? element.pm_test_value : element.PM_Reqmnt_test_min

      let obj = {
        headerName: element.co_param_val_name,
        field: dynamicField,

        editable: function (params) {
          if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
            if (params.data.remarks != 'NTC') {
              return true;
            }
          } else {
            return false;
          }
        },
        cellEditor: element.pm_value_type === "A" && selectValues.length > 1 ? "agSelectCellEditor" : '',
        cellEditorParams: {
          values: selectValues?.map((data) => data),
        },
        // valueFormatter: (params) => {
        //   console.log(params.node.rowIndex)
        //   if (element.pm_value_type === "A" && params.colDef.field === obj.field && params.node.rowIndex > 1) {
        //     // Only set the first value of selectValues if the field matches obj.field, row index is >= 2, and pm_value_type is "A"
        //     return params.value ? params.value : (selectValues.length > 0 ? selectValues[0] : "Ok");
        //   } else {
        //     // For all other fields, just return the value as is
        //     return params.value;
        //   }
        // },
        width: 200,
        headerClass: "custom-header",
        cellStyle: (params) => {
          if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
            if (typeof parseFloat(params.value) == "number") {
              let minvalue = rowData[0][params.colDef.field];
              let maxvalue = rowData[1][params.colDef.field];
              if (
                (minvalue == '' &&
                  parseFloat(params.value) > maxvalue) || (maxvalue == '' &&
                    parseFloat(params.value) < minvalue) || (maxvalue != '' &&
                      minvalue != '' && (parseFloat(params.value) < minvalue || parseFloat(params.value) > maxvalue))
              ) {
                showPopUp.current = true
                return { borderColor: "#ED2939" };
              } else {
                showPopUp.current = false
                return { borderColor: "#34B233" };
              }
            } else {
              return { borderColor: "#FFA100" };
            }
          }
        },
        valueSetter: TestGridValueSetter,
      };

      columnDefs.push(obj);
      if (dynamicRowDataForZerothPos === "") {
        dynamicRowDataForZerothPos =
          '"' + dynamicField + '":' + '"' + values + '",';
        dynamicRowDataForFirstPos =
          '"' + dynamicField + '":' + '"' + element.PM_Reqmnt_test_Max + '",';
        finlrowdata = '"' + dynamicField + '":' + '"' + '",';
      } else {
        dynamicRowDataForZerothPos =
          dynamicRowDataForZerothPos +
          '"' +
          dynamicField +
          '":' +
          '"' +
          values +
          '",';
        dynamicRowDataForFirstPos =
          dynamicRowDataForFirstPos +
          '"' +
          dynamicField +
          '":' +
          '"' +
          element.PM_Reqmnt_test_Max +
          '",';
        finlrowdata = finlrowdata + '"' + dynamicField + '":' + '"' + '",';
      }
    }
    if (
      element.pm_reqmnt_temp_Minus !== '' &&
      element.pm_reqmnt_temp_plus !== ''
    ) {
      let dynamicField;
      dynamicField = (element.co_param_val_name + "Temp1").split(" ").join("");
      fieldsNames = fieldsNames + "@~@" + dynamicField;
      columnsArr.push(dynamicField)
      let obj = {
        headerName: element.co_param_val_name + " Temp.. Range",
        field: dynamicField,
        editable: function (params) {
          if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
            if (params.data.remarks != 'NTC') {
              return true;
            }
          } else {
            return false;
          }
        },
        width: 250,
        headerClass: "custom-header",
        cellStyle: (params) => {
          if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
            if (typeof parseFloat(params.value) == "number") {
              let minvalue = rowData[0][params.colDef.field];
              let maxvalue = rowData[1][params.colDef.field];
              if (
                parseFloat(params.value) < minvalue ||
                parseFloat(params.value) > maxvalue
              ) {
                showPopUp.current = true
                return { borderColor: "#ED2939" };
              } else {
                showPopUp.current = false
                return { borderColor: "#34B233" };
              }
            } else {
              return { borderColor: "#FFA100" };
            }
          }
        },
        valueSetter: TestGridValueSetter,
      };
      columnDefs.push(obj);
      if (dynamicRowDataForZerothPos === "") {
        dynamicRowDataForZerothPos =
          '"' + dynamicField + '":' + '"' + element.pm_reqmnt_temp_Minus + '",';
        dynamicRowDataForFirstPos =
          '"' + dynamicField + '":' + '"' + element.pm_reqmnt_temp_plus + '",';
        finlrowdata = '"' + dynamicField + '":' + '"' + '",';
      } else {
        dynamicRowDataForZerothPos =
          dynamicRowDataForZerothPos +
          '"' +
          dynamicField +
          '":' +
          '"' +
          element.pm_reqmnt_temp_Minus +
          '",';
        dynamicRowDataForFirstPos =
          dynamicRowDataForFirstPos +
          '"' +
          dynamicField +
          '":' +
          '"' +
          element.pm_reqmnt_temp_plus +
          '",';
        finlrowdata = finlrowdata + '"' + dynamicField + '":' + '"' + 0 + '",';
      }
    }
    finlrowdata = finlrowdata + '"remarks":' + '"' + "Select" + '",';
    finlrowdata = finlrowdata + '"remarks":' + '"' + "Select" + '",';
    fieldsNamesarr.push(fieldsNames);
    let ids =
      finlrowdata +
      '"' +
      "pm_proc_template_id" +
      '":' +
      element.pm_proc_template_id +
      "," +
      '"' +
      "pm_test_categ_id" +
      '":' +
      element.pm_test_categ_id +
      "," +
      '"' +
      "pm_test_type_id" +
      '":' +
      element.pm_test_type_id +
      "," +
      '"' +
      "pm_test_id" +
      '":' +
      element.pm_test_id +
      "," +
      '"' +
      "pm_proc_test_id" +
      '":' +
      element.pm_proc_test_id;
    ids = "{" + ids + "}";
    valueids.push(JSON.parse(ids));
  });

  // if (formData.coatingType == "526") {
  if (formData.coatingType == "526") {
    columnDefs.push({
      headerName: "Coat Status",
      field: "coat_status",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: coatStatusOptions.map((item) => item.co_param_val_alias),
      },
      editable: function (params) {
        if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
          return true;
        } else {
          return false;
        }
      },
      width: 250,
      headerClass: "custom-header",
      valueSetter: TestGridValueSetter,
    });

    columnDefs.push({
      headerName: "Field Test",
      field: "field_test",
      cellRenderer: "agCheckboxCellRenderer",
      cellEditor: "agCheckboxCellEditor",
      editable: function (params) {
        if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
          const matchingRecord = coatStatusOptions.find(record => record.co_param_val_alias === params.data.coat_status);
          if (matchingRecord?.FieldTestCount > 0) {
            return true
          }
        } else {
          return false;
        }
      },
      width: 250,
      headerClass: "custom-header",
      valueSetter: TestGridValueSetter,
    });

    columnDefs.push({
      headerName: "Lab Test",
      field: "lab_test",
      cellRenderer: "agCheckboxCellRenderer",
      cellEditor: "agCheckboxCellEditor",
      editable: function (params) {
        if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
          const matchingRecord = coatStatusOptions.find(record => record.co_param_val_alias === params.data.coat_status);
          if (matchingRecord?.LabTestCount > 0) {
            return true
          }
        } else {
          return false;
        }
      },
      width: 250,
      headerClass: "custom-header",
      valueSetter: TestGridValueSetter,
    });
  }

  // values: ["Select", "OK", "NOT OK", "NTC"],
  columnDefs.push({
    headerName: "Remarks",
    field: "remarks",
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: remarksList && remarksList.length > 0 ? remarksList?.map((data) => data.cbrm_remarks_val) : ''
    },
    editable: function (params) {
      if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
        return true;
      } else {
        return false;
      }
    },
    width: 250,
    headerClass: "custom-header",
    valueSetter: TestGridValueSetter,
  });

  if (formData.coatingType == "524") {
    columnDefs.push({
      headerName: "NTC Reason",
      field: "ntc_reasons",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ntcReasons && ntcReasons.length > 0 ? ntcReasons?.map((data) => data.NTCReasons) : ''
      },
      editable: function (params) {
        if (params.data.aslno !== "Min" && params.data.aslno !== "Max") {
          if (params.data.remarks == 'NTC') {
            return true;
          }
        } else {
          return false;
        }
      },
      width: 250,
      headerClass: "custom-header",
      valueSetter: TestGridValueSetter,
    });
  }

  if (dynamicRowDataForZerothPos !== "") {
    dynamicRowDataForZerothPos =
      "{" +
      dynamicRowDataForZerothPos.substring(
        0,
        dynamicRowDataForZerothPos.length - 1
      ) +
      "}";
    dynamicRowDataForZerothPos = JSON.parse(dynamicRowDataForZerothPos);
    dynamicRowDataForZerothPos.Id = "";
    dynamicRowDataForZerothPos.pipeno = "";
    dynamicRowDataForZerothPos.aslno = "Min";
  }
  if (dynamicRowDataForFirstPos !== "") {
    dynamicRowDataForFirstPos =
      "{" +
      dynamicRowDataForFirstPos.substring(
        0,
        dynamicRowDataForFirstPos.length - 1
      ) +
      "}";
    dynamicRowDataForFirstPos = JSON.parse(dynamicRowDataForFirstPos);
    dynamicRowDataForFirstPos.Id = "";
    dynamicRowDataForFirstPos.pipeno = "";
    dynamicRowDataForFirstPos.aslno = "Max";
  }

  if (finlrowdata !== "") {
    rowData.push(dynamicRowDataForZerothPos);
    rowData.push(dynamicRowDataForFirstPos);
    let temp = finlrowdata;
    finlrowdata = "{" + finlrowdata.substring(0, finlrowdata.length - 1) + "}";
    finlrowdata = JSON.parse(finlrowdata);
    ExistingDataforGrid.forEach(function (element, index) {
      finlrowdata.Id = element?.seqno;
      finlrowdata.pipe_id = element?.pipe_id.toString();
      finlrowdata.pipeno = element?.PIPNO;
      finlrowdata.remarks = element?.pm_test_result_remarks;
      finlrowdata.aslno = element?.pm_asl_number;
      finlrowdata.field_test = element?.isfield == 1 ? true : false;
      finlrowdata.lab_test = element?.islab == 1 ? true : false;
      finlrowdata.coat_status = element?.coat_status;
      Object.keys(finlrowdata).forEach(function (objcolkeyitem) {
        if (!["Id", "pipeno", "aslno", "pipe_id", "remarks", "field_test", "lab_test", "coat_status"].includes(objcolkeyitem)) {
          // let isTempCol = objcolkeyitem.includes("Temp");
          let isTempCol = objcolkeyitem.includes("Temp1");
          let checkcol = isTempCol ? objcolkeyitem.split("Temp1")[0] : objcolkeyitem;

          // Find matching data based on `PIPNO` and `checkcol`
          let matchDatabasedonkey = ExistingDataforGrid.filter(item =>
            item.PIPNO === element.PIPNO
          );
          if (matchDatabasedonkey.length > 0) {
            if (isTempCol) {
              finlrowdata[objcolkeyitem] = matchDatabasedonkey[0][checkcol]?.pm_temperature1 || "";
            } else {
              if (matchDatabasedonkey[0][checkcol]?.pm_value_type == "A") {
                finlrowdata[objcolkeyitem] =
                  matchDatabasedonkey[0][checkcol]?.pm_test_value2
              }
              else {
                finlrowdata[objcolkeyitem] = matchDatabasedonkey[0][checkcol]?.pm_test_value1 || "";
              }
            }
          }
        }
      });
      rowData.push(finlrowdata);
      finlrowdata = temp;
      finlrowdata =
        "{" + finlrowdata.substring(0, finlrowdata.length - 1) + "}";
      finlrowdata = JSON.parse(finlrowdata);

      // Push `finlrowdata` to `rowData` array
    });

    DataforGrid.forEach(function (element, index) {
      // finlrowdata.Id = index + 1;
      finlrowdata.Id = element["seqno"];
      // finlrowdata.Id = element["seqno"];
      finlrowdata.pipe_id = element["pipe_id"] + "";
      finlrowdata.pipeno = element["PIPNO"];
      finlrowdata.aslno = element["pm_asl_number"];
      let objcolkey = Object.keys(finlrowdata);
      if (objcolkey.length > 0) {
        objcolkey.forEach(function (objcolkeyitem, objcolkeyindex) {
          if (["Id", "pipeno", "aslno"].includes(objcolkeyitem) == false) {
            let isTempCol = false;
            let checkcol = "";
            if (objcolkeyitem.includes("Temp1")) {
              isTempCol = true;
              checkcol = objcolkeyitem.split("Temp1")[0];
            } else {
              checkcol = objcolkeyitem;
            }
            let matchDatabasedonkey = ExistingDataforGrid.filter(
              (item) =>
                item.PIPNO === element.PIPNO &&
                item.co_param_val_name?.replace(/[^\w\s]/gi, '').includes(checkcol)
            );
            if (matchDatabasedonkey.length > 0) {
              if (isTempCol) {
                finlrowdata[objcolkeyitem] =
                  matchDatabasedonkey[0]["pm_temperature1"];
              } else {
                if (element.pm_value_type == "A") {
                  finlrowdata[objcolkeyitem] =
                    matchDatabasedonkey[0]["pm_test_value2"];
                }
                else {
                  finlrowdata[objcolkeyitem] =
                    matchDatabasedonkey[0]["pm_test_value1"];
                }
              }
            }
          }
        });
      }
      rowData.push(finlrowdata);
      finlrowdata = temp;
      finlrowdata =
        "{" + finlrowdata.substring(0, finlrowdata.length - 1) + "}";
      finlrowdata = JSON.parse(finlrowdata);
    });
  }

  function TestGridValueSetter(params) {
    const newValue = params.newValue;
    const { data, colDef, api } = params;
    console.log(data, colDef, api)
    // Handle specific logic for different columns
    console.log(data, newValue)

    function isAlphaNumericOrAlpha(value) {
      // Regex for alphanumeric values, including special characters
      const alphanumericRegex = /^[a-zA-Z0-9\s\p{L}\p{N}]*$/u; // Unicode property escapes for letters and numbers
      // Regex for pure numeric values
      const numericRegex = /^\d+$/;

      // Check if the value is purely numeric
      if (numericRegex.test(value)) {
        return false;
      }

      // Check if the value is alphanumeric or alphabetic, including special characters
      return alphanumericRegex.test(value);
    }

    switch (params.colDef.field) {
      case "coat_status":
        // Update coat_status field in data
        data.coat_status = newValue;
        if (newValue == "Bare") {
          data.field_test = false
          data.lab_test = false
        }
        break;
      case "field_test":
        // Update field_test field in data
        data.field_test = newValue;
        break;
      case "lab_test":
        // Update lab_test field in data
        data.lab_test = newValue;
        break;
      case "ntc_reasons":
        data.ntc_reasons = newValue;
        for (let key in data) {
          if (key !== 'Id' && key !== 'aslno' && key !== 'pipe_id' && key !== 'remarks' && key !== 'pipeno' && key !== 'coat_status' && key !== 'field_test' && key !== 'lab_test') {
            data[key] = newValue;
          }
        }
        break;

      default:
        console.log(params.node.rowIndex)
        if (params.node.rowIndex === 2) {
          console.log("----okay----")
          // Update the value for the first row
          if (params.oldValue !== params.newValue) {
            data[params.colDef.field] = newValue;
          } else {
            data[params.colDef.field] = params.oldValue;
          }

          // Loop through all rows and set the value in the specified column
          if (isAlphaNumericOrAlpha(newValue)) {
            api.forEachNode((node) => {
              if (node.rowIndex !== 0 && node.rowIndex > 2) {
                node.setDataValue(colDef.field, newValue);
              }
            });
          }
        } else {
          console.log("----oooo----")
          // For other rows, just update the specific cell
          if (params.oldValue !== params.newValue) {
            data[params.colDef.field] = newValue;
          } else {
            data[params.colDef.field] = params.oldValue;
          }
        }
        // For dynamic columns or other fields, update as needed
        break;
    }

    // Handle any specific conditions or actions based on the new value
    // if (newValue === "NTC") {
    //   setIsOpen(true);
    // } else {
    //   setIsOpen(false);
    // }

    // Return true to indicate value was set successfully
    return true;

    // if (params.newValue == "NTC") {
    //   setIsOpen(true)
    // }
    // else {
    //   setIsOpen(false)
    // }
    // try {
    //   if (params.newValue !== undefined) {
    //     // const isValidNumber = /^-?\d*\.?\d+$/.test(params.newValue);
    //     if (params.oldValue !== params.newValue) {
    //       console.log(params.data[params.colDef.field], params.newValue)
    //       params.data[params.colDef.field] = params.newValue;
    //       console.log(params.data[params.colDef.field], params.newValue)
    //     } else {
    //       params.data[params.colDef.field] = params.oldValue;
    //     }
    //   } else {
    //     params.data[params.colDef.field] = params.oldValue;
    //   }

    //   // params.data[params.colDef.field] = params.newValue;
    // }


    // catch (error) {
    //   console.log(error);
    // }
  }

  const RemoveButton = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    // Check if the current row is the last row
    const isLastRow = props.rowIndex >= 2;
    // const isLastRow = props.rowIndex == props.api.getDisplayedRowCount() - 1;
    const openModal = (e) => {
      if (isLastRow) {
        e.preventDefault()
        setIsModalOpen(true);
      }
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const confirmRemove = () => {
      props.api.applyTransaction({ remove: [props.node.data] });
      // console.log(indexPipe)
      // const pipes = props.data.pipe_id
      // setIndexPipe((data) => ({ ...data, pipes }))
      excludePipe(props)
      closeModal();
    };

    function excludePipe(data) {
      console.log(data.data["pipe_id"])
      excludedPipes.push(data.data["pipe_id"])
    }
    // setIndexPipe(excludedPipes)

    const onClick = (e) => {
      if (isLastRow) {
        e.preventDefault()
        props.api.applyTransaction({ remove: [props.node.data] });
      }
    };

    return (
      <>
        {isLastRow ? (
          <i onClick={openModal} id={props.rowIndex} className="fa fa-trash"></i>
        ) : (
          <span></span>  // Render nothing if not the last row
        )}

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Confirm Deletion"
          ariaHideApp={false}
          className="BlastingDataModalCustom">
          <div className="BlastingDataModalBox">
            <h2>Confirm Deletion</h2>
            <p>Are you sure you want to delete this row?</p>
            <button onClick={confirmRemove} style={{ background: '#34B233' }}>Yes, Remove</button>
            <button onClick={closeModal} style={{ background: '#ED2939' }}>Cancel</button>
          </div>
        </Modal>
      </>
    );
  };

  // const RemoveButton = (props) => {
  //   const onClick = (e) => {
  //     e.preventDefault();
  //     props.api.applyTransaction({ remove: [props.node.data] });
  //   };

  //   return (
  //     <button onClick={onClick}>Remove</button>
  //   );
  // };


  const gridOptions = {
    columnDefs: columnDefs,
    rowData: rowData,
    onCellValueChanged: onCellValueChanged,
    components: {
      removeButtonRenderer: RemoveButton,  // Register the RemoveButton component
    },
    // Other grid options
  };


  function onCellValueChanged(params) {
    TestGridValueSetter(params);

    if (params.rowIndex === 0) {
      const newValue = params.newValue;
      gridOptions.api.forEachNode(node => {
        if (node.rowIndex !== 0) { // Avoid updating the first row again
          node.setDataValue(params.colDef.field, newValue);
        }
      });
    }
  }
  const handleSelectChange = (event, rowIndex, selectName) => {
    if (selectName != 'batch') {
      const { value } = event.target;
      setMaterialUsed(prevData => {
        const updatedData = [...prevData];
        if (updatedData[rowIndex]) {
          updatedData[rowIndex][selectName] = value;
        } else {
          updatedData[rowIndex] = { [selectName]: value };
        }
        // updatedData[rowIndex].batches = convertToCommaSeparatedString(batches[rowIndex]);
        return updatedData;
      });
    }
    else {
      setMaterialUsed(prevData => {
        const updatedData = [...prevData];
        const batch = [...batches];
        batch[rowIndex] = event;
        setBatches(batch);
        updatedData[rowIndex].batch = convertToCommaSeparatedString(batch[rowIndex]);
        return updatedData;
      })
    }
  };

  const convertToCommaSeparatedString = (dataArray) => {
    return dataArray?.map(item => item.value).join('@#@');
  };

  function containsNonNumeric(value) {
    // Regular expression to match non-numeric characters
    // ^-? means the string can optionally start with a minus sign
    // \d+ means there should be at least one digit
    // (\.\d+)? means there can optionally be a dot followed by one or more digits
    let numericPattern = /^-?\d+(\.\d+)?$/;

    return !numericPattern.test(value);
  }

  const handleSubmit = async (e, value) => {
    console.log(excludedPipes)
    setIsSubmitting(true);

    if (showPopUp.current) {
      const confirmed = window.confirm('Are you sure you want to proceed with Non-Satisfactory result?');
      if (confirmed) {
        console.log('ok')
      } else {
        setIsSubmitting(false);
        return
      }
    }
    // const batchesString = convertToCommaSeparatedString(batch);
    // let data = materialUsed.filter(item => item !== null && item !== undefined);
    // data.batches = batches
    // const filteredData = data.filter(row => (
    //   row.material && row.manufacturer && row.grade && row.batch
    // ));
    // let transformedData = [];

    // // Iterate over each item in the original data
    // filteredData.forEach(item => {
    //   // Split the batch field by '@#@' separator
    //   let batches = item.batch.split('@#@');

    //   // For each split batch, create a new object with the same material, manufacturer, and grade
    //   batches.forEach(batch => {
    //     let newObject = {
    //       "material": item.material,
    //       "manufacturer": item.manufacturer,
    //       "grade": item.grade,
    //       "batch": batch
    //     };
    //     transformedData.push(newObject);
    //   });
    // });

    // // Print the transformed data (array of objects)

    // // Format data to match the specified order
    // const formattedData = filteredData.map(row => ({
    //   material: row.material,
    //   manufacturer: row.manufacturer,
    //   grade: row.grade,
    //   batch: row.batch
    // }));
    const transformedData = [];
    console.log(groupedDataArray, selectedBatches)
    groupedDataArray.forEach((item) => {
      const key = `${item.Material}-${item.Manfacturer}-${item.Grade}`;
      const selectedOption = selectedBatches[key] || [];

      selectedOption.forEach((option) => {
        transformedData.push({
          material: item.ps_material_id.toString(),
          manufacturer: item.ps_manufacturer_id.toString(),
          grade: item.ps_grade_id.toString(),
          batch: option.value,
        });
      });
    });


    console.log(transformedData)

    setSubmit(value)
    e.preventDefault();
    let Aggriddata = [];
    var testing_data = {}
    fieldsNamesarr.forEach(function (ele, index) {
      for (var i = 0; i < columnsArr.length; i++) {
        testing_data[i] = columnsArr[i]
      }
      // columnsArr.forEach(function (ele, index) {
      let firstcolname = ele.split("@~@")[0];
      let secondcolname = ele.split("@~@")[1];
      var colNo = {}
      rowData.forEach(function (elerow) {
        const selectedOption = coatStatusOptions.find(option => option.co_param_val_alias === elerow.coat_status);
        const id = selectedOption ? selectedOption.co_param_val_id : '0';
        if (elerow.pipeno !== "") {
          let obj = {
            seqno: "",
            test_categ_id: "",
            test_type_id: "",
            test_id: "",
            proc_template_id: "",
            proc_test_id: "",
            shift_id: formData.shift.toString(),
            test_result_batch: "0",
            pipe_id: "",
            temperature1: "",
            test_value1: "",
            test_value2: "",
            value_type: value_type,
            test_result_accepted: "",
            assigned_to_role_id: "",
            test_result_remarks: "",
            coatstatus: id.toString(),
            islab: elerow.lab_test == true ? true : false,
            isfield: elerow.field_test == true ? true : false,
            ntcReason: "",
          };

          let result = ntcReasons.find(item => item.NTCReasons === elerow.ntc_reasons);
          if (elerow?.remarks == "Not OK" || elerow?.remarks == "OK") {
            result = ""
          }
          obj.pipe_id = elerow.pipe_id;
          obj.test_result_remarks = elerow.remarks;
          obj.ntcReason = result ? result.valId.toString() : "";
          obj.seqno = elerow?.Id ? elerow?.Id.toString() : "1";

          if (firstcolname !== "") {
            obj.test_value1 = result ? "0" : elerow[firstcolname] + "";
          }
          if (secondcolname != undefined) {
            obj.temperature1 = result ? "0" : elerow[secondcolname] == undefined ? 0 : elerow[secondcolname] + "";
          }
          else {
            obj.temperature1 = "0"
          }
          obj.proc_template_id = valueids[index].pm_proc_template_id + "";
          obj.test_categ_id = valueids[index].pm_test_categ_id + "";
          obj.test_type_id = valueids[index].pm_test_type_id + "";
          obj.test_id = valueids[index].pm_test_id + "";
          obj.proc_test_id = valueids[index].pm_proc_test_id + "";
          console.log(excludedPipes)
          if (!excludedPipes.includes(elerow.pipe_id)) {
            Aggriddata.push(obj);
          }
        }
      });
      // }
    });
    let selectedprocedure = "";
    // formData?.procedure?.forEach((element) => {
    //   selectedprocedure = selectedprocedure + element.value + ",";
    // });
    let thickness = false
    if (formData.coatingType == '526') {
      thickness = true
    }
    else {
      thickness = false
    }
    console.log(testRunId)
    let inputData = {
      test_run_id: action == 'edit' ? pm_test_run_id : testRunId ? testRunId.toString() : "0",
      project_id: formData.project_id.toString(),
      procsheet_id: formData.procsheet_id.toString(),
      processsheet: formData.processsheet,
      testdate: formData.testdate,
      shift: formData.shift.toString(),
      rm_batch: formData.rm_batch,
      material_id: formData.material_id == "" ? "0" : formData.material_id,
      manufacturer_id: formData.manufacturer_id == "" ? "0" : formData.manufacturer_id,
      grade_id: formData.grade_id == "" ? "0" : formData.grade_id,
      created_by: formData.created_by,
      year: formData.year,
      type: formData.type,
      clientname: formData.clientname,
      projectname: formData.projectname,
      pipesize: formData.pipesize,
      typeofcoating: formData.typeofcoating,
      procedure_type: wiSelectedProcedure.map((proc) => proc.value).join(',') + ',',
      process_type: formData.coatingType.toString(),
      ispqt: formData.ispqt + "",
      testsData: Aggriddata,
      instrumentData: usedInstrument,
      rawMaterialData: transformedData,
      isSubmit: value,
      isThickness: thickness,
      roleId: parseInt(roleId)
    };
    let flag = 0
    if (inputData.testsData.length == 0) {
      toast.error("There are no pipes available")
      return
    }
    console.log(inputData)
    console.log(Aggriddata)
    for (let i = 0; i < inputData.testsData.length; i++) {
      if ((inputData.testsData[i].test_value1 == "" || inputData.testsData[i].temperature1 == "" || inputData.testsData[i].test_result_remarks == "Select") && inputData.testsData[i].test_result_remarks != "NTC") {
        flag = 1
      }
      if (containsNonNumeric(inputData.testsData[i].temperature1) && inputData.testsData[i].test_result_remarks != "NTC") {
        flag = 2
      }
    }
    if (false) {

    }
    // if (flag == 1) {
    //   setIsSubmitting(false);
    //   toast.error("Please enter field values")
    //   return
    // }
    // else if (flag == 2) {
    //   setIsSubmitting(false);
    //   toast.error("Please enter numeric values only in temperature field")
    //   return
    // }
    else {
      try {
        const response = await fetch(
          Environment.BaseAPIURL + "/api/User/SaveProcessDataEntry",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(inputData),
          }
        );

        const responseBody = await response.text();
        setIsSubmitting(false);
        if (responseBody === "1000") {
          toast.success("Form data sent successfully!");
          console.log("Form data sent successfully!");
          navigate(`/blastingsheetlist?menuId=${menuId}`)
        } else {
          console.error(
            "Failed to send form data to the server. Status code:",
            response.status
          );
          console.error("Server response:", responseBody);
        }
      } catch (error) {
        console.error("An error occurred while sending form data:", error);
      }
    }
  };

  const handleApprovalSubmit = async () => {
    try {
      const response = await fetch(
        Environment.BaseAPIURL + "/api/User/updateQCApprove",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(approvalFormData),
        }
      );

      const responseBody = await response.text();

      if (responseBody === "100" || responseBody === "200") {
        toast.success("Form data sent successfully!");
        console.log("Form data sent successfully!");
      } else {
        console.error(
          "Failed to send form data to the server. Status code:",
          response.status
        );
        console.error("Server response:", responseBody);
      }
    } catch (error) {
      console.error("An error occurred while sending form data:", error);
    }
  };

  // ---------------------------------------------
  // -------------------------------------------
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleStatusChange = (value) => {
    if (value === "A") {
      setApprovalFormData({ ...approvalFormData, QCApproverejectflag: "1" });
    }
    if (value === "R") {
      setApprovalFormData({ ...approvalFormData, QCApproverejectflag: "0" });
    }
  };

  const formatDate = (date) => {
    if (!date) return '';

    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header />
          <section className="InnerHeaderPageSection">
            <div
              className="InnerHeaderPageBg"
              style={{ backgroundImage: `url(${RegisterEmployeebg})` }}
            ></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <ul>
                    <li>
                      <Link to="/dashboard?moduleId=618">Quality Module</Link>
                    </li>
                    <li><h1>/&nbsp;</h1></li>
                    <li>&nbsp;<Link to={`/blastingsheetlist?menuId=${menuId}`}>&nbsp;Process Data Entry List</Link></li>
                    <li>
                      <h1>/&nbsp; Process Data Entry </h1>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="RawmaterialPageSection BlastingDataEntrySectionPage">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="PipeTallySheetDetails">
                    <form className="form row m-0">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <h4>
                          Process Data Entry <span>- Add page</span>
                        </h4>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Process Sheet</label>
                          <div className="ProcessSheetFlexBox">
                            <input
                              style={{ width: "66%", cursor: "not-allowed" }}
                              name="processsheet"
                              value={formData.processsheet}
                              onChange={handleTypeChange}
                              disabled={ProcessSheetFields}
                              placeholder="Process sheet no."
                              readOnly
                            />

                            <select
                              name="year"
                              value={formData.year}
                              onChange={handleTypeChange}
                            >
                              <option value="">Year</option>
                              {ddlYear.map((coatingTypeOption, i) => (
                                <option
                                  key={i}
                                  value={coatingTypeOption.year_id}
                                >
                                  {" "}
                                  {coatingTypeOption.year_value}{" "}
                                </option>
                              ))}
                            </select>
                            {/* <select
                              name="year"
                              value={formData.year}
                              onChange={handleTypeChange}
                            >
                              <option value=""> Year </option>
                              <option value="2022"> 2022 </option>
                              <option value="2023"> 2023 </option>
                            </select> */}
                            <b>-</b>
                            <input
                              type="number"
                              name="type"
                              value={formData.type}
                              onChange={handleTypeChange}
                              onBlur={getTestBlastingEntry}
                              placeholder="No."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Client Name</label>
                          <input
                            type="text"
                            name="clientname"
                            value={formData.clientname}
                            onChange={handleTypeChange}
                            placeholder="Client name"
                            style={{ cursor: "not-allowed" }}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Project Name</label>
                          <input
                            type="text"
                            name="projectname"
                            value={formData.projectname}
                            onChange={handleTypeChange}
                            disabled={shouldDisableFields}
                            placeholder="Project name"
                            style={{ cursor: "not-allowed" }}
                            readOnly
                          />
                        </div>
                      </div> */}
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Pipe Size</label>
                          <input
                            type="text"
                            name="pipesize"
                            value={formData.pipesize}
                            onChange={handleTypeChange}
                            disabled={shouldDisableFields}
                            placeholder="Pipe size"
                            style={{ cursor: "not-allowed" }}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Type Of Coating</label>
                          <input
                            type="text"
                            name="typeofcoating"
                            value={formData.typeofcoating}
                            onChange={handleTypeChange}
                            disabled={shouldDisableFields}
                            placeholder="Type of coating"
                            style={{ cursor: "not-allowed" }}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Procedure/ WI No.</label>
                          <Select
                            className="select"
                            name="procedure"
                            value={wiSelectedProcedure}
                            onChange={handleChange}
                            isDisabled={!userRole}
                            options={wiProcedure}
                            isSearchable
                            isClearable
                            isMulti={true}
                            placeholder="Search or Select procedure..."
                          />
                        </div>
                      </div> */}
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Date</label>


                          <input type="text" value={formatDate(formData?.testdate)} />

                          {/* <DatePicker
                            max={new Date().toISOString().split("T")[0]}
                            name="testdate"
                            value={formData.testdate}
                            onChange={handleTypeChange}
                            dateFormat="dd/MMM/yyyy"
                            placeholderText="DD/MMM/YYYY"
                          /> */}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Shift</label>
                          <select
                            name="shift"
                            className=""
                            style={{ cursor: "not-allowed" }}
                            readOnly
                            disabled
                            value={formData.shift}
                            onChange={handleTypeChange}
                          >
                            <option value="0" selected>
                              Select shift
                            </option>
                            {ddlShift.map((coatingTypeOption, i) => (
                              <option
                                key={i}
                                value={coatingTypeOption.pm_shift_id}
                              >
                                {coatingTypeOption.pm_shiftvalue}
                              </option>
                            ))}
                          </select>
                          {/* <select name="shift" value={formData.shift} onChange={handleTypeChange}>
                                                                        <option value="0">-- Select shift --</option>
                                                                        <option value="1">Day</option>
                                                                        <option value="2">Night</option>
                                                                    </select> */}
                          {/* <input type="text" value={shouldDisableFields ? 'DAY' : ''} title={shouldDisableFields ? 'DAY' : ''} disabled={shouldDisableFields} placeholder='Report No.' style={{ cursor: 'not-allowed' }} readOnly /> */}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Process Type</label>
                          <select
                            name="coatingType"
                            className=""
                            value={formData.coatingType}
                            onChange={handleTypeChange}
                          >
                            <option value=''>Select type</option>
                            {coatingType.map((coatingTypeOption, i) => (
                              <option
                                key={i}
                                value={coatingTypeOption.co_param_val_id}
                              >
                                {" "}
                                {coatingTypeOption.co_param_val_name}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>


                      {ShowProcessTypeGrids && (
                        <>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <hr className="DividerLine" />
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="PQTBox">
                              <input
                                type="checkbox"
                                id="ispqt"
                                name="ispqt"
                                checked={formData.ispqt}
                                onChange={handleTypeChange}
                              />
                              <label for="pqt"> PQT</label>
                            </div>
                          </div>

                          <div className="accordion my-4" id="accordionExample">
                            <div className="accordion-item" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Frequency: {frequency}
                              </button>
                              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <div className="Frequencytable">
                                    <table>
                                      <thead>
                                        <tr style={{ background: "#5a245a", color: "#fff", }}>
                                          <th>Sr No.</th>
                                          <th>Batch</th>
                                          <th>Pipe No.</th>
                                          <th>Shift</th>
                                          <th>Date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {frequencyDetails?.map((item, index) =>
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.batch}</td>
                                            <td>{item.pipeno}</td>
                                            <td>{item.shift}</td>
                                            <td>{new Date(item.date).toLocaleDateString("en-GB")}</td>
                                          </tr>)}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* {(typeOfProcess != "523" && typeOfProcess != "526") ?
                            materials.map((data, i) => {
                              return (
                                <>
                                  <div className='col-md-3 col-sm-3 col-xs-12'>
                                    <div className='form-group'>
                                      <label htmlFor="">Raw Material Name</label>
                                      <select style={{ background: "#fbfbfb" }} name="material" id="" onChange={(e) => handleSelectChange(e, i, 'material')}>
                                        <option>Select raw material</option>
                                        <option value={data?.Material_Id}>{data?.Material}</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className='col-md-3 col-sm-3 col-xs-12'>
                                    <div className='form-group'>
                                      <label htmlFor="">Manufacturer</label>
                                      <select style={{ background: "#fbfbfb" }} name="manufacturer" id="material" onChange={(e) => handleSelectChange(e, i, 'manufacturer')}>
                                        <option>Select mfr</option>
                                        <option value={data?.Manufacturer_Id}>{data?.Manufacturer}</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className='col-md-3 col-sm-3 col-xs-12'>
                                    <div className='form-group'>
                                      <label htmlFor="">Grade</label>
                                      <select style={{ background: "#fbfbfb" }} name="grade" id="" onChange={(e) => handleSelectChange(e, i, 'grade')}>
                                        <option>Select grade</option>
                                        <option value={data?.Grade_Id}>{data?.Grade}</option>
                                      </select>
                                    </div>
                                  </div>

                                  {/* <div className='col-md-3 col-sm-3 col-xs-12'>
                                    <div className='form-group'>
                                      <label htmlFor="">Batch</label>
                                      <select style={{ background: "#fbfbfb" }} multiple name="batch" id="" onChange={(e) => handleSelectChange(e, i, 'batch')}>
                                        <option>Select Batch</option>
                                        {data?.Batches?.map((data1) => {
                                          return (
                                            <option value={data1}>{data1}</option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  </div> */}


                          {/* <div className='col-md-3'>
                                    <label htmlFor="" style={{ marginBottom: '8px' }}>Batch</label>
                                    <Select
                                      className="select"
                                      value={batches[i]}  // Use batchSelections for this specific row
                                      onChange={(selectedOption) => handleSelectChange(selectedOption, i, 'batch')}
                                      options={data.Batches?.map(batch => ({ value: batch, label: batch }))}
                                      isSearchable
                                      isClearable
                                      isMulti
                                      placeholder="Search or select batch..."
                                    />
                                  </div> */}

                          {/* <div className='col-md-3 col-sm-3 col-xs-12'>
                                    <div className='form-group'>
                                      <label htmlFor='batchno'>Batch No.</label>
                                      <Select
                                        className="select"
                                        name="batch"
                                        value={batches}
                                        onChange={(selectedOption) => setBatches(selectedOption)}
                                        options={data?.Batches?.map((clientOption) => ({
                                          value: clientOption,
                                          label: clientOption,
                                        }))}
                                        isSearchable
                                        isClearable
                                        isMulti={true}
                                        placeholder="Search or Select batch..."
                                      />
                                      <span id='testrunid' hidden></span>
                                    </div>
                                  </div> 
                                </>
                              )
                            }) : ''} */}

                          {(typeOfProcess !== "523" && typeOfProcess !== "526") && (
                            <>
                              {
                                ((typeOfProcess === "524" && groupedDataArray.some(item => item.Material === "Phosphoric Acid")) ||
                                  (typeOfProcess === "525" && groupedDataArray.some(item =>
                                    ["Chromate", "Adhesive", "Fusion Bonded Epoxy", "High Density Polyethylene"].includes(item.Material)
                                  ))) &&
                                (
                                  <div className="Frequencytable">
                                    <table>
                                      <thead>
                                        <tr style={{ background: "#5a245a", color: "#fff" }}>
                                          <th>Sr. No.</th>
                                          <th style={{ minWidth: "150px" }}>Material</th>
                                          <th style={{ minWidth: "150px" }}>Manufacturer</th>
                                          <th style={{ minWidth: "150px" }}>Grade</th>
                                          <th style={{ minWidth: "150px" }}>Batch No.</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {groupedDataArray.filter(item => {
                                          if (typeOfProcess === "524") {
                                            return item.Material === "Phosphoric Acid";
                                          }
                                          if (typeOfProcess === "525") {
                                            return ["Chromate", "Adhesive", "Fusion Bonded Epoxy", "High Density Polyethylene"].includes(item.Material);
                                          }
                                          return false;
                                        })
                                          .map((item, index) => {
                                            const key = `${item.Material}-${item.Manfacturer}-${item.Grade}`;
                                            const selectedOption = selectedBatches[key] || null;

                                            return (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td><select><option value={item.ps_material_id}>{item.Material}</option></select></td>
                                                <td><select><option value={item.ps_manufacturer_id}>{item.Manfacturer}</option></select></td>
                                                <td><select><option value={item.ps_grade_id}>{item.Grade}</option></select></td>
                                                <td>
                                                  {action === 'edit' ? (
                                                    <div>
                                                      <Select
                                                        className="select"
                                                        id="batchno"
                                                        value={item.batches.map(batch => ({ value: batch, label: batch }))}
                                                        isMulti
                                                      />
                                                    </div>
                                                  ) : (
                                                    <Select
                                                      className="select"
                                                      id="batchno"
                                                      value={selectedOption}
                                                      onChange={selectedOption => handleBatchChange(key, selectedOption)}
                                                      options={item.batches.map(batch => ({
                                                        value: batch,
                                                        label: batch,
                                                      }))}
                                                      isSearchable
                                                      isClearable
                                                      isMulti
                                                      placeholder="Search or Select batch..."
                                                    />
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                            </>
                          )}
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <hr className="DividerLine" />
                          </div>

                          {typeOfProcess != 523 ?
                            <div className="accordion my-4" id="accordionExample">
                              <div className="accordion-item" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne" style={{ backgroundColor: previousStationPipes?.length > 0 ? 'red' : 'green', color: previousStationPipes?.length > 0 ? 'white' : 'black' }}>
                                  Pipes pending at previous stations | Count : {previousStationPipes?.length}
                                </button>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                  <div className='container-fluid'>
                                    <div className='row'>
                                      <div className='col-md-12 col-sm-12 col-xs-12 p-0'>
                                        <table>
                                          <thead>
                                            <tr style={{ background: "#5a245a", color: "#fff" }}>
                                              <th>S. No.</th>
                                              <th>Process Sheet No.</th>
                                              <th>Pipe No.</th>
                                              <th>ASL No.</th>
                                              {/* <th>Station</th> */}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {previousStationPipes?.map((data, index) => {
                                              return (
                                                <tr index={index}>
                                                  <td>{index + 1}</td>
                                                  <td>{data.pm_procsheet_id}</td>
                                                  <td>{data.PIPNO}</td>
                                                  <td>{data.pm_asl_number}</td>
                                                  {/* <td>{data.co_param_val_name}</td> */}
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> : ''}
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <hr className="DividerLine" />
                          </div>
                          <div className="fadedIcon">
                            <li style={{ listStyle: 'none' }}>
                              <i
                                onClick={() => {
                                  getTestBlastingDataEntries(
                                    formData.coatingType
                                  );
                                }}
                                className="fa-solid fa-arrows-rotate refresh-icon"
                                style={{
                                  color: "#3d7edb",
                                  display: "inline-block",
                                  cursor: "pointer",
                                  marginRight: "7px"
                                }}
                              ></i>
                              Refresh
                            </li>
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div
                              className="ag-theme-alpine"
                              id="custom-scroll"
                              style={{ marginTop: "10px", width: "100%" }}
                            >
                              <AgGridReact
                                className="BlastingDataEntryTable"
                                columnDefs={columnDefs}
                                rowData={rowData}
                                suppressDragLeaveHidesColumns="true"
                                stopEditingWhenGridLosesFocus={false}
                                suppressRowClickSelection="true"
                                rowHeight={50}
                                domLayout="autoHeight"
                                gridOptions={gridOptions}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <hr className="DividerLine" />
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <Table>
                              <thead>
                                <tr
                                  style={{
                                    background: "#5a245a",
                                    color: "#fff !important",
                                  }}
                                >
                                  <th
                                    colSpan={3}
                                    style={{
                                      fontSize: "16px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {" "}
                                    Used Instrument
                                  </th>
                                </tr>
                                <tr
                                  style={{
                                    background: "#5a245a",
                                    color: "#fff",
                                  }}
                                >
                                  <td
                                    style={{
                                      maxWidth: "30px",
                                      background: "whitesmoke",
                                    }}
                                  >
                                    Sr. No.
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: "30px",
                                      background: "whitesmoke",
                                    }}
                                  >
                                    Instrument Name
                                  </td>
                                  <td
                                    style={{
                                      minWidth: "30px",
                                      background: "whitesmoke",
                                    }}
                                  >
                                    Instrument ID/Serial No.
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {usedInstrument.map((tests, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{tests.equip_name}</td>
                                    <td>
                                      <select name="" id="">
                                        <option value="">
                                          -- Select instrument id/ serial no.--{" "}
                                        </option>
                                        <option
                                          value={tests.equip_code}
                                          selected
                                        >
                                          {tests.equip_code}
                                        </option>
                                      </select>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          {!ShowApprovalSection && (
                            <div className="FlexSubmitFlex">
                              <button type="button" className="DraftSaveBtn SubmitBtn" disabled={isSubmitting} onClick={(e) => handleSubmit(e, false)}>
                                Draft Save
                              </button>
                              <button type="button" className="SubmitBtn" disabled={isSubmitting} onClick={(e) => handleSubmit(e, true)}>
                                Submit
                              </button>
                            </div>
                          )}
                        </>
                      )}

                      {ShowApprovalSection && (
                        <>
                          <div className="row text-center mt-4">
                            <p>Prepared By : </p>
                            <p>Prepared on: </p>
                            {ShowTestDate && (
                              <>
                                <p>Reviewed By: </p>
                                <p>Review Date: </p>
                              </>
                            )}

                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <label className="custom-radio">
                                <input
                                  type="radio"
                                  className="Approveinput"
                                  name="pm_approver_status"
                                  id="btnaprv"
                                  onChange={() => handleStatusChange("A")}
                                />
                                <span className="radio-btn">
                                  <i className="fas fa-check"></i>Approve
                                </span>
                              </label>
                              <label className="custom-radio">
                                <input
                                  type="radio"
                                  className="Rejectinput"
                                  name="pm_approver_status"
                                  id="btnreject"
                                  onChange={() => handleStatusChange("R")}
                                />
                                <span className="radio-btn m-0">
                                  <i className="fas fa-times"></i>Reject
                                </span>
                              </label>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <div className="form-group Remarksform-group">
                                <label htmlFor="">
                                  Remarks <b>*</b>
                                </label>
                                <input
                                  name="pm_remarks"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Approval/Rejection Remarks...."
                                  autoComplete="off"
                                  onInput={(event) =>
                                    setApprovalFormData({
                                      ...approvalFormData,
                                      QCApproverejectremark: event.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {ShowTestDate && (
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group Remarksform-group">
                                  <label htmlFor="">
                                    Effective Date <b>*</b>
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    autoComplete="off"
                                    name="pm_testdate"
                                    min={new Date().toISOString().split("T")[0]}
                                    placeholder="Enter Effective Date...."
                                  />
                                </div>
                              </div>
                            )}
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <button
                                type="button"
                                className="ApprovalSubmitBtn"
                                onClick={handleApprovalSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 style={{ color: "black" }}>Select reason for NTC</h4>
              <button onClick={closeModal} style={{ background: 'transparent', border: 'none' }}>X</button>
            </div>
            <form className="ntc-reason-form mt-4">
              <label for="ntc_reason" className="mb-2">NTC reason</label>
              <select name="ntc_reason" className="form-control" onChange={(e) => setNtcValue(e.target.value)}>
                <option disabled selected>Select Reason</option>
                {ntcReasons && ntcReasons?.map((data) => {
                  return (
                    <option value={data?.valId}>{data?.NTCReasons}</option>
                  )
                })}
              </select>
              <div>
                <button type="button" onClick={closeModal} className="SubmitBtn mt-4">Submit</button>
              </div>
            </form>
          </Modal>
          <Footer />
        </>
      )}
    </>
  );
};

export default BlastingDataEntry;
