import React, { useState, useEffect } from "react";
import "./Beforelabtestingapproved.css"
import tatasteellogo from "../../assets/images/tsl-blue-logo.png";
import tatalogo from "../../assets/images/tata-blue-logo.png";
import Loading from "../Loading";
import Environment from "../../environment";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Beforelabtestingapproved() {
    const [formData, setFormData] = useState({
        pm_comp_id: "1",
        pm_location_id: "1",
        pm_project_id: "",
        pm_processsheet_id: "",
        pm_approver_type: "",
        pm_remarks: "",
        pm_testdate: "",
        pm_approver_status: "",
        pm_approved_by: "1"
    });

    const searchParams = new URLSearchParams(document.location.search);
    let projectid = searchParams.get('id');
    const [ShowTestDate, setShowTestDate] = useState('');
    const [ShowApprovalSection, setShowApprovalSection] = useState('');
    const [loading, setLoading] = useState(false);
    const [FirstSectionViewData, setFirstSectionViewData] = useState([]);
    const [InspectionAgency, setInspectionAgency] = useState('');

    useEffect(() => {
        fetchData();
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])


    const fetchData = () => {
        axios.get(Environment.BaseAPIURL + `/api/User/ViewProcessSheet?project_id=${projectid}`)
            .then((response) => {
                let pm_processsheet_id = searchParams.get('pm_processsheet_id');
                let ViewType = searchParams.get('ViewType');
                if (ViewType === "onhandleApproveClick") {
                    setShowTestDate('');
                    setShowApprovalSection('Yes');
                    setFormData({
                        ...formData, pm_project_id: projectid,
                        pm_processsheet_id: pm_processsheet_id,
                        pm_approver_type: "1",
                    });

                }
                if (ViewType === "onhandleSecondApproveClick") {
                    setShowTestDate('Yes');
                    setShowApprovalSection('Yes');
                    setFormData({
                        ...formData, pm_project_id: projectid,
                        pm_processsheet_id: pm_processsheet_id,
                        pm_approver_type: "2",
                    });

                }
                if (ViewType === 'View') {
                    setShowTestDate('');
                    setShowApprovalSection('');
                }
                setFirstSectionViewData(response.data[0][0]);
                setInspectionAgency(response.data[1][0]);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    };

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(Environment.BaseAPIURL + "/api/User/ProcessSheetApproval", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const responseBody = await response.text();

            if (responseBody === '100' || responseBody === '200') {
                toast.success('Form data sent successfully!');
                console.log("Form data sent successfully!");
            } else {
                console.error(
                    "Failed to send form data to the server. Status code:",
                    response.status
                );
                console.error("Server response:", responseBody);
            }
        } catch (error) {
            console.error("An error occurred while sending form data:", error);
        }
    };


    const handleStatusChange = (value) => {
        if (value === 'A') {
            setFormData({ ...formData, pm_approver_status: 'A' })

        }
        if (value === 'R') {
            setFormData({ ...formData, pm_approver_status: 'R' })
        }
    }


    return (
        <>
            {loading ? (<Loading />) : (
                <>
                    <Header />
                    <section className="InnerHeaderPageSection">
                        <div className="InnerHeaderPageBg" style={{ backgroundImage: `url(${RegisterEmployeebg})` }} ></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <ul>
                                        <li><Link to="/dashboard">Quality Module</Link></li>
                                        <li><h1>/&nbsp;</h1></li>
                                        <li>&nbsp;<Link to="/rawmateriallist?testingtype=607">&nbsp;Before Process Lab Testing</Link></li>
                                        <li><h1>/&nbsp; Before Process Lab View </h1></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="ProcessSheetViewPageSection">
                        <section className="HeaderDataSection">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="HeaderDataFlexdisplay">
                                            <img className="tatasteellogoimg" src={tatasteellogo} alt="" />
                                            <img className="tatalogoimg" src={tatalogo} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h1> PIPE COATING DIVISION <br /> PROCESS SHEET FOR COATING </h1>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div style={{ textAlign: "right" }}>
                                            <p>FORMAT NO: TSL/COAT/QC/F-02 REV: 05 DATE: 13.11.2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='ProcessSheetViewHeaderDetails'>
                            <div className='container-fluid'>
                                <form className='row'>
                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">Client Name</label>
                                            <h4>: &nbsp;&nbsp; {FirstSectionViewData.clientname}</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">Coating Type</label>
                                            <h4>: &nbsp;&nbsp; {FirstSectionViewData.coating_type}</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">Pipe Size</label>
                                            <h4>: &nbsp;&nbsp; {FirstSectionViewData.pipesize} </h4>
                                        </div>
                                    </div>
                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">P. O. NO.</label>
                                            <h4>: &nbsp;&nbsp; {FirstSectionViewData.pm_loi_po_foa_no}</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">Process Sheet No.</label>
                                            <h4>: &nbsp;&nbsp; {FirstSectionViewData.pm_procsheet_code}</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">Date</label>
                                            <h4>: &nbsp;&nbsp; {FirstSectionViewData.pm_procsheet_date}</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">QAP No.</label>
                                            <h4>: &nbsp;&nbsp; {FirstSectionViewData.ps_qap_no}</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-5 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">Technical Specification</label>
                                            <h4>: &nbsp;&nbsp; {FirstSectionViewData.pm_technical_spec}</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-6 col-xs-12'>
                                        <div className='form-group'>
                                            <label htmlFor="">Inspection Agency</label>
                                            <h4>: &nbsp;&nbsp; {InspectionAgency.clientname}</h4>
                                        </div>
                                    </div>
                                </form>
                            </div>



                            {/* <div className="ReporttableSection" id="custom-scroll">
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan={6}>P.O QUANTITY </th>
                                    </tr>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Pipe Size</th>
                                        <th>NOS(Approx)</th>
                                        <th>MTRS</th>
                                        <th>Grade</th>
                                        <th>Color Code(For OD Surface)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>(8.625") 219.10 mm OD x 6.40 mm WT</td>
                                        <td>1108</td>
                                        <td>13300</td>
                                        <td>API 5L GR. X52M  PSL 2</td>
                                        <td>WHITE</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>(6.625") 168.30 mm OD x 6.40 mm WT</td>
                                        <td>3025</td>
                                        <td>36300</td>
                                        <td>API 5L GR. X52M  PSL 2</td>
                                        <td>YELLOW</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>(4.5") 114.3 mm OD x 6.40 mm WT</td>
                                        <td>4750</td>
                                        <td>57000</td>
                                        <td>API 5L GR. X52M  PSL 2</td>
                                        <td>BLUE</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ fontFamily: "Myriad Pro Bold" }}>Total</td>
                                        <td style={{ fontFamily: "Myriad Pro Bold" }}>8883</td>
                                        <td style={{ fontFamily: "Myriad Pro Bold" }}>106600</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}

                            {/* <div className='container-fluid'>
                            <form className='row'>
                                <div className='col-md-7 col-sm-6 col-xs-12'>
                                    <div className='form-group'>
                                        <label htmlFor="">Inspection Agency</label>
                                        <h4>: &nbsp;&nbsp; EDLIPSE ENGINEERING GLOBAL PVT. LTD</h4>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                        </section>

                        <section class="ReporttableSection ProcessSheetViewTableDetails">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div id="custom-scroll">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={6}>{FirstSectionViewData.coating_type} COATING RAW MATERIAL </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>MATERIAL</th>
                                                        <th>MANUFACTURER</th>
                                                        <th>Grade</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div id="custom-scroll">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={6} style={{ borderTop: 'none', fontSize: '16px', textAlign: 'left', paddingLeft: '20px' }}>RAW MATERIAL  INSPECTION & TESTING </th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={6} style={{ borderTop: 'none' }}>EPOXY (IN-HOUSE ) </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>TEST DESCRIPTION</th>
                                                        <th>PQT</th>
                                                        <th>REGULAR PRODUCTION</th>
                                                        <th>ACCEPTATION CRITERIA </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row text-center mt-4">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <label className="custom-radio">
                                            <input type="radio" className="Approveinput" name="pm_approver_status" id="btnaprv" onChange={() => handleStatusChange("A")} />
                                            <span className="radio-btn">
                                                <i class="fas fa-check"></i>Approve
                                            </span>
                                        </label>
                                        <label className="custom-radio">
                                            <input type="radio" className="Rejectinput" name="pm_approver_status" id="btnreject" onChange={() => handleStatusChange("R")} />
                                            <span className="radio-btn">
                                                <i class="fas fa-times"></i>Reject
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className='form-group Remarksform-group'>
                                            <label htmlFor="">Remarks <b>*</b></label>
                                            <input name="pm_remarks" class="form-control" value={formData.pm_remarks} onChange={handleChange} type="text" placeholder="Enter Approval/Rejection Remarks...." autoComplete="off" />
                                        </div>
                                    </div>
                                    {ShowTestDate && (
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className='form-group Remarksform-group'>
                                                <label htmlFor="">Effective Date <b>*</b></label>
                                                <input type="date" class="form-control" autoComplete="off" name="pm_testdate" min={new Date().toISOString().split("T")[0]} value={formData.pm_testdate} onChange={handleChange} placeholder="Enter Effective Date...." />
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <button type='button' className="SubmitBtn" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div >
                    <Footer />
                </>
            )
            }
        </>
    );
}

export default Beforelabtestingapproved