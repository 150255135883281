import React, { useState, useEffect } from 'react';
import Loading from '../Loading';

import TATASteelLogoWhite from '../../assets/images/TATA-SteelLogoWhite.png'
import Profileuser from '../../assets/images/profileuser.png'
import { Link, useLocation } from 'react-router-dom';
import Logout from '../Login/Logout';
import HeaderProfile from '../Common/Header-profile/HeaderProfile';
import Environment from "../../environment";
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';

function Productiondashboard() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const moduleId = queryParams.get('moduleId');
    const userId = secureLocalStorage.getItem('userId');

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchPermissions();
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])

    const fetchPermissions = async () => {
        try {
            await new Promise(resolve => setTimeout(resolve, 2000));
            const response = await axios.get(`${Environment.BaseAPIURL}/api/User/Get_MenuBindByRoleWise?UserId=${userId}&ModuleId=${moduleId}`);
            const sortedPermissions = response.data.sort((a, b) => a.menuId - b.menuId);
            setPermissions(sortedPermissions);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching permissions:', error);
            setLoading(false);
        }
    };

    // ---------------------------------------- LOCAL STORAGE SECTION

    var retrivedUsername = localStorage.getItem("username");

    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <div className='DashboardPageSection'>
                        <div className='DashboardHeaderSection'>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <div className="ModulePageheader">
                                            <img className="tatasteellogoimg" src={TATASteelLogoWhite} alt="TATA Steel Logo" />
                                            <HeaderProfile />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='DashboardDetailsSection'>
                            <aside id="sidebar" className={sidebarOpen ? 'sidebar-open' : 'SideBarSection'}>
                                <div className='sidebartoggleRound'>
                                    <input type="checkbox" id="sidebar-toggle" checked={sidebarOpen} onChange={toggleSidebar} />
                                    {/* <label htmlFor="sidebar-toggle" id="sidebar-icon"><i className="fas fa-bars"></i></label> */}
                                </div>
                                <ul>
                                    {permissions.map((module, index) => (
                                        <li key={index}>
                                            <Link to={`${module.link}?menuId=${module.menuId}`} className="RegisterEmployeeBtn">
                                                <span><i className={module.icon}></i> {module.menuName}</span>
                                            </Link>
                                        </li>
                                    ))}
                                    <li>
                                        <Logout />
                                    </li>
                                </ul>
                            </aside>

                            <div id="content" className={sidebarOpen ? 'content-open' : 'DashboardDetails'}>
                                <div className='DashboardDetailsContent'>
                                    <h1>Production Admin!</h1>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Productiondashboard