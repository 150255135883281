import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import Environment from "../../../environment";
import '../Allreports.css';
import HeaderDataSection from "../Headerdata";
import Footerdata from '../Footerdata';
import Loading from "../../Loading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from 'react-secure-storage';
import html2pdf from 'html2pdf.js';

function formatDate(dateString) {
    return dateString ? new Date(dateString).toLocaleDateString('en-GB') : "-";
}

const parseKeyValuePair = (str) => {
    const parts = str.split(':-');
    const key = parts[0].trim(); // Key before ':-'
    const value = parts[1]?.trim(); // Value after ':-', using optional chaining
    return { key, value };
};

function PorosityTest() {
    const { tstmaterialid } = useParams();
    const contentRef = useRef();
    const [headerDetails, setHeaderDetails] = useState({});
    const [testDetails, setTestDetails] = useState([]);
    const [instrumentDetails, setInstrumentDetails] = useState([]);
    const [rawMaterialDetails, setRawMaterialDetails] = useState([]);
    const [signatureReport, setSignatureReport] = useState([])
    const [showRemarks, setShowRemarks] = useState([])
    const [reportTestDate, setReportTestDate] = useState()
    const [witnessValue, setWitnessValue] = useState('');
    const [witnessSelected, setWitnessSelected] = useState(false);
    const [showWitness, setShowWitness] = useState(true)
    const [witnessData, setWitnessData] = useState([])
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState()
    const [value, setKeyValue] = useState()
    const location = useLocation();
    const [isClicked, setIsClicked] = useState(false)
    const renderRows = useRef()
    const navigate = useNavigate()
    const pathSegments = location.pathname.split(/[\/&]/);
    const companyId = secureLocalStorage.getItem("emp_current_comp_id")
    const [regPerc, setRegPerc] = useState();
    const [attachedFiles, setAttachedFiles] = useState([])

    let pm_project_id1 = null;
    let pm_processSheet_id1 = null;
    let pm_processtype_id1 = null;
    let pm_approved_by1 = null;
    let pm_Approve_level1 = null;
    let menuId1 = null;
    for (let i = 0; i < pathSegments.length; i++) {
        if (pathSegments[i].startsWith('pm_project_id=')) {
            pm_project_id1 = pathSegments[i].substring('pm_project_id='.length);
        }
        if (pathSegments[i].startsWith('pm_processSheet_id=')) {
            pm_processSheet_id1 = pathSegments[i].substring('pm_processSheet_id='.length);
        }
        if (pathSegments[i].startsWith('pm_processtype_id=')) {
            pm_processtype_id1 = pathSegments[i].substring('pm_processtype_id='.length);
        }
        if (pathSegments[i].startsWith('pm_approved_by=')) {
            pm_approved_by1 = pathSegments[i].substring('pm_approved_by='.length);
        }
        if (pathSegments[i].startsWith('pm_Approve_level=')) {
            pm_Approve_level1 = pathSegments[i].substring('pm_Approve_level='.length);
        }
        if (pathSegments[i].startsWith('menuId=')) {
            menuId1 = pathSegments[i].substring('menuId='.length);
        }
    }

    const [id1, id2, id3, id4] = tstmaterialid.split('&');
    const [formData, setFormData] = useState({
        pm_comp_id: 1,
        pm_location_id: 1,
        pm_project_id: parseInt(pm_project_id1),
        pm_processSheet_id: parseInt(pm_processSheet_id1),
        pm_processtype_id: parseInt(pm_processtype_id1),
        pm_remarks: "",
        pm_approver_status: true,
        pm_approved_by: pm_approved_by1,
        pm_approved_on: new Date().toISOString().split('T')[0],
        pm_Approve_level: pm_Approve_level1 == "first" ? 1 : pm_Approve_level1 == "second" ? 2 : 0,
        pm_approvedRoleId_by: '0',
        p_test_run_id: parseInt(id2),
        pm_isfinalapproval: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (tstmaterialid) {
                    const [id1, id2, id3, id4] = tstmaterialid.split('&');
                    const response = await axios.post(`${Environment.BaseAPIURL}/api/User/GetCrossSectionInterfacePorosity?pm_procsheet_id=${id1}&testRunId=${id2}&rtype=${id3}&testid=${id4}`);
                    const data = response.data[0];
                    setHeaderDetails(data._CdTesHeaderDetails[0] || {});
                    setTestDetails(data._HotWaterAdhesionTest24hrsReportDetails || []);
                    setRawMaterialDetails(data._CdTestRawMaterial || []);
                    setAttachedFiles(data._attachfiles)
                    const { key, value } = parseKeyValuePair(data._CdTesHeaderDetails[0].poNo);
                    setKey(key);
                    setKeyValue(value)

                    const date = data._CdTesHeaderDetails[0]?.reportTestDateNew || {}
                    const formattedDate = date?.split('T')[0]
                    const newDateStr = formattedDate.replace(/-/g, '');
                    setReportTestDate(newDateStr);

                    const response1 = await axios.get(`${Environment.BaseAPIURL}/api/User/GETInstrumentDetailsByReportId?ReportId=${pm_processtype_id1}`);
                    const data1 = response1.data[0]
                    setInstrumentDetails(data1);

                    try {
                        if (tstmaterialid) {
                            const [id1, id2, id3, id4] = tstmaterialid.split('&');
                            const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetInspectedByAcceptedByDetails?matid=${id3}&testId=${id2}`);
                            const data = response.data
                            setSignatureReport(data)
                            callWitness()
                        }
                        setLoading(false)
                    } catch (error) {
                        console.error('Error fetching report data:', error);
                        setLoading(false)
                    }
                }
            } catch (error) {
                console.error('Error fetching report data:', error);
            }
        };
        fetchData();
    }, [tstmaterialid]);

    async function callWitness() {
        const [id1, id2] = tstmaterialid.split('&');
        const response1 = await axios.post(`${Environment.BaseAPIURL}/api/User/GetEmployeeTypeWithName?p_procsheet_id=${pm_processSheet_id1}&p_test_run_id=${id2}&p_type_id=${pm_processtype_id1}`);
        setWitnessData(response1?.data)
        const pm_status_app_rej = response1?.data[0]?.pm_status_app_rej
        if (pm_status_app_rej == null || pm_status_app_rej == 0 || pm_status_app_rej == 2 || pm_Approve_level1 == 'second') {
            setShowRemarks(true)
        } else {
            setShowRemarks(false)
        }
        setWitnessValue(response1?.data[0]?.roleId)
        setFormData({ ...formData, pm_approvedRoleId_by: witnessValue != '' ? witnessValue : pm_Approve_level1 == 'first' ? response1?.data[0]?.roleId.toString() : companyId.toString(), pm_isfinalapproval: response1.data.length == 1 ? 1 : 0 })
        setWitnessSelected(true);
        const matchingData = response1?.data.find(item => item.roleId == companyId);
        const regPerc = matchingData ? matchingData.reg_perc : null;
        setRegPerc(regPerc)
    }

    function handleSelect(e) {
        setWitnessValue(e.target.value)
        setFormData({ ...formData, pm_approvedRoleId_by: e.target.value })
        setWitnessSelected(true);
        if (!showRemarks) {
            handleStatusChange("A")
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleStatusChange = (value) => {
        setIsClicked(true)
        if (value === "A") {
            setFormData({ ...formData, pm_approver_status: true, pm_approvedRoleId_by: witnessValue != '' ? witnessValue : pm_Approve_level1 == 'first' ? witnessValue.toString() : companyId.toString() });
            setWitnessSelected(true);
            setShowWitness(true);
        }
        if (value === "R") {
            setFormData({ ...formData, pm_approver_status: false, pm_approvedRoleId_by: "0" });
            setShowWitness(false);
            setWitnessSelected(false)
        }
    };

    const renderApprovalSection = () => {
        return (
            showRemarks ?
                <div className='RemarksFlexBox'>
                    <label htmlFor="">Remarks</label>
                    <input name="pm_remarks" value={formData.pm_remarks} onChange={handleChange} type="text" placeholder="Enter Approval/Rejection Remarks...." autoComplete="off" />
                    <div className='ApproveRejectUIFlex'>
                        <label className="custom-radio">
                            <input type="radio" className="Approveinput" name="pm_approver_status" id="btnaprv" onChange={() => handleStatusChange("A")} />
                            <span className="radio-btn"><i className="fas fa-check"></i>Approve</span>
                        </label>
                        <label className="custom-radio">
                            <input type="radio" className="Rejectinput" name="pm_approver_status" id="btnreject" onChange={() => handleStatusChange("R")} />
                            <span className="radio-btn"><i className="fas fa-times"></i>Reject</span>
                        </label>
                    </div>
                </div> : ''
        );
    };

    const renderFirstApprovalStatus = () => {
        if (pm_Approve_level1 == "first") {
            return (
                <div className="bare-pipe-inspection">
                    {renderApprovalSection()}
                    {showWitness && (<div className="SelectWitnessFlexBox">
                        <label htmlFor="" >Select Witness <b>*</b></label>
                        <select name="" value={witnessValue} onChange={handleSelect}>
                            <option disabled selected>Select Witness</option>
                            {witnessData && witnessData?.map((data) => {
                                return (
                                    <option value={data?.roleId}>{data?.Name}</option>
                                )
                            })}
                        </select>
                    </div>)}
                    <div className='SubmitBtnFlexBox'>
                        {<button type="button" className="SubmitBtn" onClick={handleSubmit}>Submit</button>}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderSecondApprovalStatus = () => {
        if (pm_Approve_level1 == "second") {
            return (
                <div className='BarePipeInspForm row m-0'>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className='renderApprovalFlexBox'>
                            {renderApprovalSection()}
                            {<button type="button" onClick={handleSubmit}>Submit</button>}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (showRemarks) {
            if (formData?.pm_remarks == '' || isClicked == false) {
                toast.error("Please enter remarks and status")
                return
            }
        }

        if (showWitness && !witnessSelected && pm_Approve_level1 != "second") {
            toast.error('Please select a witness before submitting the form.');
            return;
        }
        try {
            const response = await fetch(Environment.BaseAPIURL + "/api/User/InspectionSheetApproval", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ formData, 'checkedPipes': [''] }),
            });

            const responseBody = await response.text();

            if (responseBody === '100' || responseBody === '200') {
                toast.success('Status Updated Successfully!');
                navigate(`/rawmateriallist?menuId=${menuId1}&testingtype=${pm_processtype_id1}`)
                console.log("Form data sent successfully!");
            } else {
                console.error(
                    "Failed to send form data to the server. Status code:",
                    response.status
                );
                console.error("Server response:", responseBody);
            }
        } catch (error) {
            console.error("An error occurred while sending form data:", error);
        }
    };

    const handleDownloadPDF = () => {
        const element = contentRef.current;
        const opt = {
            margin: [10, 10, 10, 10],
            filename: `${headerDetails?.reportAlias}/${reportTestDate}-${new Date().toLocaleDateString('en-GB').replace(/\//g, "-")}.pdf`,
            image: { type: 'jpeg', quality: 1.0 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save();
    };

    const handlePrint = () => {
        window.print();
    };

    // -------------------------------------------

    const groupData = (data) => {
        let groupedData = [];
        let lastMaterial = '';
        let lastBatch = '';
        let lastTestMethod = '';

        data.forEach((item, index) => {
            let materialRowSpan = 0;
            let batchRowSpan = 0;
            let testMethodRowSpan = 0;

            // Count how many rows will have the same material and batch
            data.forEach((subItem) => {
                if (subItem.materialDescription === item.materialDescription) {
                    materialRowSpan++;
                    if (subItem.batchNo === item.batchNo) {
                        batchRowSpan++;
                        if (subItem.testMethod === item.testMethod) {
                            testMethodRowSpan++;
                        }
                    }
                }
            });

            groupedData.push({
                ...item,
                materialRowSpan: lastMaterial === item.materialDescription ? 0 : materialRowSpan,
                batchRowSpan: lastBatch === item.batchNo ? 0 : batchRowSpan,
                testMethodRowSpan: lastTestMethod === item.testMethod ? 0 : testMethodRowSpan
            });

            lastMaterial = item.materialDescription;
            lastBatch = item.batchNo;
            lastTestMethod = item.testMethod;
        });

        return groupedData;
    };

    const groupedData = groupData(testDetails);

    return (
        <>
            {
                loading ? <Loading /> :
                    <div>
                        <div style={{ textAlign: 'right', paddingRight: '14px', paddingTop: '10px' }}>
                            <div className="DownloadPrintFlexSection">
                                <h4 className='DownloadPDFBtn' onClick={handleDownloadPDF}>
                                    <i className="fas fa-download"> </i> Download PDF
                                </h4>
                                <h4 className='PrintBtn' onClick={handlePrint}>
                                    <i className="fas fa-print"></i> Print
                                </h4>
                            </div>
                        </div>
                        <div className='InspReportSection' ref={contentRef}>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <div className='InspReportBox'>

                                            <HeaderDataSection reportData={headerDetails} />

                                            <section className='Reportmasterdatasection'>
                                                <div className='container-fluid'>
                                                    <form className='row'>
                                                        <div className='col-md-7 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Client</label>
                                                                <span>: &nbsp;</span>
                                                                <h4 style={{ textTransform: 'uppercase' }}> {headerDetails.clientName || "-"}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Report No.</label>
                                                                <span>: &nbsp;</span>
                                                                <h4>LTR/{headerDetails?.reportAlias}/{reportTestDate} - 01</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-7 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Project Name</label>
                                                                <span>: &nbsp;</span>
                                                                <h4 style={{ textTransform: 'uppercase' }}>{headerDetails?.projectName || '-'}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Date & Shift</label>
                                                                <span>: &nbsp;</span>
                                                                <h4>{headerDetails.dateShift || "-"}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-7 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">{key ? key : ''}.</label>
                                                                <span>: &nbsp;</span>
                                                                <h4>{value ? value : ''}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Process Sheet No.</label>
                                                                <span>: &nbsp;</span>
                                                                <h4>{headerDetails.procSheetNo || "-"} Rev.00 DATE: 10.08.2024</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-7 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Pipe Size</label>
                                                                <span>: &nbsp;</span>
                                                                <h4>{headerDetails.pipeSize || "-"}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Procedure / WI No.</label>
                                                                <span>: &nbsp;</span>
                                                                <h4>{headerDetails.procedureWINo || "-"}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-7 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Type Of Coating</label>
                                                                <span>: &nbsp;</span>
                                                                <h4>{headerDetails.typeofCoating || "-"}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5 col-sm-6 col-xs-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor="">Production Date</label>
                                                                <span>: &nbsp;</span>
                                                                <h4>{headerDetails?.productionDate?.split(" ")[0].replace(/\//g, '.') || ""}{headerDetails?.reportPqt == '' ? '' : (
                                                                    <> & <span style={{ fontFamily: 'Myriad Pro Regular' }}>{headerDetails.reportPqt}</span></>
                                                                )}</h4>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </section>

                                            {Array.isArray(testDetails) && testDetails.length > 0 && (
                                                <section className='ReporttableSection'>
                                                    <div className='container-fluid'>
                                                        <div className='row'>
                                                            <div className='col-md-12 col-sm-12 col-xs-12'>
                                                                <div id='custom-scroll'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: '60px' }}>SR. NO.</th>
                                                                                <th>MATERIAL DESCRIPTION</th>
                                                                                <th>BATCH NO.</th>
                                                                                <th>TEST DESCRIPTION</th>
                                                                                <th>TEST METHOD</th>
                                                                                <th>REQUIREMENT</th>
                                                                                <th>TEST RESULT</th>
                                                                                <th>REMARKS</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {id4 == '306' || id4 == '307' ?
                                                                                groupedData.map((item, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1 || "-"}</td>
                                                                                        <td>{item.batchRowSpan > 0 && (
                                                                                            <>{item.batchNo}</>
                                                                                        )}</td>
                                                                                        <td>{'-'}</td>
                                                                                        <td>{item.testDescription}</td>
                                                                                        <td>{item.testMethod}</td>
                                                                                        <td>{item.pm_value_type == "A" ? item.pm_test_value : (item.pM_Reqmnt_test_min && item.pM_Reqmnt_test_Max) ?
                                                                                            `${item.pM_Reqmnt_test_min} - ${item.pM_Reqmnt_test_Max}` :
                                                                                            (item.pM_Reqmnt_test_min ? `Min - ${item.pM_Reqmnt_test_min}` :
                                                                                                (item.pM_Reqmnt_test_Max ? `Max - ${item.pM_Reqmnt_test_Max}` : '-'))}{item.PM_Reqmnt_test_min && item.PM_Reqmnt_test_Max ? " " + item.unit : ''}</td>
                                                                                        <td>{item.pm_test_result_remarks || "-"}{item.pm_test_result_remarks ? " " + item.unit : ''}</td>
                                                                                        <td>{item.pm_test_result_accepted == 1 ? "Satisfactory " : "Not Satisfactory "}</td>
                                                                                    </tr>
                                                                                )) :
                                                                                groupedData.map((item, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        {item.materialRowSpan > 0 && (
                                                                                            <td rowSpan={item.materialRowSpan}>{item.materialDescription}</td>
                                                                                        )}
                                                                                        {item.batchRowSpan > 0 && (
                                                                                            <td rowSpan={item.batchRowSpan}>{item.batchNo}</td>
                                                                                        )}
                                                                                        <td>{item.testDescription}</td>
                                                                                        <td>{item.testMethod}</td>
                                                                                        <td>{item.pm_value_type == "A" ? item.pm_test_value : (item.pM_Reqmnt_test_min && item.pM_Reqmnt_test_Max) ?
                                                                                            `${item.pM_Reqmnt_test_min} - ${item.pM_Reqmnt_test_Max}` :
                                                                                            (item.pM_Reqmnt_test_min ? `Min - ${item.pM_Reqmnt_test_min}` :
                                                                                                (item.pM_Reqmnt_test_Max ? `Max - ${item.pM_Reqmnt_test_Max}` : '-'))}{item.PM_Reqmnt_test_min && item.PM_Reqmnt_test_Max ? " " + item.unit : ''}</td>
                                                                                        <td>{item.pm_test_result_remarks || "-"}{item.pm_test_result_remarks ? " " + item.unit : ''}</td>
                                                                                        <td>{item.pm_test_result_accepted == 1 ? "Satisfactory " : "Not Satisfactory "}</td>
                                                                                    </tr>
                                                                                ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}

                                            <section className='ResultPageSection'>
                                                <div className='container-fluid'>
                                                    <div className='row'>
                                                        <div className='col-md-12 col-sm-12 col-xs-12 p-0'>
                                                            <table>
                                                                <tbody>
                                                                    {id4 == '295' && (<tr>
                                                                        <td>Test Start Date : {formatDate(headerDetails.testStartDate).replace(/\//g, '.') || "-"} & Time : {headerDetails.testStartTimeNew || "-"}</td>
                                                                        <td>Test Finish Date : {formatDate(headerDetails.testFinishDate).replace(/\//g, '.') || "-"} & Time : {headerDetails.testFinishTimeNew || "-"}</td>
                                                                    </tr>)}
                                                                    <tr>
                                                                        <td style={{ borderBottom: "none", padding: '2px 12px' }}>ABOVE RESULTS ARE CONFORMING TO SPECIFICATION :- <span style={{ fontFamily: 'Myriad Pro Light' }}>{headerDetails.specification} & QAP NO.- {headerDetails.acceptanceCriteria}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="InstrumentusedSection">
                                                <section className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                                            <table id="instrument-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={3} style={{ textAlign: 'center' }}> INSTRUMENT USED</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>SR. NO.</th>
                                                                        <th>INSTRUMENT NAME</th>
                                                                        <th>INSTRUMENT ID / SERIAL NO.</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {instrumentDetails.map((item, index) => (
                                                                        (item.SrNo || item.equip_name || item.equip_code) && (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{item.equip_name || "-"}</td>
                                                                                <td>{item.equip_code || "-"}</td>
                                                                            </tr>
                                                                        )))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </section>
                                            </section>

                                            {signatureReport && signatureReport.length > 0 ? <Footerdata data={signatureReport} /> : ''}
                                        </div>
                                        {attachedFiles ? <div style={{ marginTop: '40px' }}>
                                            {attachedFiles?.map((data) => {
                                                return (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img src={`${Environment.ImageURL}/${data?.pm_file_name}`} />
                                                    </div>
                                                )
                                            })}
                                        </div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                    {renderFirstApprovalStatus()}
                                </div>
                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                    {renderSecondApprovalStatus()}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}

export default PorosityTest;