import React, { useState, useEffect } from "react";
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg'
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import './Rawmaterial.css';
import Loading from '../Loading';
import { Table } from "react-bootstrap";
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
//import QCofficerData from "../Raw-material/QCofficer.json";
//import QCofficerReport from "../Raw-material/QCofficerReport.json";
//import InstrumentData from "../Raw-material-test-report/InstrumentData.json";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "../../environment";


function QCofficerapproved() {
    const [QCofficerData, setApiResponse] = useState([]);
    const [InstrumentData, setInstrumentData] = useState([]);
    const [QCofficerReport, setQCofficerReport] = useState([]);


    // Get the current date
    const currentDate = new Date();

    const location = useLocation();

    // Format the date in dd/mm/yyyy format
    const formattedDate = format(currentDate, 'dd/MM/yyyy');

    // -------------------------------------------------

    const searchParams = new URLSearchParams(document.location.search);
    let testingtype = searchParams.get('testingtype');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            GetEPOXYProcessSheetDetails();
            setLoading(false)
        }, 2000);
    }, [])


    const GetEPOXYProcessSheetDetails = async (e) => {
        // setType(e.target.value);
        // e.preventDefault();

        try {
            ////  debugger
            // const params = new URLSearchParams();
            //params.append('processshitid', parseInt(location.pathname.split('/')[2]));
            // params.append('year', location.pathname.split('/')[3]);
            const searchParams = new URLSearchParams(document.location.search);
            let processshitid = searchParams.get('processshitid');

            const response = await axios.post(
                Environment.BaseAPIURL + `/api/User/GetqcofficerapprovedDetails?processshitid=${processshitid}`
            );
            ////  debugger
            if (response.status === 200) {
                // toast.error('API Called.');
                //  console.log(JSON.parse(JSON.stringify(response.data.Table)));
                if (response.data.Table[0] !== undefined) {
                    setApiResponse(response.data.Table[0]);
                }
                if (response.data.Table[0] !== undefined) {
                    setInstrumentData(response.data.Table1);
                }
                if (response.data.Table[0] !== undefined) {
                    setQCofficerReport(response.data.Table2);
                    document.getElementById("pmtestrunid").innerText = response.data.Table2[0]["pm_test_run_id"];
                }
            }
        } catch (error) {
            // toast.error('Failed' + error.message);
            console.error('Failed', error.message);
        }
    };

    const updateQCApprove = async (e) => {
        // setType(e.target.value);
        // e.preventDefault();
        let qcApproverejectflag = 0;
        let RawmaterialDetailsPayload;
        try {
            const params = new URLSearchParams();

            if (document.getElementById("btnaprv").checked == true) {
                qcApproverejectflag = 1;

            } else if (document.getElementById("btnreject").checked == true) {
                qcApproverejectflag = 0;
            }

            RawmaterialDetailsPayload = {
                approvertype: 'QC',
                qcApproverejectflag: parseInt(qcApproverejectflag),
                QCApproverejectremark: document.getElementById("txtremark").value,
                pmtestrunid: parseInt(document.getElementById("pmtestrunid").innerText),
            };

            const response = await axios.post(Environment.BaseAPIURL + `/api/User/updateQCApprove`, RawmaterialDetailsPayload);
            if (response.status === 200) {
                toast.success('QC has been approved.');
                window.location.replace("/rawmateriallist?testingtype=" + testingtype);
                //return <Link to='/rawmateriallist'  />
            }
        } catch (error) {
            // toast.error('Failed' + error.message);
            console.error('Failed', error.message);
        }
    };




    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Header />
                        <section className='InnerHeaderPageSection'>
                            <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12 col-xs-12'>
                                        <ul>
                                            <li> <Link to='/dashboard'>Dashboard</Link></li>
                                            <li><h1> / &nbsp; Raw Material Testing </h1></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="RawMaterialReportSectionPage">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="RawMaterialReportTables">
                                            <form action="" className="row m-0">
                                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                                    <h4>Raw Material Testing (QC Officer)  <span>Approve / Reject</span></h4>
                                                    <span hidden id="pmtestrunid"></span>
                                                </div>
                                                {Object.entries(QCofficerData).slice(0, 20).map(([label, value]) => (
                                                    <>
                                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                                            <div className="form-group">
                                                                <label for="">{label}</label>
                                                                <input type="text" readonly id={label} value={value} />
                                                                <input type="text" readonly id={label} hidden />
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}

                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor="">Test Date</label>
                                                        <input className='formattedDateTestDate' type="text" value={formattedDate} />
                                                    </div>
                                                </div>

                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor="">Shift</label>
                                                        <input className='formattedDateTestDate' type="text" value="Day" />
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="row m-0">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <Table>
                                                        <thead>
                                                            <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                                <th>Sr. No.</th>
                                                                <th>Test</th>
                                                                <th>Test Method</th>
                                                                <th>Requirement</th>
                                                                <th>Test Result</th>
                                                                <th>Remarks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {QCofficerReport.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item["Sr. No."]}</td>
                                                                    <td>{item["Test"]}</td>
                                                                    <td>{item["Test Method"]}</td>
                                                                    <td>{item["Requirement"]}</td>
                                                                    <td>{item["Test Result"]}</td>
                                                                    <td>{item["Remarks"]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>

                                                    <Table>
                                                        <thead>
                                                            <tr style={{ background: 'rgb(90, 36, 90)', textAlign: 'center' }}>
                                                                <th colSpan={3} style={{ fontSize: '16px' }}>
                                                                    Instrument to be Used
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ background: 'whitesmoke' }}>Sr. No.</td>
                                                                <td style={{ background: 'whitesmoke' }}>Instrument Name</td>
                                                                <td style={{ background: 'whitesmoke' }}>Instrument ID/Serial No.</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {InstrumentData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item["serial_number"]}</td>
                                                                    <td>{item["equip_name"]}</td>
                                                                    <td>{item["equip_code"]}</td>
                                                                    <td hidden>{item["pm_test_run_id"]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>

                                                    <div className="radio-buttons">
                                                        <label className="custom-radio">
                                                            <input type="radio" className="Approveinput" name="radio" id="btnaprv" />
                                                            <span className="radio-btn">
                                                                <i class="fas fa-check"></i>Approve
                                                            </span>
                                                        </label>
                                                        <label className="custom-radio">
                                                            <input type="radio" className="Rejectinput" name="radio" id="btnreject" />
                                                            <span className="radio-btn">
                                                                <i class="fas fa-times"></i>Reject
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-4 col-xs-12">
                                                    <div className='form-group Remarksform-group'>
                                                        <label htmlFor="">Remarks <b>*</b></label>
                                                        <input type="text" id="txtremark" />
                                                    </div>
                                                </div>

                                                <div className="col-md-8 col-sm-8 col-xs-12 text-end">
                                                    {/* <Link to='/qcofficerlist' className="SubmitBtn">Submit</Link> */}
                                                    <button type='button' className="SubmitBtn" onClick={(e) => updateQCApprove(e)}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </>
            }
        </>
    );
}

export default QCofficerapproved