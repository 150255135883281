import React from "react";
import tatasteellogo from "../../assets/images/tsl-blue-logo.png";
import tatalogo from "../../assets/images/tata-blue-logo.png";
import './Newreport.css';

function Newreport() {
    return (
        <div className="NewReportPageSection">
            <section className="HeaderDataSection">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="HeaderDataFlexdisplay">
                                <img className="tatasteellogoimg" src={tatasteellogo} alt="" />
                                <img className="tatalogoimg" src={tatalogo} alt="" />
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <h1>
                                <span>TATA STEEL LIMITED</span>
                                <span>PIPE COATING DIVISION</span>
                                <span>PROCESS SHEET FOR COATING </span>
                            </h1>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div style={{ textAlign: "right" }}>
                                <p>TSL/COAT/QC/F-02 REV:05 DATE: 13.11.2021</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ReportDetailsSection">
                <table>
                    <tbody>
                        <tr>
                            <th colSpan={2}>CLIENT NAME</th>
                            <td colSpan={5}>INDRAPRASTHA GAS LIMITED</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>COATING TYPE</th>
                            <td colSpan={5}>THREE LAYER POLYETHYLENE COATING</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>PIPE SIZE</th>
                            <td colSpan={5}>As Per PO Quantity</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>P. O. NO.</th>
                            <td colSpan={5}>6100005797 DATED.08.06.2023</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>PROCESS SHEET NO.</th>
                            <td colSpan={3}>TSL/PSC/EXT./3LPE/IGL/2023-09 Rev.00</td>
                            <td colSpan={2}>DATE: 12.09.2023</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>QAP NO.</th>
                            <td colSpan={5}>TSL/COAT/EXT/3LPE/IGL/02 Rev. 00 DATE: 07.07.2023</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>TECHNICAL SPECIFICATION</th>
                            <td colSpan={5}>Clients spec - STANDARD SPECIFICATION FOR 3 LAYER POLYETHYLENE COATING OF LINEPIPES</td>
                        </tr>

                        <tr>
                            <th rowSpan={3} colSpan={2}>P. O Quantity</th>
                            <td className="poquantitytd" colSpan={2}>PIPE SIZE</td>
                            <td className="poquantitytd" colSpan={1}>QUANTITY (NOS.) <br /> APPROX</td>
                            <td className="poquantitytd" colSpan={2}>ORDER QUANTITY (MTRS.)</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center' }} colSpan={2}>323.9 mm OD X <br /> 6.4 mm WT</td>
                            <td style={{ textAlign: 'center' }} colSpan={1}>3663</td>
                            <td style={{ textAlign: 'center' }} colSpan={2}>43960</td>
                        </tr>
                        <tr>
                            <td className="poquantitytd" colSpan={2}>TOTAL</td>
                            <td className="poquantitytd" colSpan={1}>3663</td>
                            <td className="poquantitytd" colSpan={2}>43960</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>INSPECTION AGENCY </th>
                            <td colSpan={5}>
                                <b style={{ fontFamily: 'Myriad Pro Regular' }}>TSL APPOINTED TPIA :</b> EDLIPSE ENGINEERING GLOBAL PVT. LTD. <br />
                                <b style={{ fontFamily: 'Myriad Pro Regular' }}>PMC: </b> VCS QUALITY SERVICES PRIVATE LIMITED
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr style={{ textAlign: 'center' }}>
                            <th>SR. NO.</th>
                            <th>TEST DESCRIPTION</th>
                            <th colSpan={2}>PQT</th>
                            <th colSpan={1}>REGULAR PRODUCTION</th>
                            <th colSpan={3}>ACCEPTATION CRITERIA</th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <th colSpan={1}>1.0</th>
                            <th colSpan={6} style={{ textAlign: 'left' }}>3 LPE COATING RAW MATERIAL</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr style={{ textAlign: 'center' }}>
                            <td colSpan={1}>1.1</td>
                            <td colSpan={1} style={{ textAlign: 'left' }}>MATERIAL</td>
                            <td colSpan={1}>EPOXY POWDER</td>
                            <td colSpan={1}>ADHESIVE (ME 0420)</td>
                            <td colSpan={1}>POLYETHYLENE (HE 3450 H)</td>
                            <td colSpan={1} style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}>PHOSPHORIC ACID
                                (IF REQUIRED AS
                                PER CL. NO.7.1.12 &
                                7.2)
                                (OAKITE - 31/33,
                                METOX 31 or
                                Equivalent)</td>
                            <td colSpan={1} style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}>
                                CHROMATE
                                (IF REQUIRED AS PER
                                CL. NO.7.1.12 & 7.3)
                                (GARDOBOND 4504 PC,
                                ALUBOND 6410 C or
                                Equivalent)</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>1.2</td>
                            <td style={{ textAlign: 'left' }}>MANUFACTURER</td>
                            <td>3M</td>
                            <td>BOREALS</td>
                            <td>BOROUGE</td>
                            <td>CHEMETALL/
                                Metalguard Private
                                Limited</td>
                            <td>CHEMETALL/
                                Metalguard Private
                                Limited</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <th>2.0</th>
                            <th colSpan={6} style={{ textAlign: 'left' }}>RAW MATERIAL INSPECTION & TESTING:</th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <th>2.1</th>
                            <th colSpan={6} style={{ textAlign: 'left' }}>EPOXY (IN-HOUSE)</th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.1.1</td>
                            <td style={{ textAlign: 'left' }}>DENSITY</td>
                            <td colSpan={2} rowSpan={5}>EACH BATCH</td>
                            <td colSpan={1} rowSpan={5}>EACH BATCH</td>
                            <td colSpan={2}>Within ± 0.05 g/cm3 of the manufacturer's
                                specified nominal value</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.1.2</td>
                            <td style={{ textAlign: 'left' }}>GEL TIME</td>
                            <td colSpan={2}>Within 20% of the nominal value specified by the
                                manufacturer</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.1.3</td>
                            <td style={{ textAlign: 'left' }}>MOISTURE CONTENT</td>
                            <td colSpan={2}>0.6 % mass</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.1.4</td>
                            <td style={{ textAlign: 'left' }}>THERMAL CHARACTERISTICS</td>
                            <td colSpan={2}>Within manufacture's Specification.
                                295 and within manufacturer's specification</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.1.5</td>
                            <td style={{ textAlign: 'left' }}>PARTICLE SIZE</td>
                            <td colSpan={2}>3.0% retained on 150μm sieve ≤0.2% retained on
                                250μm sieve and within manufacturer's
                                specification</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <th>2.2</th>
                            <th colSpan={6} style={{ textAlign: 'left' }}>ADHESIVE (IN-HOUSE)</th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.2.1</td>
                            <td style={{ textAlign: 'left' }}>DENSITY</td>
                            <td colSpan={2} rowSpan={4}>EACH BATCH</td>
                            <td colSpan={1} rowSpan={4}>EACH BATCH</td>
                            <td colSpan={2}>930 kg/m3 and within manufacturer's
                                specification</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.2.2</td>
                            <td style={{ textAlign: 'left' }}>MELT FLOW RATE</td>
                            <td colSpan={2}>1.0 g/10 min and within manufacturer's
                                specification</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.2.3</td>
                            <td style={{ textAlign: 'left' }}>VICAT SOFTENING TEMPERATURE</td>
                            <td colSpan={2}>Min. 100 °C</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.2.4</td>
                            <td style={{ textAlign: 'left' }}>WATER CONTENT</td>
                            <td colSpan={2}>≤0.1%</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <th>2.3</th>
                            <th colSpan={6} style={{ textAlign: 'left' }}>POLYETHYLINE (IN-HOUSE)</th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.3.1</td>
                            <td style={{ textAlign: 'left' }}>MELT FLOW RATE</td>
                            <td colSpan={2} rowSpan={5}>EACH BATCH</td>
                            <td colSpan={1} rowSpan={5}>EACH BATCH</td>
                            <td colSpan={2}>0.25 g/10 minutes and within manufacturer's
                                specification</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.3.2</td>
                            <td style={{ textAlign: 'left' }}>DENSITY</td>
                            <td colSpan={2}>0.940 g/cm3</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.3.3</td>
                            <td style={{ textAlign: 'left' }}>VICAT SOFTENING TEMPERATURE</td>
                            <td colSpan={2}>110 °C</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.3.4</td>
                            <td style={{ textAlign: 'left' }}>WATER CONTENT</td>
                            <td colSpan={2}>0.05%</td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td>2.3.5</td>
                            <td style={{ textAlign: 'left' }}>OXIDATION INDUCTION TIME</td>
                            <td colSpan={2}>30 Minutes at 210 °C</td>
                        </tr>
                        <tr>
                            <th colSpan={7}>
                                FOR COATING PROCEDURE QUALIFICATION TEST typically 15 pipes shall be coated in accordance with the approved coating procedure and
                                the coating operations shall be witnessed by TPI. Out of these pipes, at least one pipe at the start & one pipe at the end of PQT shall be
                                coated Partially with epoxy layer (approx. 3-4 m), Partially with both epoxy & adhesive layers (approx. 3-4) and rest portion with all the three
                                layers. Remaining pipes shall have all the three layers.
                                At least 7 (seven) test pipes shall be selected by TPI for coating procedure qualification tests and shall be subjected to procedure
                                qualification testing as described hereinafter. Owner or its Representative shall witness all tests. Out of 7 (seven) test pipes, 2 (two) pipes
                                Partially coated with epoxy layers and Partially coated with both epoxy & adhesive layers shall be included. Remaining 5 (five) test pipes
                                shall have all the three layers AS PER CL NO.8.2
                            </th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <th colSpan={2}>PREPARED BY</th>
                            <th colSpan={3}>REVIEWED BY</th>
                            <th colSpan={2}>APPROVED BY</th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <th colSpan={2} style={{ padding: '50px 0', borderBottom: 'none' }}></th>
                            <th colSpan={3} style={{ padding: '50px 0', borderBottom: 'none' }}></th>
                            <th colSpan={2} style={{ padding: '50px 0', borderBottom: 'none' }}></th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <th colSpan={2}>MITUN PATEL <br /> QA/QC COATING</th>
                            <th colSpan={3}>K.M. MAHARAJA <br /> QA/QC COATING</th>
                            <th colSpan={2}>AMIT SONVENE <br /> HOD QUALITY COATING</th>
                        </tr>
                    </tbody>
                </table>
            </section >

        </div >
    )
}

export default Newreport