import React, { useState, useEffect, useRef } from "react";
import "./BlastingSheetlist.css";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Loading from "../Loading";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import DatePicker from 'react-datepicker';
import Environment from "../../environment";
import secureLocalStorage from "react-secure-storage";
import Pagination from '../Common/Pagination/Pagination';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg';

function BlastingSheetlist() {
    const [tableData, setTableData] = useState([]);
    const [calibrationList, setCalibrationList] = useState([]);
    const [dustlevelList, setDustlevelList] = useState([]);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [coatingType, setCoatingType] = useState([]);
    const [list, setList] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [processTypeMap, setProcessTypeMap] = useState({});
    const [processTypeName, setProcessTypeName] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = secureLocalStorage.getItem('userId');
    const userRole = secureLocalStorage.getItem('userRole');
    const menuId = queryParams.get('menuId');
    const [data, setData] = useState([])
    const [reportName, setReportName] = useState()
    const [reportPath, setReportPath] = useState()
    const [currentId, setCurrentId] = useState(null);
    const [coatingTypeFilter, setCoatingTypeFilter] = useState('');
    const [clientFilter, setClientFilter] = useState('');

    const typeWise = () => {
        const typeMap = { "21": 523, "15": 524, "16": 525, "22": 526, "23": 528, "24": 1398, "25": 1399, "26": 1400 };
        return typeMap[menuId] || 528888;
    };

    const menuLinks = [
        { id: "21", label: "Inlet" },
        { id: "15", label: "Blasting" },
        { id: "16", label: "Application" },
        { id: "22", label: "Thickness" },
        { id: "23", label: "External Final" },
        { id: "24", label: "Calibration of Blasting" },
        { id: "26", label: "Calibration of Thickness" },
        { id: "25", label: "Dust Level" },
    ];

    const urlMappings = {
        523: "/bare-pipe-inspection/222",
        524: "/phos-blast-insp/677",
        525: "/chromate-coat-insp/670",
        526: "/Thickness-insp/671",
        528: "/external-final",
    };

    const getProcessUrl = (action, data) => {
        const basePath = urlMappings[typeWise()] || "/default-path";
        return `${basePath}&${data.pm_test_run_id}&pm_Approve_level=${action}&pm_project_id=${data?.project_id}&pm_processSheet_id=${data?.procsheet_id}&pm_processtype_id=${typeWise()}&pm_approved_by=${userId}&test_date=${data?.test_date}&menuId=${menuId}`;
    };

    // const handleApprovalDust = (data, action) => {
    //     return `/dustlevelview/${data.pm_blasting_test_run_id}&pm_Approve_level=${action}&pm_project_id=${data?.project_id}&pm_processSheet_id=${data?.procsheet_id}&pm_processtype_id=${typeWise()}&pm_approved_by=${userId}&test_date=${data?.test_date}&menuId=${menuId}`;
    // };

    const addCalibration = (action, data) => {
        const basePath = '/calibration-blasting-report';
        return `${basePath}?year=${data.pm_procsheet_year}&TestRunId=${data.pm_test_run_id}&pm_blasting_test_run_id=${data.pm_blasting_test_run_id}&pm_Approve_level=${action}&pm_project_id=${data?.project_id}&ProcessSheetID=${data?.procsheet_id}&ProcessSheetTypeID=${typeWise()}&pm_approved_by=${userId}&test_date=${data?.test_date}&menuId=${menuId}`;
    };

    const viewCalibration = (action, data) => {
        const basePath = '/calibration-blasting-report-view';
        return `${basePath}/${data.pm_blasting_test_run_id}&pm_Approve_level=${action}&pm_project_id=${data?.project_id}&pm_processSheet_id=${data?.procsheet_id}&pm_processtype_id=${typeWise()}&pm_approved_by=${userId}&test_date=${data?.test_date}&menuId=${menuId}`;
    };
    const handleApprovalCalib = (data, action) => {
        return `/calibration-blasting-report-view/${data.pm_blasting_test_run_id}&pm_Approve_level=${action}&pm_project_id=${data?.project_id}&pm_processSheet_id=${data?.procsheet_id}&pm_processtype_id=${typeWise()}&pm_approved_by=${userId}&test_date=${data?.test_date}&menuId=${menuId}`;
    };
    const addDust = (action, data) => {
        const basePath = '/dustlevel';
        return `${basePath}/${data.pm_test_run_id}&pm_Approve_level=${action}&pm_project_id=${data?.project_id}&pm_processSheet_id=${data?.procsheet_id}&pm_processtype_id=${typeWise()}&pm_approved_by=${userId}&test_date=${data?.test_date}&menuId=${menuId}`;
    };

    const viewDust = (action, data) => {
        const basePath = '/dustlevelview';
        return `${basePath}/${data.pm_blasting_test_run_id}&pm_Approve_level=${action}&pm_project_id=${data?.project_id}&pm_processSheet_id=${data?.procsheet_id}&pm_processtype_id=${typeWise()}&pm_approved_by=${userId}&test_date=${data?.test_date}&menuId=${menuId}`;
    };


    const handleViewClick = (data) => {
        navigate(getProcessUrl('view', data));
    };
    const handleFirstLevel = (data) => {
        navigate(getProcessUrl("first", data));
    };
    const handleSecondLevel = (data) => {
        navigate(getProcessUrl("second", data));
    };
    const handleAddCalibration = (data) => {
        navigate(addCalibration("add", data));
    };
    const handleCalibViewClick = (data) => {
        navigate(viewCalibration("view", data));
    };
    const handleAddDust = (data) => {
        navigate(addDust("add", data));
    };
    const handleViewDust = (data) => {
        navigate(viewDust("view", data));
    };
    // const approvalDust = (data, action) => {
    //     navigate(handleApprovalDust(data, action));
    // };
    const approvalCalib = (data, action) => {
        navigate(handleApprovalCalib(data, action));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSend = {
            project_id: 0,
            procsheet_id: 0,
            pipe_count: data.pm_pipe_count.toString(),
            shift: 0,
            ispqt: "false",
            userid: userId,
            dust_test_id: data.dust_id,
            co_comp_id: 0,
            co_location_id: 0,
            testrunid: 0,
            process_type: 0,
            procedure_type: "0",
            testid: 0,
            report_name: reportName,
            report_file: reportPath,
            blasting_test_run_id: 0,
            isSubmit: true,
            testdate: new Date()
        };

        try {
            const response = await axios.post(Environment.BaseAPIURL + "/api/User/InsertDustLevelData_New", dataToSend);
            if (response.status === 200) {
                toast.success("Data submitted successfully");
                // Close the modal
                if (modalRef.current) {
                    const modal = window.bootstrap.Modal.getInstance(modalRef.current);
                    if (modal) {
                        modal.hide();
                    }
                }
                // Optionally, reset the form or handle further logic
                setIsFileUploaded(false);
                setReportPath('');
                setReportName('');
                fetchProcessSheetDetails();
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            toast.error("Failed to submit");
        }
    };

    const modalRef = useRef(null);

    const fetchPermissions = async () => {
        try {
            const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetPermissionDetailsByPageId`, {
                params: { UserId: userId, PageId: menuId }
            });
            setPermissions(response?.data[0]);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    }

    const extractProcessTypeName = (processTypes, processTypeId) => {
        return processTypes[processTypeId] || "Unknown";
    };

    const fetchProcessSheetDetails = async () => {
        setLoading(true);
        try {
            let response, response1, response2;

            response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetProcessSheetDetailsList`, {
                params: { ProcessType: typeWise(), UserId: userId }
            });

            const processTypes = response?.data[1].reduce((acc, item) => {
                acc[item.co_param_val_id] = item.co_param_val_name;
                return acc;
            }, {});

            setProcessTypeMap(processTypes);

            const processTypeName = extractProcessTypeName(processTypes, typeWise());
            setProcessTypeName(processTypeName);

            const dataWithSerialNumbers = response?.data[0].map((item, index) => ({
                ...item,
                sno: index + 1,
                ProcSheetDate: new Date(item.ProcSheetDate).toLocaleDateString('en-GB'),
            }));

            setTableData(dataWithSerialNumbers);
            setCoatingType(response?.data[1]);

            if (['24', '26'].includes(menuId)) {
                response1 = await axios.get(`${Environment.BaseAPIURL}/api/User/GetCalibRoughnessList`, {
                    params: { ProcessType: menuId === '24' ? '1398' : menuId === '26' ? '1400' : typeWise(), UserId: userId }
                });
                setCalibrationList(response1?.data[0]);
            }

            if (menuId === '25') {
                response2 = await axios.get(`${Environment.BaseAPIURL}/api/User/GetDustlevelTestList`, {
                    params: { ProcessType: menuId === '26' ? '1399' : typeWise(), testid: '237', UserId: userId }
                });
                setDustlevelList(response2?.data[0]);
            }

            if (response || response1 || response2) {
                fetchPermissions();
            }

            if (response && response1) {
                const result = response?.data[0].map(secondItem => {
                    const matchingItem = response1?.data[0].find(firstItem => firstItem.procsheet_id === secondItem.procsheet_id);
                    return {
                        ...secondItem,
                        pm_test_run_id1: matchingItem ? matchingItem.pm_test_run_id : null
                    };
                });
                setList(result);
            }
        } catch (error) {
            console.error('Error fetching process sheet details:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log(userRole);

        setCurrentId(menuId);
        fetchProcessSheetDetails();
        setCurrentPage({
            tableData: 0,
            calibrationList: 0,
            dustlevelList: 0,
        });
    }, [location.pathname, location.search]);

    const getUniqueOptions = (data, key) => {
        return [...new Set(data.map(item => item[key]))];
    };

    const filterData = (data) => {
        return data.filter(item => {
            const testDate = new Date(item.test_date);
            return (
                (!fromDate || testDate >= fromDate) &&
                (!toDate || testDate <= toDate) &&
                (!coatingTypeFilter || item.coating_type === coatingTypeFilter) &&
                (!clientFilter || item.clientname === clientFilter)
            );
        });
    };

    const renderDropdownFilters = () => {
        const uniqueCoatingTypes = getUniqueOptions(menuId === '25' ? dustlevelList : menuId === '24' || menuId === '26' ? calibrationList : tableData, 'coating_type');
        const uniqueClients = getUniqueOptions(menuId === '25' ? dustlevelList : menuId === '24' || menuId === '26' ? calibrationList : tableData, 'clientname');

        return (
            <>
                <label>Coating Type:
                    <select value={coatingTypeFilter} onChange={(e) => setCoatingTypeFilter(e.target.value)}>
                        <option value="">All</option>
                        {uniqueCoatingTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                </label>
                <label>Client:
                    <select value={clientFilter} onChange={(e) => setClientFilter(e.target.value)}>
                        <option value="">All</option>
                        {uniqueClients.map((client, index) => (
                            <option key={index} value={client}>{client}</option>
                        ))}
                    </select>
                </label>
                <i className="fa fa-refresh" onClick={resetFilter}></i>
            </>
        );
    };

    const [currentPage, setCurrentPage] = useState({
        tableData: 0,
        calibrationList: 0,
        dustlevelList: 0,
    });

    const pageSize = 10;

    const handlePageClick = (tableName, selectedPage) => {
        setCurrentPage(prev => ({ ...prev, [tableName]: selectedPage }));
    };

    const paginateData = (data, currentPage) => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        return data.slice(startIndex, endIndex);
    };

    const getFilteredAndPaginatedData = () => {
        const filteredTableData = paginateData(filterData(tableData), currentPage.tableData);
        const filteredCalibrationList = paginateData(filterData(calibrationList), currentPage.calibrationList);
        const filteredDustlevelList = paginateData(filterData(dustlevelList), currentPage.dustlevelList);

        return {
            filteredTableData,
            filteredCalibrationList,
            filteredDustlevelList,
        };
    };

    const { filteredTableData, filteredCalibrationList, filteredDustlevelList } = getFilteredAndPaginatedData();

    const resetFilter = () => {
        setFromDate(null);
        setToDate(null);
        setCoatingTypeFilter('');
        setClientFilter('');
    };

    const acceptedFileTypes = ['application/pdf', 'image/jpeg'];
    const [setFilesSelected] = useState(false);

    const { fileRejections } = useDropzone({
        accept: acceptedFileTypes,
        onDrop: (acceptedFiles) => {
            setFilesSelected(acceptedFiles.length > 0);
        }
    });

    const renderFileRejections = () => {
        return fileRejections.map(({ file, errors }) => (
            <div key={file.path}>
                <p>{file.path} - {file.size} bytes</p>
                {errors.map(e => (
                    <div key={e.code}>{e.message}</div>
                ))}
            </div>
        ));
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleFileChange = async (e, fieldName) => {
        let value;

        if ((fieldName === 'fieldName') && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.type !== "application/pdf") {
                toast.error("Only PDF files are allowed");
                e.target.value = '';
                return;
            }

            if (file.size > 50 * 1024 * 1024) {
                toast.error("Upload file less than 50 MB");
                e.target.value = '';
                return;
            }

            value = await fileToBase64(file);
            setReportPath(value);
            setReportName(file.name);
            setIsFileUploaded(true); // Update state to indicate file has been uploaded
        }
    };

    return (
        <>
            {loading ? (<Loading />) : (
                <>
                    <Header />
                    <section className="InnerHeaderPageSection">
                        <div className="InnerHeaderPageBg" style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <ul>
                                        <li><Link to="/dashboard?moduleId=618">Quality Module</Link></li>
                                        <li><h1>/&nbsp; Process Data Entry List &nbsp;</h1></li>
                                        <li><h1>/&nbsp; {processTypeName}</h1></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="ProcessSheetdataentrySectionPage">
                        <div className="container">
                            <div className="ProcessDataEntryListBox">
                                {menuLinks.map(({ id, label }) => (
                                    <Link key={id} to={`/blastingsheetlist?menuId=${id}`} className={currentId === id ? 'current-process-selection' : ''}>{label}</Link>
                                ))}
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="ProcessSheetlistTable">
                                        <div className="tableheaderflex">
                                            {permissions?.searchPerm === "1" &&
                                                <div className="tableheaderfilter">
                                                    <span> <i className="fas fa-filter"></i> Filter </span>
                                                    <label> From Date:
                                                        <DatePicker
                                                            maxDate={new Date()}
                                                            selected={fromDate}
                                                            onChange={(date) => setFromDate(date)}
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="DD-MM-YYYY"
                                                        />
                                                    </label>
                                                    <label> To Date:
                                                        <DatePicker
                                                            maxDate={new Date()}
                                                            selected={toDate}
                                                            onChange={(date) => setToDate(date)}
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="DD-MM-YYYY"
                                                        />
                                                    </label>
                                                    {renderDropdownFilters()}
                                                </div>
                                            }

                                            {permissions?.createPerm === "1" &&
                                                menuId !== '24' && menuId !== '25' && menuId !== '26' ? <div className="tableheaderAddbutton">
                                                <Link style={{ float: 'right' }} to={`/blastingprocesssheet?id=${typeWise()}&menuId=${menuId}`} target="_blank">
                                                    <i className="fas fa-plus"></i> Add
                                                </Link>
                                            </div> : ''}
                                        </div>
                                        <div className="fadedIcon">
                                            <ul>
                                                <li><i className="fas fa-eye" style={{ color: "#4caf50" }} ></i>View</li>
                                                <li><i className="fas fa-edit" style={{ color: "#ff9800" }} ></i>Edit</li>
                                                {(menuId === '24' || menuId === '25' || menuId === '26') && <li><i className="fas fa-add" style={{ color: "#60145a" }} ></i>Add</li>}
                                                {menuId === '25' && <li><i className="fas fa-upload" style={{ color: "#14aee1" }} ></i>Upload File</li>}
                                                {menuId === '25' && <li><i className="fas fa-download" style={{ color: "#4c84d9" }} ></i>Download File</li>}
                                                <li><i className="fas fa-check" style={{ color: "#4caf50" }} ></i>Single level Approval</li>
                                                <li><i className="fas fa-check-double" style={{ color: "#4caf50" }} ></i>Double level Approval</li>
                                            </ul>
                                        </div>

                                        {loading ? (<Loading />) : (
                                            <>
                                                {menuId !== '24' && menuId !== '25' && menuId !== '26' && (
                                                    <>
                                                        <div className='table-responsive' id='custom-scroll'>
                                                            <table>
                                                                <thead>
                                                                    <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                                        <th style={{ minWidth: '70px' }}>S No.</th>
                                                                        <th style={{ minWidth: '200px' }}>Process Sheet No.</th>
                                                                        <th style={{ minWidth: '100px' }}>Coating Type</th>
                                                                        <th style={{ minWidth: '200px' }}>Project Name</th>
                                                                        <th style={{ minWidth: '270px' }}>Client</th>
                                                                        <th style={{ minWidth: '200px' }}>Pipe Size</th>
                                                                        <th style={{ minWidth: '100px' }}>Test Date</th>
                                                                        <th style={{ minWidth: '120px' }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filteredTableData.length === 0 ? (
                                                                        <tr><td colSpan="8">No data found.</td></tr>
                                                                    ) : (
                                                                        filteredTableData.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td>{currentPage.tableData * pageSize + index + 1}</td>
                                                                                <td>{item.pm_procsheet_code}</td>
                                                                                <td>{item.coating_type}</td>
                                                                                <td>{item.project_name}</td>
                                                                                <td>{item.clientname}</td>
                                                                                <td>{item.pipesize}</td>
                                                                                <td>{new Date(item.test_date).toLocaleDateString('en-GB').replace(/\//g, "-")}</td>
                                                                                <td>
                                                                                    <div className="action-icons">
                                                                                        {permissions?.indexPerm === "1" && (
                                                                                            <i className="fas fa-eye" onClick={() => handleViewClick(item)} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.firstLevelApprove === "1" && item.IsShowForFirstLevelApproval === 1 && item.pm_is_superincharge === 0 && (
                                                                                            <i className="fas fa-check" onClick={() => handleFirstLevel(item)} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.secondLevelApprove === "1" && item.IsShowForSecondLevelApproval === 1 && item.pm_is_superincharge === 0 && (
                                                                                            <i className="fas fa-check-double" onClick={() => handleSecondLevel(item)} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.editPerm === "1" &&
                                                                                            (item.pm_isfinalapproval === 0
                                                                                                ? (item.IsSubmitted === 1 || item.pm_is_superincharge === 1)
                                                                                                : userRole == 'Super Incharge' || userRole == 'Incharge' || userRole == 'HOD') && (
                                                                                                <i className="fas fa-edit" onClick={() => navigate(`/blastingprocesssheet?id=${typeWise()}&pm_test_run_id=${item.pm_test_run_id}&action=edit&processsheetId=${item.procsheet_id}&menuId=${menuId}`)} style={{ color: "#ff9800", margin: '4px', cursor: "pointer" }}></i>
                                                                                            )}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <Pagination
                                                            pageCount={Math.ceil(filterData(tableData).length / pageSize)}
                                                            onPageChange={(data) => handlePageClick('tableData', data.selected)}
                                                        />
                                                    </>
                                                )}

                                                {menuId !== '15' && menuId !== '16' && menuId !== '21' && menuId !== '22' && menuId !== '23' && menuId !== '25' && (
                                                    <>
                                                        <div className='table-responsive' id='custom-scroll'>
                                                            <table>
                                                                <thead>
                                                                    <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                                        <th style={{ minWidth: '70px' }}>S No.</th>
                                                                        <th style={{ minWidth: '200px' }}>Process Sheet No.</th>
                                                                        <th style={{ minWidth: '100px' }}>Coating Type</th>
                                                                        <th style={{ minWidth: '200px' }}>Project Name</th>
                                                                        <th style={{ minWidth: '270px' }}>Client</th>
                                                                        <th style={{ minWidth: '200px' }}>Pipe Size</th>
                                                                        <th style={{ minWidth: '100px' }}>Test Date</th>
                                                                        <th style={{ minWidth: '120px' }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filteredCalibrationList.length === 0 ? (
                                                                        <tr><td colSpan="8">No data found.</td></tr>
                                                                    ) : (
                                                                        filteredCalibrationList.map((item, index) => (
                                                                            <tr key={index} style={{ background: item.pm_blasting_test_run_id !== 0 ? '#d4ffe5' : 'white' }}>
                                                                                <td>{currentPage.calibrationList * pageSize + index + 1}</td>
                                                                                <td>{item.pm_procsheet_code}</td>
                                                                                <td>{item.coating_type}</td>
                                                                                <td>{item.project_name}</td>
                                                                                <td>{item.clientname}</td>
                                                                                <td>{item.pipesize}</td>
                                                                                <td>{item.test_date ? new Date(item.test_date).toLocaleDateString('en-GB').replace(/\//g, "-") : ''}</td>
                                                                                <td>
                                                                                    <div className="action-icons">
                                                                                        {permissions?.indexPerm === "1" && item.pm_blasting_test_run_id !== 0 && (
                                                                                            <i className="fas fa-eye" onClick={() => handleCalibViewClick(item)} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.createPerm === "1" && item.pm_blasting_test_run_id === 0 && item.IsSubmitted === 0 && (
                                                                                            <i className="fas fa-add" onClick={() => handleAddCalibration(item)} style={{ color: "#60145a", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.firstLevelApprove === "1" && item.IsShowForFirstLevelApproval === 1 && (
                                                                                            <i className="fas fa-check" onClick={() => approvalCalib(item, "first")} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.secondLevelApprove === "1" && item.IsShowForSecondLevelApproval === 1 && (
                                                                                            <i className="fas fa-check-double" onClick={() => approvalCalib(item, "second")} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.editPerm === "1" && (item.IsSubmitted === 1 || item.pm_is_superincharge === 1) && (
                                                                                            <i className="fas fa-edit" onClick={() => navigate(`/calibration-blasting-report?action=edit&year=${item.pm_procsheet_year}&ProcessSheetID=${item.procsheet_id}&ProcessSheetTypeID=${typeWise()}&TestRunId=${item.pm_test_run_id}&pm_blasting_test_run_id=${item.pm_blasting_test_run_id}&menuId=${menuId}`)} style={{ color: "#ff9800", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <Pagination
                                                            pageCount={Math.ceil(filterData(calibrationList).length / pageSize)}
                                                            onPageChange={(data) => handlePageClick('calibrationList', data.selected)}
                                                        />
                                                    </>
                                                )}

                                                {menuId !== '15' && menuId !== '16' && menuId !== '21' && menuId !== '22' && menuId !== '23' && menuId !== '24' && menuId !== '26' && (
                                                    <>
                                                        <div className='table-responsive' id='custom-scroll'>
                                                            <table>
                                                                <thead>
                                                                    <tr style={{ background: 'rgb(90, 36, 90)' }}>
                                                                        <th style={{ minWidth: '70px' }}>S No.</th>
                                                                        <th style={{ minWidth: '200px' }}>Process Sheet No.</th>
                                                                        <th style={{ minWidth: '100px' }}>Coating Type</th>
                                                                        <th style={{ minWidth: '200px' }}>Project Name</th>
                                                                        <th style={{ minWidth: '270px' }}>Client</th>
                                                                        <th style={{ minWidth: '200px' }}>Pipe Size</th>
                                                                        <th style={{ minWidth: '100px' }}>Test Date</th>
                                                                        <th style={{ minWidth: '160px' }}>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filteredDustlevelList.length === 0 ? (
                                                                        <tr><td colSpan="8">No data found.</td></tr>
                                                                    ) : (
                                                                        filteredDustlevelList.map((item, index) => (
                                                                            <tr key={index} style={{ background: item.pm_blasting_test_run_id !== 0 ? '#d4ffe5' : 'white' }}>
                                                                                <td>{currentPage.dustlevelList * pageSize + index + 1}</td>
                                                                                <td>{item.pm_procsheet_code}</td>
                                                                                <td>{item.coating_type}</td>
                                                                                <td>{item.project_name}</td>
                                                                                <td>{item.clientname}</td>
                                                                                <td>{item.pipesize}</td>
                                                                                <td>{item.test_date ? new Date(item.test_date).toLocaleDateString('en-GB').replace(/\//g, "-") : ''}</td>
                                                                                <td>
                                                                                    <div className="action-icons">
                                                                                        {permissions?.indexPerm === "1" && item.pm_blasting_test_run_id !== 0 && (
                                                                                            <i className="fas fa-eye" onClick={() => handleViewDust(item)} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.createPerm === "1" && item.pm_blasting_test_run_id === 0 && (
                                                                                            <i className="fas fa-add" onClick={() => handleAddDust(item)} style={{ color: "#60145a", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {item.pm_blasting_test_run_id !== 0 ? <i title="Upload" className="fa-solid fa-upload" style={{ color: "#14aee1", margin: '4px', cursor: "pointer", marginLeft: '10px' }} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setData(item)}></i> : ''}
                                                                                        {item.pm_isattach_submitted === true ? <a
                                                                                            href={`${Environment.ImageURL}/sign_images/${item.pm_attachment}`}
                                                                                            alt="Signature" target="_blank" rel="noreferrer"
                                                                                        ><i title="Download" className="fa-solid fa-download" style={{ color: "#4c84d9", margin: '4px', cursor: "pointer", marginLeft: '10px' }}></i></a> : ''}
                                                                                        {/* {permissions?.firstLevelApprove === "1" && item.IsShowForFirstLevelApproval === 1 && (
                                                                                            <i className="fas fa-check" onClick={() => approvalDust(item, "first")} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )}
                                                                                        {permissions?.secondLevelApprove === "1" && item.IsShowForSecondLevelApproval === 1 && (
                                                                                            <i className="fas fa-check-double" onClick={() => approvalDust(item, "second")} style={{ color: "#4CAF50", margin: '4px', cursor: "pointer" }}></i>
                                                                                        )} */}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <Pagination
                                                            pageCount={Math.ceil(filterData(dustlevelList).length / pageSize)}
                                                            onPageChange={(data) => handlePageClick('dustlevelList', data.selected)}
                                                        />
                                                    </>
                                                )}

                                                <div className="modal fade DustlevelReportUploadModal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                                                    <i className="fa-solid fa-times"></i>
                                                                </button>
                                                                <div className="DustlevelReportUploadBox">
                                                                    <div className="AttachmentsFlexBox">
                                                                        <input
                                                                            name="fieldName"
                                                                            type="file"
                                                                            onChange={(e) => handleFileChange(e, 'fieldName')}
                                                                            accept="application/pdf"
                                                                        />
                                                                        <p>(Each file less than 50 MB)</p>
                                                                    </div>
                                                                    {fileRejections.length > 0 && (
                                                                        <div>
                                                                            <h4>Rejected Files:</h4>
                                                                            {renderFileRejections()}
                                                                        </div>
                                                                    )}
                                                                    {isFileUploaded && (
                                                                        <button type="submit" onClick={handleSubmit} className="submit-button">Submit</button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </>
    );
}

export default BlastingSheetlist;