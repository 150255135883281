import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import Environment from "../../../environment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../Allreports.css';
import HeaderDataSection from "../Headerdata";
import ReportRemarks from '../Report-remarks';
import InstrumentusedSection from '../Instrument-used';
import Footerdata from '../Footerdata';


function formatDate(dateString) {
    return dateString ? new Date(dateString).toLocaleDateString('en-GB') : "-";
}

function formatTime(timeString) {
    if (!timeString) return "-";
    const time = new Date(timeString);
    return time.toLocaleTimeString('en-GB', { hour12: true });
}

function BeforeProcessLabTestReport1() {
    const { tstmaterialid } = useParams();
    const contentRef = useRef();
    const [headerDetails, setHeaderDetails] = useState({});
    const [testDetails, setTestDetails] = useState([]);
    const [instrumentDetails, setInstrumentDetails] = useState([]);
    const [rawMaterialDetails, setRawMaterialDetails] = useState([]);
    const [reportTestDate, setReportTestDate] = useState()
    const [key, setKey] = useState()
    const [value, setKeyValue] = useState()


    const parseKeyValuePair = (str) => {
        // Split the string by ':-'
        const parts = str.split(':-');

        // Trim whitespace from both parts
        const key = parts[0].trim(); // Key before ':-'
        const value = parts[1]?.trim(); // Value after ':-', using optional chaining

        return { key, value };
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (tstmaterialid) {
                    console.log(164, '--------')
                    const [id1, id2, id3, id4] = tstmaterialid.split('&');
                    const response = await axios.get(`${Environment.BaseAPIURL}/api/User/BeforeProcessReport?typeid=${id3}&TestRunId=${id2}&procsheet_id=${id1}&test_id=${id4}`);
                    const data = response.data;
                    const date = data[0][0].ReportTestDate || {}
                    // const [month, day, year] = date.split('T');
                    const formattedDate = date.split('T')[0]
                    setReportTestDate(formattedDate);
                    console.log(formattedDate)
                    console.log(data[0][0])
                    setHeaderDetails(data[0][0] || {});
                    setTestDetails(data[1] || []);
                    setInstrumentDetails(data[2] || []);
                    setRawMaterialDetails(data[3] || []);

                    const { key, value } = parseKeyValuePair(data[0][0].PONo);
                    setKey(key);
                    setKeyValue(value)
                }
            } catch (error) {
                console.error('Error fetching report data:', error);
            }
        };
        fetchData();
    }, [tstmaterialid]);

    const handleDownload = () => {
        const content = contentRef.current;
        const options = {
            scale: 2,
            useCORS: true,
            scrollX: 0,
            scrollY: 0,
            windowWidth: document.documentElement.scrollWidth,
            windowHeight: document.documentElement.scrollHeight,
        };

        // table borders for PDF generation
        const tableElements = content.querySelectorAll('table');
        tableElements.forEach(table => {
            table.style.border = '1px solid #999999';

            // borders from table cells
            const cells = table.querySelectorAll('td, th');
            cells.forEach(cell => {
                cell.style.border = '1px solid #999999';
            });
        });

        html2canvas(content, options)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0);
                const pdf = new jsPDF('landscape', 'mm', 'a4');
                pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
                pdf.save('insAdhesive_report.pdf');
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
                alert('An error occurred while generating the PDF. Please try again later.');
            });
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <div>
            <div style={{ textAlign: 'right', paddingRight: '14px', paddingTop: '10px' }}>
                <h4 className='DownloadPDFBtn' onClick={handleDownload}>
                    <i className="fas fa-download"> </i> Download PDF
                </h4>
                <h4 className='PrintBtn' onClick={handlePrint}>
                    <i className="fas fa-print"></i> Print
                </h4>
            </div>
            <div className='InspReportSection' ref={contentRef}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12 col-sm-12 col-xs-12'>
                            <div className='InspReportBox'>

                                <HeaderDataSection reportData={headerDetails} />

                                <section className='Reportmasterdatasection'>
                                    <div className='container-fluid'>
                                        <form className='row'>
                                            <div className='col-md-7 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Client</label>
                                                    <h4>: &nbsp;&nbsp; {headerDetails.ClientName || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-5 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Report No.</label>:
                                                    <h4 style={{ marginLeft: '5px' }}>
                                                        {headerDetails?.ReportAlias}/{reportTestDate}
                                                        {headerDetails?.ReportPqt == '' ? '' : (
                                                            <> ({headerDetails.ReportPqt})</>
                                                        )}
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className='col-md-7 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">{key ? key : ''} </label>:
                                                    {/* <span>:</span> */}
                                                    <h4 style={{ marginLeft: '10px' }}>{value ? value : ''}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-5 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Date & Shift</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.DateShift || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-7 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Pipe Size</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.PipeSize || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-5 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Process Sheet No.</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.ProcSheetNo || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-7 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Type Of Coating</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.TypeofCoating || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-5 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Procedure / WI No.</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.ProcedureWINo || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-7 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Test Start Date</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.TestStartDateNew || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-5 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Test Start Time</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.TestStartTimeNew || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-7 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Test Finish Date</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.TestFinishDateNew || "-"}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-5 col-sm-6 col-xs-12'>
                                                <div className='form-group'>
                                                    <label htmlFor="">Test Finish Time</label>
                                                    <h4>: &nbsp;&nbsp;{headerDetails.TestFinishTimeNew || "-"}</h4>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </section>

                                {Array.isArray(testDetails) && testDetails.length > 0 && (
                                    <section className='ReporttableSection'>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-md-12 col-sm-12 col-xs-12'>
                                                    <div id='custom-scroll'>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>MATERIAL DESCRIPTION</th>
                                                                    <th>BATCH NO/GRADE</th>
                                                                    <th>TEST DESCRIPTION</th>
                                                                    <th>TEST METHOD</th>
                                                                    <th>REQUIREMENT</th>
                                                                    <th>TEST RESULT</th>
                                                                    <th>REMARKS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rawMaterialDetails.map((rawMaterial, index) => (
                                                                    <tr key={index}>
                                                                        {console.log(testDetails[index], index)}
                                                                        <td>{index + 1 || "-"}</td>
                                                                        <td>{rawMaterial.materialName || "-"}</td>
                                                                        <td>{rawMaterial.grade || "-"}</td>
                                                                        <td>{"-"}</td>
                                                                        <td>{testDetails[0]?.Test_Method || "-"}</td>
                                                                        <td>{(testDetails[0]?.pm_reqmnt_temp_Minus && testDetails[0]?.pm_reqmnt_temp_plus) ?
                                                                            `${testDetails[0]?.pm_reqmnt_temp_Minus} - ${testDetails[0]?.pm_reqmnt_temp_plus}` :
                                                                            (testDetails[0]?.pm_reqmnt_temp_Minus ? `Min - ${testDetails[0]?.pm_reqmnt_temp_Minus}` :
                                                                                (testDetails[0]?.pm_reqmnt_temp_plus ? `Max - ${testDetails[0]?.pm_reqmnt_temp_plus}` : ''))}{" " + testDetails[0].Unit}</td>
                                                                        <td>{testDetails[0]?.pm_test_result_remarks || "-"}{" " + testDetails[0].Unit}</td>
                                                                        <td>{testDetails[0]?.pm_test_result_accepted == 1 ? "Satisfactory " + testDetails[0]?.pm_test_result_suffix : "Not Satisfactory " + testDetails[0]?.pm_test_result_suffix}</td>
                                                                        {/* <td>{rawMaterial.batch || "-"}</td> */}
                                                                    </tr>
                                                                ))}
                                                                {testDetails.map((item, index) => (
                                                                    <tr key={index + 1}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.test_name || "-"}</td>
                                                                        <td>{"-"}</td>
                                                                        <td>{item.test_name || "-"}</td>
                                                                        <td>{item.Test_Method || "-"}</td>
                                                                        <td>{(item.pm_reqmnt_temp_Minus && item.pm_reqmnt_temp_plus) ?
                                                                            `${item.pm_reqmnt_temp_Minus} - ${item.pm_reqmnt_temp_plus}` :
                                                                            (item.pm_reqmnt_temp_Minus ? `Min - ${item.pm_reqmnt_temp_Minus}` :
                                                                                (item.pm_reqmnt_temp_plus ? `Max - ${item.pm_reqmnt_temp_plus}` : ''))}{" " + item.Unit}</td>
                                                                        <td>{item.pm_test_result_remarks || "-"}{" " + item.Unit}</td>
                                                                        <td>{item?.pm_test_result_accepted == 1 ? "Satisfactory " + item?.pm_test_result_suffix : "Not Satisfactory " + item?.pm_test_result_suffix}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}

                                {/* <ReportRemarks reportData={testDetails} /> */}

                                <InstrumentusedSection reportData={instrumentDetails} />

                                {/* <section className="Reportrawmaterialsection">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={4} > RAW MATERIAL USED </th>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ paddingLeft: "10px", fontFamily: "Myriad Pro Bold", }} > MATERIAL </th>
                                                                <th style={{ paddingLeft: "10px", fontFamily: "Myriad Pro Bold", }} > MANUFACTURER </th>
                                                                <th style={{ paddingLeft: "10px", fontFamily: "Myriad Pro Bold", }} > GRADE </th>
                                                                <th style={{ paddingLeft: "10px", fontFamily: "Myriad Pro Bold", }} > BATCH NO. </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {rawMaterialDetails.map((rawMaterial, index) => (
                                                                <tr key={index}>
                                                                    <td>{rawMaterial.materialName || "-"}</td>
                                                                    <td>{rawMaterial.manufacturerName || "-"}</td>
                                                                    <td>{rawMaterial.grade || "-"}</td>
                                                                    <td>{rawMaterial.batch || "-"}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> */}

                                <Footerdata reportData={headerDetails} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BeforeProcessLabTestReport1;