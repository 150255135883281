import React, { useState, useEffect } from "react";

import './Blastingline.css';

import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Loading from '../Loading';
import { Link, useLocation, useNavigate } from "react-router-dom";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import axios from 'axios';
import Environment from "../../environment";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Processsheetfooter from '../Common/Process-sheet-footer/Processsheetfooter';

function ViewBlastingline() {

  const today = new Date();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const empId = secureLocalStorage.getItem("empId")
  const formattedDate = today.getFullYear() + '-' +
    String(today.getMonth() + 1).padStart(2, '0') + '-' +
    String(today.getDate()).padStart(2, '0');
  const [formData, setFormData] = useState({
    pm_comp_id: "1",
    pm_location_id: "1",
    pm_project_id: "",
    pm_processsheet_id: "",
    pm_approver_type: "",
    pm_remarks: "",
    pm_testdate: formattedDate,
    pm_approver_status: "",
    pm_approved_by: empId.toString(),
  });
  const [ShowTestDate, setShowTestDate] = useState("");
  const [ShowApprovalSection, setShowApprovalSection] = useState("");
  const [approvalData, setApprovalData] = useState([])
  const [InspectionAgency, setInspectionAgency] = useState("");
  const [showWitness, setShowWitness] = useState(false)
  const location = useLocation();
  const pathSegments = location.pathname.split(/[\/&]/);

  const searchParams = new URLSearchParams(window.location.search);
  const Id = searchParams.get("id");
  console.log(Id);

  const [workview, setWorkview] = useState([]);
  const params = new URLSearchParams(window.location.search);
  // Get the value of the 'pm_Approve_level' parameter
  const level = params.get('pm_Approve_level');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Environment.BaseAPIURL + `/api/User/GetProdBlastingdatabyid?id=${Id}`);
        const data = response.data;

        console.log(data);
        setWorkview(data[0]);

        if (level == 'first') {
          setShowApprovalSection(true)
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(Environment.BaseAPIURL + "/api/User/ProcessSheetApproval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
      );

      const responseBody = await response.text();

      if (responseBody === "100" || responseBody === "200") {
        toast.success("Form data sent successfully!");
        navigate("/processsheetlist?menuId=7")

      } else {
        console.error("Failed to send form data to the server. Status code:", response.status);
        console.error("Server response:", responseBody);
      }
    } catch (error) {
      console.error("An error occurred while sending form data:", error);
    }
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStatusChange = (value) => {
    if (value === "A") {
      setFormData({ ...formData, pm_approver_status: "A" });
      setShowWitness(true)
    }
    if (value === "R") {
      setFormData({ ...formData, pm_approver_status: "R" });
      setShowWitness(false)
    }
  };


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {
        loading ?
          <Loading />
          :
          <>
            <Header />
            <section className="InnerHeaderPageSection">
              <div
                className="InnerHeaderPageBg"
                style={{ backgroundImage: `url(${RegisterEmployeebg})` }}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <ul>
                      <li><Link to="/productiondashboard">Production Module</Link></li>
                      <li><b style={{ color: '#fff' }}>/&nbsp;</b> <Link to="/listblastingline?menuId=30"> Blasting Line List</Link></li>
                      <li><h1>/&nbsp; Blasting Line View </h1></li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="RawmaterialPageSection AddproductionparameterchartSection">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="PipeTallySheetDetails">
                      <form className="form row m-0">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <h4>
                            Blasting Line <span>- View page</span>
                          </h4>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="">Process Sheet</label>
                            <div className="ProcessSheetFlexBox">
                              <input type="text"
                                style={{ width: "66%", cursor: "not-allowed" }}
                                value={workview?.procsheetCode || ''} />
                              <input type="number" value={workview?.year || ''} />
                              <b> &nbsp; -</b>
                              <input type="number" value={workview?.procsheetId || ''} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="">Client Name</label>
                            <input
                              type="text"
                              value={workview?.clientName || ''}
                              style={{ cursor: "not-allowed" }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="">Date</label>
                            <input
                              type="text"
                              value={formatDate(workview?.importdate)}
                              style={{ cursor: "not-allowed" }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="">Project Name</label>
                            <input
                              type="text"
                              value={workview?.projectName || ''}
                              style={{ cursor: "not-allowed" }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="">Pipe Size</label>
                            <input
                              type="text"
                              value={workview?.pipeSize || ''}
                              style={{ cursor: "not-allowed" }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="">Coating Type</label>
                            <input
                              type="text"
                              value={workview?.coatType || ''}
                              style={{ cursor: "not-allowed" }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="">Specification</label>
                            <input
                              type="text"
                              value={workview?.specification || ''}
                              style={{ cursor: "not-allowed" }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="">LOI No./PO No./FOA No./LOA No</label>
                            <input
                              type="text"
                              value={workview?.loiNo || ''}
                              style={{ cursor: "not-allowed" }}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <form className="ppcdiagramBox">
                            <div className="TopBox">
                              <span className="TopBoxspan1">
                                <label htmlFor="">Flow Meter- {workview?.flowMeter || ''}</label>
                              </span>
                              <span className="TopBoxspan2"></span>
                              <span className="TopBoxspan3"></span>
                            </div>
                            <div className="MidBox">
                              <label htmlFor="">Phosphoric Acid Header</label>
                              <span className="MidBoxspan1"><b>{workview?.phacidHeader}</b></span>
                            </div>
                            <div className="MidBox1">
                              <span className="Mid1Boxspan1">{workview?.phNozzleNo}</span>
                            </div>
                            <div className="bottomBox">
                              <b className="BlastedLinePipeTxt"><hr /> Blasted Line Pipe </b>
                              <span className="bottomBoxspan1">
                                <hr /> <b> {workview?.blastedLinePipe}</b>
                              </span>
                              <span className="bottomBoxspan2">{workview?.waterNozzleNo} NOZZLES</span>
                            </div>

                            <div className="FixedHeaderThead ppcUlList" id="custom-scroll">
                              <table>
                                <thead>
                                  <tr style={{ backgroundColor: "#5A245A", color: "#fff" }}>
                                    <th>S No.</th>
                                    <th>Name</th>
                                    <th>Value</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Blasting Line Speed</td>
                                    <td>{workview?.blastedLineSpeed || ''}</td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Total Nos. of Phosphoric Nozzle</td>
                                    <td>{workview?.phNozzleNo || ''}</td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Total Nos. of Water Nozzle</td>
                                    <td>{workview?.waterNozzleNo || ''}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </form>
                        </div>

                        {/* <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="productionparSaveBtn">
                      <Link to='/listproductionparameterchart'>Save</Link>
                    </div>
                  </div> */}
                      </form>
                    </div>
                    <Processsheetfooter data={approvalData} />
                    {ShowApprovalSection && (
                      <>
                        <div class="row mt-4">
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group Remarksform-group">
                              <label htmlFor="">Remarks <b>*</b></label>
                              <input
                                name="pm_remarks"
                                class="form-control"
                                value={formData.pm_remarks}
                                onChange={handleChange}
                                type="text"
                                placeholder="Enter Approval/Rejection Remarks...."
                                autoComplete="off"
                              />
                            </div>
                            <label className="custom-radio">
                              <input
                                type="radio"
                                className="Approveinput"
                                name="pm_approver_status"
                                id="btnaprv"
                                onChange={() => handleStatusChange("A")}
                              />
                              <span className="radio-btn"><i class="fas fa-check"></i>Approve</span>
                            </label>
                            <label className="custom-radio">
                              <input
                                type="radio"
                                className="Rejectinput"
                                name="pm_approver_status"
                                id="btnreject"
                                onChange={() => handleStatusChange("R")}
                              />
                              <span className="radio-btn"><i class="fas fa-times"></i>Reject</span>
                            </label>
                          </div>
                          <div className="col-md-5 col-sm-5 col-xs-12">
                            <div style={{ display: 'flex' }}>
                              {ShowTestDate && (
                                <div className="form-group Remarksform-group EffectiveDateDiv">
                                  <label htmlFor="">Effective Date <b>*</b></label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    autoComplete="off"
                                    name="pm_testdate"
                                    max={new Date().toISOString().split("T")[0]}
                                    value={formData.pm_testdate}
                                    onChange={handleChange}
                                    placeholder="Enter Effective Date...."
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-3 col-xs-12">
                            <button type="button" className="SubmitBtn" onClick={handleSubmit}>Submit</button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </>
      }
    </>
  );
}

export default ViewBlastingline