import React, { useState, useEffect } from 'react';
import './Calibration.css'
import Header from '../Common/Header/Header'
import Footer from '../Common/Footer/Footer'
import Loading from '../Loading';
import RegisterEmployeebg from '../../assets/images/RegisterEmployeebg.jpg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from "../../environment";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


import { AgGridReact } from "ag-grid-react";
import secureLocalStorage from 'react-secure-storage';

function Calibrationlist() {

  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [testingType, setTestingType] = useState('');
  const [permissions, setPermissions] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const menuId = queryParams.get('menuId');
  const userId = secureLocalStorage.getItem('userId');

  useEffect(() => {
    // fetchData();
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, []);

  useEffect(() => {
    GeWorkinstructiontList();
  }, []);

  const filterData = () => {
    try {
      const filteredData = data.filter(item => {
        const testDate = new Date(item.testDate);
        return (!fromDate || testDate >= new Date(fromDate)) && (!toDate || testDate <= new Date(toDate) &&
          (!testingType || item.testType === testingType));
      });
      return filteredData;
    } catch (ex) {
      console.log(ex);
    }
  };

  const searchParams = new URLSearchParams(document.location.search);
  let testingtype = searchParams.get('testingtype');
  let testingtypeval = "Calibration";
  let addscreenurl = "/Calibration";

  // addscreenurl = "/Workinstruction";


  const filteredData = filterData();

  // -------------------------------------------------

  const GeWorkinstructiontList = async () => {
    try {
      const params = new URLSearchParams();

      const response = await axios.get(Environment.BaseAPIURL + `/api/User/GetCalibrationData`);
      if (Array.isArray(response.data)) { // Check if response.data is an array
        setData(response.data);
      } else {
        toast.error('Data received from the API is not in the expected format.');
      }
      fetchPermissions()
    } catch (error) {
      toast.error('Failed' + error.message);
      console.error('Failed', error.message);
    }
  };

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${Environment.BaseAPIURL}/api/User/GetPermissionDetailsByPageId`, {
        params: { UserId: userId, PageId: menuId }
      });
      setPermissions(response?.data[0]);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  }

  const getProcessUrl = (data, action) => {
    return `/calibrationview?id=${28}&pm_Approve_level=${action}&menuId=${menuId}`;
  };

  const handleApproval = (data, action) => {
    navigate(getProcessUrl(data, action));
  };

  let sno = 0;
  data.forEach(function (value, i) {
    ++sno;
    value['sno'] = sno;

  });

  // const fetchData = () => {
  //   axios.get(Environment.BaseAPIURL + `/api/User/GetTestTemplateforCoatType`)
  //     .then(response => {
  //       setOptions(response.data.filter(option => option.pm_test_level === 2 && option.co_param_val_pid === 183));
  //     })
  //     .catch(error => {
  //       console.error('There was a problem fetching the data:', error);
  //     });
  // };

  const handleTestTypeChange = event => {
    setTestingType(event.target.value);
  };


  const handleViewClick = (params) => {
    console.log(params);
    navigate(`/calibrationview?id=${params.data.calib_id}&menuId=${menuId}`, '_blank');
  };

  const handleListClick = () => {
    window.open('/Calibrationedit', '_blank');
  };

  const actionCellRenderer = (params) => (
    <div className="action-icons">
      <i
        title='View'
        className="fas fa-eye"
        onClick={() => handleViewClick(params)}
        style={{ color: "#4CAF50", margin: '4', cursor: "pointer" }}
      ></i>
      <i
        title='Edit'
        className="fas fa-edit" style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }}
      ></i>
      <i
        title='Recalibration'
        className="far fa-dot-circle" style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }}
      ></i>
      {permissions?.firstLevelApprove === "1" && (
        <i className="fas fa-check" onClick={() => handleApproval(params, "first")} style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }}></i>
      )}
      {permissions?.secondLevelApprove === "1" && (
        <i className="fas fa-check-double" style={{ color: "#4CAF50", borderLeft: '2px solid #a9a9a9', margin: '4', paddingLeft: "10px", cursor: "pointer", marginLeft: "10px" }} onClick={() => handleApproval(params, "second")}></i>
      )}
    </div>
  );

  const columnDefs = [{
    headerName: 'S No.',
    valueGetter: (params) => params.node.rowIndex + 1,
    width: 65,
    headerClass: 'custom-header'
  },
  { headerName: "Manufacturer", field: "pm_manufacturer", width: 350, headerClass: 'custom-header' },
  { headerName: "Type", field: "pm_type", width: 200, headerClass: 'custom-header' },
  { headerName: "Location", field: "pm_location", width: 180, headerClass: 'custom-header align-center' },
  {
    headerName: "Created Date",
    field: "created_on",
    width: 180,
    headerClass: 'custom-header align-center',
    valueFormatter: (params) => {
      if (params.value) {
        const date = new Date(params.value);
        return date.toLocaleDateString('en-GB');
      }
      return '';
    }
  },
  {
    headerName: "Action",
    cellRenderer: actionCellRenderer,
    width: 220, headerClass: 'custom-header'
  },
  ];

  const navigate = useNavigate();

  // ---------------------------------------------------



  return (
    <>
      {
        loading ?
          <Loading />
          :
          <>
            <Header />
            <section className='InnerHeaderPageSection'>
              <div className='InnerHeaderPageBg' style={{ backgroundImage: `url(${RegisterEmployeebg})` }}></div>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12 col-sm-12 col-xs-12'>
                    <ul>
                      <li> <Link to='/dashboard?moduleId=618'>Quality Module</Link></li>
                      <li><h1>/ &nbsp; Calibration List</h1></li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className='RawmateriallistPageSection'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12 col-sm-12 col-xs-12'>
                    <div className='RawmateriallistTables'>
                      <h4>{testingtypeval} <span>- List page</span></h4>
                      <div className='tableheaderflex'>
                        <div className='tableheaderfilter'>
                          <span><i className="fas fa-filter"></i> Filter Test Data</span>
                          <label>
                            From Date:
                            {/* <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} /> */}
                            <DatePicker
                              maxDate={Date.now()}
                              selected={fromDate}
                              onChange={setFromDate}
                              dateFormat="dd/MMM/yyyy"
                              placeholderText="DD/MMM/YYYY"
                            />
                          </label>
                          <label>
                            To Date:
                            {/* <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} /> */}
                            <DatePicker
                              maxDate={Date.now()}
                              selected={toDate}
                              onChange={setToDate}
                              dateFormat="dd/MMM/yyyy"
                              placeholderText="DD/MMM/YYYY"
                            />
                          </label>
                        </div>
                        <div className='tableheaderAddbutton'>
                          <Link style={{ float: 'right' }} to={addscreenurl}><i className="fas fa-plus"></i> Add</Link>
                        </div>
                      </div>

                      {/* SUMIT */}
                      <div className="fadedIcon">
                        <li><i className="fa-solid fa-eye" style={{ color: "#4caf50" }} ></i>View</li>
                        <li><i className="fa-solid fa-edit" style={{ color: "#3d7edb" }} ></i>Edit</li>
                      </div>
                      {/* END SUMIT */}

                      <div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }} >
                        <div className='DownloadExcelFlexBox'>
                          <ul>
                            <li><i className="fa-solid fa-eye" style={{ color: "#4caf50" }} ></i>View</li>
                            <li><i className="fa-solid fa-edit" style={{ color: "#4caf50" }} ></i>Edit</li>
                            <li><i className="far fa-dot-circle" style={{ color: "#4caf50" }} ></i>Recalibration</li>
                          </ul>

                          <a href="/assets/excel-files/calibration-list.xlsx" download>
                            <button className='DownloadExcelBtn'>
                              Download Excel
                            </button>
                          </a>
                        </div>
                        <AgGridReact
                          columnDefs={columnDefs}
                          rowData={filteredData}
                          pagination={true}
                          paginationPageSize={50}
                          suppressDragLeaveHidesColumns="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </>
      }
    </>
  )
}

export default Calibrationlist