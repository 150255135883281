import React, { useState, useEffect, useRef } from 'react';
import '../../Allreports.css';
import Loading from '../../../Loading';
import tatasteellogo from "../../../../assets/images/tsl-blue-logo.png";
import tatalogo from "../../../../assets/images/tata-blue-logo.png";
import tatastamp from '../../Stamps.png';
import axios from 'axios';
import Environment from "../../../../environment";
import html2pdf from 'html2pdf.js';
import { useLocation, useParams } from 'react-router-dom';

function Dustlevelview() {

  const searchParams = new URLSearchParams(window.location.search);
  const dustId = searchParams.get("dustId");
  const processSheet = searchParams.get("processSheet");

  const [workview, setWorkview] = useState({});
  const [pipeCount, setPipeCount] = useState();
  const contentRef = useRef();
  const headerDetails = useRef([]);

  const location = useLocation();
  const pathSegments = location.pathname.split(/[\/&]/);

  const { tstmaterialid } = useParams();

  const [id1, id2, id3, id4] = tstmaterialid.split('&');

  let pm_project_id1 = null;
  let pm_processSheet_id1 = null;
  let pm_processtype_id1 = null;
  let pm_approved_by1 = null;
  let test_date1 = null;
  let pm_Approve_level1 = null;
  let menuId1 = null;
  for (let i = 0; i < pathSegments.length; i++) {
    if (pathSegments[i].startsWith('pm_project_id=')) {
      pm_project_id1 = pathSegments[i].substring('pm_project_id='.length);
    }
    if (pathSegments[i].startsWith('pm_processSheet_id=')) {
      pm_processSheet_id1 = pathSegments[i].substring('pm_processSheet_id='.length);
    }
    if (pathSegments[i].startsWith('pm_processtype_id=')) {
      pm_processtype_id1 = pathSegments[i].substring('pm_processtype_id='.length);
    }
    if (pathSegments[i].startsWith('pm_approved_by=')) {
      pm_approved_by1 = pathSegments[i].substring('pm_approved_by='.length);
    }
    if (pathSegments[i].startsWith('test_date=')) {
      test_date1 = pathSegments[i].substring('test_date='.length);
    }
    if (pathSegments[i].startsWith('pm_Approve_level=')) {
      pm_Approve_level1 = pathSegments[i].substring('pm_Approve_level='.length);
    }
    if (pathSegments[i].startsWith('menuId=')) {
      menuId1 = pathSegments[i].substring('menuId='.length);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(Environment.BaseAPIURL + `/api/User/GetDustLevelReport?dustid=${id1}&procsheet_id=${pm_processSheet_id1}&test_id=237`);
        const data = response.data[0];
        setPipeCount(response.data[0].pm_pipe_count)
        setWorkview(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log(workview);
  }, [workview]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString).toLocaleDateString('en-GB').replace(/-/g, '.')
    return dateString.replace(/-/g, '.') ? date : "-";
  }

  const handleDownloadPDF = () => {
    const element = contentRef.current;
    const opt = {
      margin: [10, 10, 10, 10],
      filename: `Dust-Level-report-${workview?.projectId}-${new Date().toLocaleDateString('en-GB').replace(/\//g, "-")}.pdf`,
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
    };
    html2pdf().set(opt).from(element).save();
  };

  const handlePrint = () => { window.print(); };


  return (<>
    {
      loading ?
        <Loading />
        :
        <>
          <div style={{ textAlign: 'right', paddingRight: '14px', paddingTop: '10px' }}>
            <div className="DownloadPrintFlexSection">
              <h4 className='DownloadPDFBtn' onClick={handleDownloadPDF}>
                <i className="fas fa-download"> </i> Download PDF
              </h4>
              <h4 className='PrintBtn' onClick={handlePrint}>
                <i className="fas fa-print"></i> Print
              </h4>
            </div>
          </div>
          <div className='InspReportSection' ref={contentRef}>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <div className='InspReportBox'>
                    <section className="HeaderDataSection">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="HeaderDataFlexdisplay">
                              <img className="tatasteellogoimg" src={tatasteellogo} alt="" />
                              <img className="tatalogoimg" src={tatalogo} alt="" />
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <h1> PIPE COATING DIVISION <br /> {workview.formatDesc || 'Loading...'} </h1>
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div style={{ textAlign: "right" }}>
                              <p>{`FORMAT NO.: ${workview.formatno} REV: ${workview.Revision} DATE: ${formatDate(workview.Formatdate ? workview.Formatdate : workview.Formatdate ? workview.Formatdate : '-')}`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className='Reportmasterdatasection'>
                      <div className='container-fluid'>
                        <form className='row'>
                          <div className='col-md-7 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Client</label>
                              <span>: &nbsp;</span>
                              <h4 style={{ textTransform: 'uppercase' }}>
                                {workview.ClientName}
                              </h4>
                            </div>
                          </div>
                          <div className='col-md-5 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Report No.</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.ReportAlias}/ {workview?.ReportTestDate.split("T")[0].replace(/-/g, '')} - 01 {workview.ReportPqt == '' ? '' : (
                                <> ({workview.ReportPqt})</>
                              )} </h4>
                            </div>
                          </div>
                          <div className='col-md-7 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">P.O. NO.</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.PONo}</h4>
                            </div>
                          </div>
                          <div className='col-md-5 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Date & Shift</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.DateShift}</h4>
                            </div>
                          </div>
                          <div className='col-md-7 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Pipe Size</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.PipeSize}</h4>
                            </div>
                          </div>
                          <div className='col-md-5 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Acceptance Criteria</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.AcceptanceCriteria}</h4>
                            </div>
                          </div>
                          <div className='col-md-7 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Specification</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.specification}</h4>
                            </div>
                          </div>
                          <div className='col-md-5 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Process Sheet No.</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.ProcSheetNo}</h4>
                            </div>
                          </div>
                          <div className='col-md-7 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Type Of Coating</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.TypeofCoating}</h4>
                            </div>
                          </div>
                          <div className='col-md-5 col-sm-6 col-xs-12'>
                            <div className='form-group'>
                              <label htmlFor="">Procedure / WI No.</label>
                              <span>: &nbsp;</span>
                              <h4>{workview.WINO || "-"}</h4>
                            </div>
                          </div>
                        </form>
                      </div>
                    </section>

                    <section className='DustlevelDetailsSection'>
                      <div className='container-fluid'>
                        <form className='row'>
                          <div className='col-md-12 col-sm-12 col-xs-12 p-0'>
                            <div className='table-responsive' id='custom-scroll'>
                              <table>
                                <thead>
                                  <tr>
                                    <th style={{ width: '1px' }}>Sr. No.</th>
                                    <th style={{ width: '10px' }}>Pipe No.</th>
                                    <th>Tape Application</th>
                                    <th style={{ width: '10px' }}>Dust Level</th>
                                  </tr>
                                </thead>
                                {Array(pipeCount).fill(null).map((data) => {
                                  return (
                                    <tbody>
                                      <tr>
                                        <td>-</td>
                                        <td>-</td>
                                        <td style={{ width: '160mm', height: '30mm' }}></td>
                                        <td>-</td>
                                      </tr>
                                    </tbody>
                                  )
                                })}
                              </table>
                            </div>
                          </div>
                        </form>
                      </div>
                    </section>

                    <section class="ResultPageSection">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{ borderTop: 'none', padding: '2px 12px' }}>ABOVE RESULTS ARE CONFORMING TO SPECIFICATION :- <span style={{ fontFamily: 'Myriad Pro Light' }}>{workview.specification} & {workview.AcceptanceCriteria} AND FOUND SATISFACTORY.</span></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className="FooterdataSection">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <table>
                              <tr>
                                <th style={{ borderTop: 'none', width: '20%' }}>INSPECTED BY</th>
                                <th style={{ borderTop: 'none', width: '100%' }}>ACCEPTED BY</th>
                              </tr>
                              <tr style={{ height: '200px' }}>
                                <td style={{ borderBottom: 'none' }}>
                                  <div className="FooterDataSignatureSection">
                                    {/* <img src={tatastamp} className="TATAStampImg" alt="TATA Stamp" /> */}

                                    <div className="INSPECTEDBYBoxLabelBox">
                                      {/* <span> Employee Name</span>
                                      <span>Designation / Department</span>
                                      <span>Format Date</span> */}
                                      {/* <span style={{ color: data[0]?.status == "Accepted" || data[0]?.status == "Approved" ? '#34B233' : data[0]?.status == "Pending" ? "#FFA100" : "#ED2939" }}>{data[0]?.status}</span> */}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="AccceptedBYFlexBox">
                                    <div className="NDDBox">
                                      <div className="AcceptedBoxLabelBox">
                                        {/* <span>Employee Name</span>
                                        <span>Designation</span>
                                        <span>Company Name</span>
                                        <span>Format Date</span> */}
                                        {/* <span style={{ color: data?.status == "Approved" ? '#34B233' : "#ED2939" }}>{data?.status}</span> */}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ borderBottom: 'none', width: '20%', borderTop: 'none' }}>QC ENGINEER</th>
                                <th style={{ borderBottom: 'none', width: '100%', borderTop: 'none' }}>TPIA / CLIENT</th>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    }
  </>
  );
}

export default Dustlevelview;