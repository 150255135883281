import React, { useState, useEffect } from "react";
import './Blastingline.css';
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import RegisterEmployeebg from "../../assets/images/RegisterEmployeebg.jpg";
import axios from 'axios';
import Environment from "../../environment";
import { toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import Loading from "../Loading";

function AddBlastingline() {

  const navigate = useNavigate();
  const [year, setYear] = useState('');
  const [ddlYear, setddlYear] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [type, setType] = useState('');
  const userId = secureLocalStorage.getItem("userId");

  const queryParams = new URLSearchParams(window.location.search);

  // Get the value of 'menuId'
  const id = queryParams.get('id');

  const [formData, setFormData] = useState({
    coCompId: 1,
    coLocationId: 1,
    procsheetId: 0,
    flowMeter: "",
    phosphoricAcidHeader: "",
    blastedLinePipe: "",
    blastedLineSpeed: "",
    nosOfPhosphoricNozzle: "",
    nosOfWaterNozzle: "",
    shiftId: '',
    // clientname: "",
    // projectname: "",
    // pipesize: "",
    // typeofcoating: "",
    // PONo: "",
  });

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    if (name === "year") {
      setYear(value);
    } else {
      setType(value);
    }
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    if (/^\d{0,2}$/.test(value)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    console.log(headerData?.pm_shiftvalue)
    e.preventDefault();
    try {
      const response = await axios.post(Environment.BaseAPIURL + "/api/User/Insertprodblastingdata",
        formData, {
        headers: {
          Authorization: `Bearer`
        }
      });
      console.log("Response:", response.data);
      if (response.data.responseMessage == "Already Inserted") {
        toast.error("This Process Sheet Already Inserted!");
        return;
      }
      toast.success("Inserted Successfully!");
      navigate("/listblastingline");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again.");
    }

    // try {
    //   const response = await axios.post(Environment.BaseAPIURL + "/api/User/getEPOXYProcesssheetdetails",
    //     formData, {
    //     headers: {
    //       Authorization: `Bearer`
    //     }
    //   });
    //   console.log("Response:", response.data);

    //   navigate("/listblastingline");
    //   toast.success("Registration successful");
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    //   toast.error("Error submitting form. Please try again.");
    // }
  };

  const getYear = () => {
    axios.get(Environment.BaseAPIURL + "/api/User/getprocsheetyear")
      .then((response) => {
        const sortedYears = response.data.sort((a, b) => b.year - a.year);
        setddlYear(sortedYears);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const editApi = () => {
    axios.get(Environment.BaseAPIURL + `/api/User/GetProdBlastingdatabyid?id=${id}`)
      .then((response) => {
        console.log(response.data[0]);
        setHeaderData(response.data[0])
        setFormData(prev => [{
          ...prev, flowMeter: response.data[0].flowMeter,
          phosphoricAcidHeader: response.data[0].phacidHeader,
          blastedLinePipe: response.data[0].blastedLinePipe,
          blastedLineSpeed: response.data[0].blastedLineSpeed,
          nosOfPhosphoricNozzle: response.data[0].phNozzleNo,
          nosOfWaterNozzle: response.data[0].waterNozzleNo
        }])
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (id) {
      editApi()
    }
    else {
      getYear();
    }
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  const handleTypeBlur = () => {
    callApi();
  }

  const callApi = async () => {
    try {
      if (year && type) {
        const response = await axios.post(`${Environment.BaseAPIURL}/api/User/getEPOXYProcessSheetDetails?processsheetno=${type}&year=${year}`);
        const firstDataItem = response.data.Table[0];
        setFormData((prevState) => ({
          ...prevState,
          procsheetId: response.data.Table[0]?.processsheetid,
          userId: userId
          // clientname: response.data.Table[0]?.clientname,
          // PONo: response.data.Table[0]?.PONo,
          // pipesize: response.data.Table[0]?.pipesize,
          // projectname: response.data.Table[0]?.projectname,
          // typeofcoating: response.data.Table[0]?.typeofcoating
        }));
        setHeaderData(firstDataItem || []);
        setFormData((data) => ({ ...data, shiftId: firstDataItem?.pm_shiftvalue }))
        // setShowAdditionalFields(parseInt(type) > 0);
        // setProcessSheetNumber(type);
      } else {
        console.error('Invalid year or type:', year, type);
      }
    } catch (error) {
      console.error('Error fetching process sheet details:', error);
    }
  };

  // ---------------------------------------------

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header />
          <section className="InnerHeaderPageSection">
            <div
              className="InnerHeaderPageBg"
              style={{ backgroundImage: `url(${RegisterEmployeebg})` }}
            ></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <ul>
                    <li><Link to="/productiondashboard">Production Module</Link></li>
                    <li><b style={{ color: '#fff' }}>/&nbsp;</b> <Link to="/listblastingline?menuId=30"> Blasting Line List</Link></li>
                    <li><h1>/&nbsp; Blasting Line </h1></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="RawmaterialPageSection AddproductionparameterchartSection">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="PipeTallySheetDetails">
                    <form className="form row m-0" onSubmit={handleSubmit}>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <h4>
                          Blasting Line <span>- Add page</span>
                        </h4>
                      </div>
                      <div className='col-md-4 col-sm-4 col-xs-12'>
                        <div className='form-group'>
                          <label htmlFor="processSheet">Process Sheet</label>
                          <div className='ProcessSheetFlexBox'>
                            <input
                              required id="processSheet" style={{ width: '66%', cursor: 'not-allowed' }} value={headerData.processsheetcode} placeholder='Process sheet no.' readOnly />
                            <select name="year" value={year} onChange={handleTypeChange} >
                              <option value=""> Year </option>
                              {ddlYear.map((coatingTypeOption, i) => (
                                <option key={i} value={coatingTypeOption.year}> {coatingTypeOption.year} </option>
                              ))}
                            </select>
                            <b>-</b>
                            <input
                              required id="type" type="text" placeholder='No.' value={type} onChange={handleTypeChange} onBlur={handleTypeBlur} />
                          </div>
                        </div>
                      </div>

                      {[
                        { id: 'clientName', label: 'Client Name', value: headerData?.clientname != undefined ? headerData?.clientname : '' },
                        { id: 'projectName', label: 'Project Name', value: headerData?.projectname != undefined ? headerData?.projectname : '' },
                        { id: 'pipeSize', label: 'Pipe Size', value: headerData?.pipesize != undefined ? headerData?.pipesize : '' },
                        { id: 'poNo', label: 'LOI No./PO No./FOA No./LOA No.', value: headerData?.PONo != undefined ? headerData?.PONo : '' },
                        { id: 'dated', label: 'Dated', value: new Date(headerData?.testdate).toLocaleDateString('en-GB') != undefined ? new Date(headerData?.testdate).toLocaleDateString('en-GB') : '' },
                        { id: 'shift', label: 'Shift', value: headerData?.pm_shiftvalue != undefined ? headerData?.pm_shiftvalue : '' },
                      ].map(field => (
                        <div key={field.id} className='col-md-4 col-sm-4 col-xs-12'>
                          <div className='form-group'>
                            <label htmlFor={field.id}>{field.label}</label>
                            <input
                              required
                              id={field.id}
                              type='text'
                              value={field.value}
                              placeholder={field.label}
                              style={{ cursor: 'not-allowed' }}
                              readOnly
                            />
                          </div>
                        </div>
                      ))}

                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <hr />
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="flowMeter">Flow Meter</label>
                          <input
                            required
                            type="number"
                            id="flowMeter"
                            name="flowMeter"
                            placeholder="Enter flow meter"
                            value={formData.flowMeter}
                            onChange={handleInputChange}
                            pattern="\d{1,2}"
                            minLength={1}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="phosphoricAcidHeader">Phosphoric Acid Header</label>
                          <input
                            required
                            type="number"
                            id="phosphoricAcidHeader"
                            name="phosphoricAcidHeader"
                            placeholder="Enter phosphoric acid header"
                            value={formData.phosphoricAcidHeader}
                            onChange={handleInputChange}
                            pattern="\d{1,2}"
                            minLength={1}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="blastedLinePipe">Blasted Line Pipe</label>
                          <input
                            required
                            type="number"
                            id="blastedLinePipe"
                            name="blastedLinePipe"
                            placeholder="Enter blasted line pipe"
                            value={formData.blastedLinePipe}
                            onChange={handleInputChange}
                            pattern="\d{1,2}"
                            minLength={1}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="blastedLineSpeed">Blasted Line Speed</label>
                          <input
                            required
                            type="number"
                            id="blastedLineSpeed"
                            name="blastedLineSpeed"
                            placeholder="Enter blasted line speed"
                            value={formData.blastedLineSpeed}
                            onChange={handleInputChange}
                            pattern="\d{1,2}"
                            minLength={1}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="nosOfPhosphoricNozzle">Total Nos. of Phosphoric Nozzle</label>
                          <input
                            required
                            type="number"
                            id="nosOfPhosphoricNozzle"
                            name="nosOfPhosphoricNozzle"
                            placeholder="Enter total nos. of phosphoric nozzle"
                            value={formData.nosOfPhosphoricNozzle}
                            onChange={handleInputChange}
                            pattern="\d{1,2}"
                            minLength={1}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="nosOfWaterNozzle">Total Nos. of Water Nozzle</label>
                          <input
                            required
                            type="number"
                            id="nosOfWaterNozzle"
                            name="nosOfWaterNozzle"
                            placeholder="Enter total nos. of water nozzle"
                            value={formData.nosOfWaterNozzle}
                            onChange={handleInputChange}
                            pattern="\d{1,2}"
                            minLength={1}
                            maxLength={2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <div className="RegisterEmployeeFooter">
                          <span style={{ color: "#ED2939", fontSize: "12px" }}>
                            {/* *all fields are mandatory */}
                          </span>
                          <button type="submit" className="SubmitNextbtn">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div >
          </section >
          <Footer />
        </>
      )}
    </>
  );
}

export default AddBlastingline